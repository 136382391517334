const langs = {
    gb: {
        _name: 'English',
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            updateOrder: 'Update Order',
        },
        balanceModal: {
            balance: 'Balance',
            credit: 'Credit',
            equity: 'Equity',
            practice: 'PRACTICE',
            real: 'REAL',
            account: 'ACCOUNT',
            invested: 'Invested',
            profit: 'Profit',
            loss: 'Loss',
            margin: 'Margin',
            marginLevel: 'Margin Level',
            marginFree: 'Free Margin'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Deposit'
        },
        sideMenu: {
            marketWatch: 'Market Watch',
            activeOrders: 'Active Orders',
            tradingHistory: 'Trading History',
            economicCalendar: 'Economic Calendar',
            marketNews: 'Market News'
        },
        closePositionModal: {
            closeOrder: 'Close Position',
            areYouSure: 'Are you sure to close the position',
            buy: 'Buy',
            sell: 'Sell',
            yes: 'Yes',
            no: 'No',
        },
        chart: {
            addNewChart: 'Add New Chart',
        },
        symbolsModal: {
            watchlist: 'Watchlist',
            asset: 'Asset',
            price: 'Price',
            changePct: 'Change 24h'
        },
        pendingModal: {
            pending: 'Pending',
            assetPrice: 'Asset Price',
            current: 'Current',
            revert: 'Revert to Market Price',
            automatically: 'Position will be opened automatically when the price reaches this level'
        },
        orderMenu: {
            volume: 'Volume',
            lots: 'lots',
            units: 'units',
            currency: 'currency',
            contractSize: 'Contract size',
            position: 'Position',
            margin: 'Margin',
            freeMargin: 'Free Margin',
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            pending: 'Pending',
            market: 'Market',
            leverage: 'Leverage',
            spread: 'Spread',
            notSet: 'Not set',
            at: 'at',
            buy: 'buy',
            sell: 'sell',
        },
        footer: {
            supportBanner: 'EVERY DAY, AROUND THE CLOCK',
            currentTime: 'CURRENT TIME',
            liveChat: 'Live Chat'
        },
        statusBar: {
            balance: 'Balance',
            credit: 'Credit',
            equity: 'Equity',
            margin: 'Margin',
            marginLevel: 'Margin level',
            freeMargin: 'Free Margin',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Date Registered',
            userId: 'User ID',
            dashboard: 'Dashboard',
            personalData: 'Personal Data',
            deposit: 'Deposit',
            withdrawFunds: 'Withdraw Funds',
            savings: 'Savings',
            settings: 'Settings',
            logout: 'Logout'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            BT_ACCOUNT: 'Account',
            BT_BANK_NAME: 'Bank Name',
            BT_BENEFICIARY: 'Beneficiary',
            BT_ADDRESS: 'Beneficiary Address',
            BT_SWIFT: 'Swift',
            BT_IBAN: 'IBAN',
            BT_REFERENCE_CODE : 'Bank Reference Code',
            BT_INN: 'INN',
            BT_KPP: 'Correspondent account',
            BT_CORRESPONDING: '',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            makeDeposit: 'Make a Deposit',
            practiceAccount: 'Practice Account',
            realAccount: 'Real Account',
            noWithdrawals: 'No Withdrawals',
            easyWithdrawals: 'Easy Withdrawals',
            allAssets: 'All assets available',
            fullFledged: 'Full-ledged platform',
            fillUpTo: 'Fill Up To',
            freeReplenishment: 'Free replenishment',
            topUp: 'Top Up Your Account',
            minimumAmount: 'Minimum amount',
            canSwitch: 'You can switch between your accounts at any moment'
        },
        gridsModal: {
            chartGrids: 'CHART GRIDS',
            chart1: '1 chart',
            chart2: '2 charts',
            chart3: '3 charts',
            chart4: '4 charts',
        },
        noConnectionModal: {
            connectionLost: 'Connection with the server is lost',
            retryNow: 'Retry Now',
        },
        loginModal: {
            loginToTradeRoom: 'Login To Traderoom',
            email: 'Email address',
            emailAndId: 'Email address / ID number',
            enterEmail: 'Enter your email',
            twoFactor: '2FA Code (if enabled)',
            twoFactorAuth: 'Two-factor authentication',
            password: 'Password',
            yourPassword: 'Your password',
            signIn: 'Sign In',
            dontHaveAccount: 'Don’t have an account?',
            restore: 'Restore',
            fullName: 'Full Name',
            havePromo: 'I have a promo-code',
            promo: 'Promo code',
            login: 'Login',
            haveAccount: 'Already have an account?',
            hide: 'Hide',
            goTo: 'Go to back'
        },
        newPasswordModal: {
            setNewPassword: 'Set New Password',
            newPassword: 'New password',
            confirmNewPassword: 'Confirm new password',
            setButton: 'Set'
        },
        registerModal: {
            createNewAccount: 'Create A New Account',
            email: 'Email address',
            firstName: 'First Name',
            lastName: 'Last Name',
            country: 'Country',
            phone: 'Phone',
            password: 'Password',
            createAccount: 'Create Account',
            currency: 'Currency'
        },
        forgotPasswordModal: {
            forgotPassword: 'Forgot Password?',
            submitEmail: 'Please submit an email used for registration, check your inbox and follow the instructions provided',
            submit: 'Submit'
        },
        notifications: {
            error: 'Error',
            success: 'Success',
            deposit: 'Deposit',
            withdrawal: 'Withdrawal',
            depositApproved: 'Deposit has been approved',
            depositDeclined: 'Deposit has been declined',
            withdrawalApproved: 'Withdrawal has been approved',
            withdrawalDeclined: 'Withdrawal has been declined',
            withdrawalCancelled: 'Withdrawal has been cancelled',
        },
        markets: {
            forex: 'Forex',
            stocks: 'Stocks',
            commodities: 'Commodities',
            indices: 'Indices',
            crypto: 'Crypto',
            metals: 'Metals',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'BUY',
            profitCalculator: 'Profit Calculator',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Market Watch',
            search: 'Search...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volume',
            margin: 'Margin',
            active: 'Active',
            pending: 'Pending',
            activeOrders: 'Active Orders',
            portfolio: 'Portfolio',
            allPositions: 'All Positions',
            noPositionsLine1: 'You have no open',
            noPositionsLine2: 'positions yet',
            show: 'Show',
            more: 'more',
            less: 'less',
            purchaseTime: 'Purchase Time',
            closePosition: 'Close Position',
            priceOpen: 'Purchase Price',
            priceSl: 'Stop Loss Price',
            priceTp: 'Take Profit Price',
            type: 'Position Direction',
            pnl: 'Profit/Loss',
            cancelOrder: 'Cancel Order',
            orderType: 'Order Type',
            cancelled: 'Cancelled',
            tradingHistory: 'Trading History',
            noHistoryLine1: 'You don\'t have any',
            noHistoryLine2: 'closed deals yet',
            sortByTime: 'Sort by time',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Economic Calendar'
        },
        widgetMarketNews: {
            marketNews: 'Market News'
        },
        ordersPane: {
            ordersActive: 'Active Orders',
            ordersHistory: 'Orders History',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Type',
            volume: 'Volume',
            openPrice: 'Open Price',
            openTime: 'Open Time',
            closePrice: 'Close Price',
            closeTime: 'Close Time',
            sl: 'SL',
            tp: 'TP',
            price: 'Price',
            pnl: 'PnL',
            actions: 'Actions',
            edit: 'Edit',
            close: 'Close',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Information',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        fullScreen: {
            startTrading: 'Start Trading',
            deposit: 'Deposit',
            dashboard: 'Dashboard',
            personalInfo: 'Personal Info',
            withdrawal: 'Withdrawal',
            verification: 'Verification',
            accounts: 'Accounts',
            liveChat: 'Live Chat',
            savings: 'Savings',
            settings: 'Settings',
            logOut: 'Log Out',
        },
        fullScreenDeposit: {
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'MAKE A DEPOSIT',
            instant: 'Instant',
            minutes: 'minutes',
            hours: 'hours',
            days: 'days',
            amount: 'Amount',
            continue: 'Continue',
            qrCode: 'QR CODE',
            depositAddress: 'DEPOSIT ADDRESS',
            copy: 'Copy',
            last: 'LAST',
            deposits: 'DEPOSITS',
            time: 'Time',
            currency: 'Currency',
            status: 'Status',
            info: 'Info',
            name: 'Cardholder\'s name',
            address: 'Address',
            DOB: 'DOB',
            bankName: 'Bank name',
            additionalInfo: 'To deposit more to your account, please contact your account manager directly',
            additionalInfoOnlyBTCpsp: 'To open other payment methods please provide KYC or contact your account manager',
            sofortPspMessage: 'Our finance team will contact you to help you make a deposit'
        },
        fullScreenCcExt: {
            pleaseWait: 'Please wait. Connecting to payment service provider...',
            cardPayment: 'Card payment',
            inOrder: 'In order to send the amount',
            pleaseProvide: 'please provide card details:'
        },
        fullScreenDashboard: {
            totalTaxes: 'Total Taxes',
            totalBalance: 'Total Balance',
            totalPnl: 'Total PNL',
            profitableOrders: 'Profitable Orders',
            roi: 'ROI',
            activityLog: 'Activity Log',
            loginFromIp: 'Login from IP',
            tradingResults: 'Trading Results',
            week: 'Week',
            month: 'Month',
            year: 'Year',
            successRate: 'Success rate',
            closedWithProfit: 'Closed With Profit',
            closedWithLoss: 'Closed With Loss',
            account: 'Account',
            balance: 'Balance',
            leverage: 'Leverage',
            credit: 'Credit',
            tradeNow: 'Trade Now',
            usingCurrentRate: 'using current exchange rate',
            totalDeposits: 'Total Deposits'
        },
        fullScreenPersonal: {
            personalDetails: 'Personal Details',
            profilePhoto: 'Profile Photo',
            firstName: 'First Name',
            country: 'Country',
            lastName: 'Last Name',
            address: 'Address',
            email: 'E-mail',
            phone: 'Phone number',
            saveChanges: 'Save Changes',
        },
        fullScreenAvatar: {
            dropFile: 'Drop a file on the circle above to upload',
            notAllowed: 'It is not allowed to publish',
            notAllowedLine1: 'Photos of an explicitly sexual or pornographic nature',
            notAllowedLine2: 'Images aimed at inciting ethnic or racial hatred or aggression',
            notAllowedLine3: 'Photos involving persons under 18 years of age',
            notAllowedLine4: 'Third-party copyright protected photos',
            notAllowedLine5: 'Images larger than 5 MB and in a format other than JPG, GIF or PNG',
            requirements: 'Your face must be clearly visible on the photo. All photos and videos uploaded by you must comply with these requirements, otherwise they can be removed.'
        },
        fullScreenWithdrawal: {
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'REQUEST A NEW WITHDRAWAL',
            amount: 'Amount',
            currentBalance: 'Your current account balance is',
            withdrawAll: 'Withdraw all',
            requestWithdrawal: 'Request Withdrawal',
            last: 'LAST',
            withdrawalRequests: 'WITHDRAWAL REQUESTS',
            time: 'Time',
            currency: 'Currency',
            info: 'Info',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            card: 'Card',
            ethereum: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Withdrawal Details',
            address: 'Wallet Address',
            bankAccount: 'Account Number',
            bankHolder: 'Account Holder Name',
            bankIban: 'IBAN',
            bankSwift: 'Bank SWIFT code',
            sortCode: 'Sort Code',
            ifscCode: 'IFSC Code',
            bankName: 'Bank Name',
            bankBranch: 'Bank Branch',
            cardNumber: 'Card Number',
            cardHolder: 'Card Holder Name',
            incorrectAmountByFreeMargin: 'The amount is incorrect you can withdraw the amount of free margin',
            closeOrders: 'before close all orders'
        },
        fullScreenVerification: {
            dragAndDrop: 'drag and drop the document to this area',
            documentsList: 'LIST OF UPLOADED DOCUMENTS',
            document: 'Document',
            timeUploaded: 'Time Uploaded',
            timeProcessed: 'Time Processed',
            status: 'Status',
            types: ['Proof of Id', 'Proof of Residence', 'Credit Card Front', 'Credit Card Back', 'Proof of Id Back', 'Selfie','Other'],
            types2: ['Proof of Id (ID CARD, PASSPORT, DRIVER LICENCE)', 'Proof of Residence (Utility bill or Bank statement)', 'Credit Card Front', 'Credit Card Back', 'Proof of Id Back (ID CARD BACK, DRIVER LICENCE BACK)', 'Selfie','Other'],
            upload: 'Upload',
            isConfirmed: 'is confirmed',
            uploadAnotherDocument: 'Upload another document',
            isUnderReview: 'is under review',
            questionnaire: 'Questionnaire',
            sendAnswers: 'Send Answers',
            progressBarOf: 'of',
            progressBarText: 'of your documents have been uploaded and confirmed'
        },
        fullScreenAccounts: {
            manageAccounts: 'Transfer funds between your accounts',
            transferFrom: 'From account',
            transferTo: 'To account',
            amount: 'Amount',
            makeTransfer: 'Make Transfer',
            yourAccounts: 'Your Accounts',
            account: 'Account',
            currency: 'Currency',
            balance: 'Balance',
            credit: 'Credit',
            makeActive: 'Make Active',
            active: 'Active'
        },
        fullScreenSupport: {
            chatOff: 'Chat will be available on working days',
            sendMessage: 'Send Message'
        },
        fullScreenSavings: {
            pct_0: 'Flexible',
            pct_30: 'Locked 1 Month',
            pct_90: 'Locked 1 Quarter',
            pct_180: 'Locked 6 Months',
            pct_365: 'Locked 1 Year',
            pct_730: 'Locked 2 Year',
            savings: 'Savings',
            detailedInformation: 'Detailed Information',
            pleaseSelect: 'Please select a currency and a period',
            openSavings: 'Open Savings',
            currentTime: 'Current Time',
            releaseTime: 'Release Time',
            currencyChosen: 'Currency Chosen',
            periodChosen: 'Period Chosen',
            yourRate: 'Your Rate',
            yourAnnualRate: 'Your Annual Rate',
            yourEarnings: 'Your earnings',
            accountFrom: 'Account to transfer funds from',
            enterAmount: 'Enter Amount',
            in: 'In',
            openAndInvest: 'Open Savings Account and invest',
            investment: 'Investment',
            period: 'Period',
            daysTotal: 'Days total',
            finalEarnings: 'Final earnings',
            created: 'Created',
            daysElapsed: 'Days Elapsed',
            days: 'days',
            earnings: 'Earnings',
            fundsReleasedIn: 'Funds Released In',
            claim: 'Claim',
        },
        fullScreenSettings: {
            updatePassword: 'UPDATE PASSWORD',
            currentPassword: 'Current Password',
            newPassword: 'New Password',
            changePassword: 'Change Password',
            activationForGoogle: 'Activation code for Google Authenticator',
            activateAndEnter: 'Activate Authenticator and enter a generated code in field below',
            qrCode: 'QR Code',
            activateProtection: 'Activate 2FA Protection',
            protectionActive: '2FA protection is active',
            twoFactorCode: '2FA Code',
            disableProtection: 'Disable 2FA Protection',
            language: 'Language',
            dashboardCurrency: 'Dashboard Currency',
            confirmNewPassword: 'Confirm new password',
        },
        fullScreenReferrals: {
            yourLink: 'YOUR REFERRAL LINK',
            referredFriends: 'REFERRED FRIENDS',
            id: 'ID',
            name: 'Name',
            registered: 'Register',
            level: 'Level',
            payout: 'Payout',
            lastPayouts: 'LAST REFERRAL PAYOUTS',
            time: 'Time',
            amount: 'Amount',
            currency: 'Currency',
            info: 'Info'
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'May',
            Jun: 'Jun',
            Jul: 'Jul',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Oct',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Buy', 'Sell', 'Buy Limit', 'Sell Limit', 'Buy Stop', 'Sell Stop'],
        statuses: ['Pending', 'Approved', 'Declined', 'Processing','Under Review'],
        errors: {
            INVALID_FIELDS: 'Please check that the data you have entered is correct',
            NOT_FOUND: 'Request error',
            FORBIDDEN: 'Access denied. Please re-login',
            SERVER_ERROR: 'Action failed. Please try again later',
            INVALID_ACCOUNT: 'Session expired. Please re-login',
            INVALID_EMAIL: 'Invalid e-mail address',
            INVALID_PHONE: 'Invalid phone number',
            INVALID_COUNTRY: 'Invalid country',
            INVALID_LOGIN: 'Invalid login credentials',
            USER_BLOCKED: 'Account is blocked. Please contact support',
            USER_REGISTERED: 'User with entered e-mail or phone number is already registered',
            INVALID_USER: 'User is not found',
            REJECTED: 'Operation is not allowed due to platform restrictions',
            INVALID_OLD_PASSWORD: 'Old password is invalid',
            INVALID_SYMBOL: 'Invalid trading symbol',
            INVALID_SYMBOL_PRECISION: 'Invalid symbol precision',
            INVALID_FRAME: 'Invalid chart frame',
            INVALID_CURRENCY: 'Invalid currency',
            INVALID_PRODUCT: 'Invalid account trading type',
            INSUFFICIENT_FUNDS: 'Insufficient funds',
            INVALID_WITHDRAWAL: 'Withdrawal is not found',
            INVALID_STATUS: 'Invalid status',
            INVALID_FILE: 'Invalid file extension or file is too large',
            INVALID_DOCUMENT: 'Document is not found',
            INVALID_NAME: 'Invalid name',
            INVALID_LOT_SIZE: 'Invalid lot size',
            INVALID_LOT_STEP: 'Invalid lot step',
            INVALID_MIN_VOLUME: 'Invalid minimal volume',
            INVALID_MAX_VOLUME: 'Invalid maximal volume',
            INVALID_GROUP: 'Invalid group',
            INVALID_SPREAD: 'Invalid spread',
            REQUEST_TIMEOUT: 'Request timed out. Please try again later',
            INVALID_TYPE: 'Invalid order type',
            INVALID_VOLUME: 'Invalid order volume',
            INVALID_SL: 'Invalid stop loss value',
            INVALID_TP: 'Invalid take profit value',
            INVALID_PRICE: 'Invalid order price',
            INVALID_EXPIRATION: 'Invalid order expiration',
            NOT_ENOUGH_MARGIN: 'Not enough margin',
            INVALID_ORDER: 'Order is not found',
            MARKET_CLOSED: 'Trading is not available. Please try again',
            INVALID_BALANCE_TYPE: 'Invalid type of balance operation',
            INVALID_HASH: 'Invalid hash',
            HASH_EXPIRED: 'Your password reset link is expired. Please request a new one',
            INVALID_CODE: 'Invalid 2FA code',
            CHAT_DISABLED: 'Access to the support chat has been closed',
            WITHDRAWAL_NOT_ALLOWED: 'Access to the withdrawals has been closed',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            CLOSE_OPEN_ORDERS: 'Please close your open orders',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later',
            REGISTRATIONS_BLOCKED: "Thank you for your inquiry, One of our representatives will be in touch with you as soon as possible",
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland Islands',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'American Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua And Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belgium',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnia And Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet Island',
            BR: 'Brazil',
            IO: 'British Indian Ocean Territory',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodia',
            CM: 'Cameroon',
            CA: 'Canada',
            CV: 'Cape Verde',
            KY: 'Cayman Islands',
            CF: 'Central African Republic',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands',
            CO: 'Colombia',
            KM: 'Comoros',
            CG: 'Congo',
            CD: 'Congo, Democratic Republic',
            CK: 'Cook Islands',
            CR: 'Costa Rica',
            CI: 'Cote D\'Ivoire',
            HR: 'Croatia',
            CU: 'Cuba',
            CY: 'Cyprus',
            CZ: 'Czech Republic',
            DK: 'Denmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominican Republic',
            EC: 'Ecuador',
            EG: 'Egypt',
            SV: 'El Salvador',
            GQ: 'Equatorial Guinea',
            ER: 'Eritrea',
            EE: 'Estonia',
            ET: 'Ethiopia',
            FK: 'Falkland Islands (Malvinas)',
            FO: 'Faroe Islands',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'France',
            GF: 'French Guiana',
            PF: 'French Polynesia',
            TF: 'French Southern Territories',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Germany',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Greece',
            GL: 'Greenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island & Mcdonald Islands',
            VA: 'Holy See (Vatican City State)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungary',
            IS: 'Iceland',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Iran',
            IQ: 'Iraq',
            IE: 'Ireland',
            IM: 'Isle Of Man',
            IL: 'Israel',
            IT: 'Italy',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwait',
            KG: 'Kyrgyzstan',
            LA: 'Lao People\'s Democratic Republic',
            LV: 'Latvia',
            LB: 'Lebanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libyan Arab Jamahiriya',
            LI: 'Liechtenstein',
            LT: 'Lithuania',
            LU: 'Luxembourg',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldives',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshall Islands',
            MQ: 'Martinique',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesia',
            MD: 'Moldova',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morocco',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Netherlands',
            AN: 'Netherlands Antilles',
            NC: 'New Caledonia',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk Island',
            MP: 'Northern Mariana Islands',
            NO: 'Norway',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestinian Territory, Occupied',
            PA: 'Panama',
            PG: 'Papua New Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippines',
            PN: 'Pitcairn',
            PL: 'Poland',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Romania',
            RU: 'Russia',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts And Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre And Miquelon',
            VC: 'Saint Vincent And Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome And Principe',
            SA: 'Saudi Arabia',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovakia',
            SI: 'Slovenia',
            SB: 'Solomon Islands',
            SO: 'Somalia',
            ZA: 'South Africa',
            GS: 'South Georgia And Sandwich Isl.',
            ES: 'Spain',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Suriname',
            SJ: 'Svalbard And Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Sweden',
            CH: 'Switzerland',
            SY: 'Syrian Arab Republic',
            TW: 'Taiwan',
            TJ: 'Tajikistan',
            TZ: 'Tanzania',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad And Tobago',
            TN: 'Tunisia',
            TR: 'Turkey',
            TM: 'Turkmenistan',
            TC: 'Turks And Caicos Islands',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'United Arab Emirates',
            GB: 'United Kingdom',
            US: 'United States',
            UM: 'United States Outlying Islands',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Virgin Islands, British',
            VI: 'Virgin Islands, U.S.',
            WF: 'Wallis And Futuna',
            EH: 'Western Sahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    ru: {
        _name: 'Русский',
        autoCloseModal: {
            takeProfitStopLoss: 'Тейк Профит и Стоп Лосс',
            takeProfit: 'Тейк Профит',
            stopLoss: 'Стоп Лосс',
            updateOrder: 'Изменить ордер',
        },
        balanceModal: {
            balance: 'Баланс',
            credit: 'Кредит',
            equity: 'Средства',
            practice: 'Пробный',
            real: 'Реальный',
            account: 'Счет',
            invested: 'Вложено',
            profit: 'Прибыль',
            loss: 'Убыток',
            margin: 'Маржа',
            marginLevel: 'Уровень маржи',
            marginFree: 'Свободная маржа'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Депозит'
        },
        sideMenu: {
            marketWatch: 'обзор Рынков',
            activeOrders: 'открытые ордера',
            tradingHistory: 'история Торговли',
            economicCalendar: 'экономический Календарь',
            marketNews: 'Новости рынков'
        },
        closePositionModal: {
            closeOrder: 'Закрыть позицию',
            areYouSure: 'Вы уверены, что хотите закрыть позицию',
            buy: 'Купить',
            sell: 'Продать',
            yes: 'Да',
            no: 'Нет',
        },
        chart: {
            addNewChart: 'Добавить график',
        },
        symbolsModal: {
            watchlist: 'Избранное',
            asset: 'Инструмент',
            price: 'Цена',
            changePct: 'Изменения 24ч'
        },
        pendingModal: {
            pending: 'Цена', // <- NB
            assetPrice: 'Цена инструмента',
            current: 'Текущая',
            revert: 'Вернуть к рыночной',
            automatically: 'Позиция будет открыта автоматически при достижении указанного уровня'
        },
        orderMenu: {
            volume: 'Объем',
            lots: 'лоты',
            units: 'юниты',
            currency: 'валюта',
            contractSize: 'Размер контракта',
            position: 'Позиция',
            margin: 'Маржа',
            freeMargin: 'Свободная маржа',
            takeProfitStopLoss: 'Тейк Профит и Стоп Лосс',
            pending: 'Цена',
            market: 'Рынок',
            leverage: 'Плечо',
            spread: 'Спрэд',
            notSet: 'Не задано',
            at: 'по',
            buy: 'купить',
            sell: 'продать',
        },
        footer: {
            supportBanner: 'КАЖДЫЙ ДЕНЬ, 24/7',
            currentTime: 'ТЕКУЩЕЕ ВРЕМЯ',
            liveChat: 'Поддержка'
        },
        statusBar: {
            balance: 'Баланс',
            credit: 'Кредит',
            equity: 'Средства',
            margin: 'Маржа',
            marginLevel: 'Уровень маржи',
            freeMargin: 'Свободная маржа',
            pnl: 'Прибыль/Убыток',
            profitTotal: 'Прибыль всего'
        },
        accountModal: {
            dateRegistered: 'Дата Регистрации',
            userId: 'ID Пользователя',
            dashboard: 'Панель пользователя',
            personalData: 'Личные Данные',
            deposit: 'Депозит',
            withdrawFunds: 'Вывод Средств',
            savings: 'Накопительные Счета',
            settings: 'Настройки',
            logout: 'Выйти'
        },
        depositModal: {
            BT_INVOICE: 'Введите сумму и сформируйте счет',
            BT_TITLE: 'Ваши платежные реквизиты',
            PENDING_DEPOSITS_LIMIT: 'Лимит депозитов',
            BT_ACCOUNT: 'Счет получателя',
            BT_BANK_NAME: 'Наименование банка получателя',
            BT_BENEFICIARY: 'Получатель',
            BT_ADDRESS: 'Счет получателя',
            BT_SWIFT: 'БИК Банка получателя',
            BT_IBAN: 'IBAN',
            BT_REFERENCE_CODE : 'Банковский референсный код',
            BT_INN: 'ИНН',
            BT_KPP: 'КПП',
            BT_CORRESPONDING: 'Корреспондентский счёт',
            TOKEN_AMOUNT: 'Сумма',
            TOKEN_SUBTOTAL: 'Промежуточный итог',
            TOKEN_TOTAL: 'Всего',
            TOKEN_DESCRIPTION: 'Описание',
            TOKEN_PAYMENT_DETAILS: 'Платежные реквизиты',
            makeDeposit: 'Внести Депозит',
            practiceAccount: 'Учебнй Счет',
            realAccount: 'Реальный Счет',
            noWithdrawals: 'Нет вывода средств',
            easyWithdrawals: 'Легкий вывод средств',
            allAssets: 'Доступны все инструменты',
            fullFledged: 'Полнофункциональная платформа',
            fillUpTo: 'Пополнить до',
            freeReplenishment: 'Бесплатное Пополнение',
            topUp: 'Пополните Ваш Счет',
            minimumAmount: 'Минимальная Сумма',
            canSwitch: 'Вы можете переключиться между счетами в любое время'
        },
        gridsModal: {
            chartGrids: 'СЕТКА ГРАФИКОВ',
            chart1: '1 график',
            chart2: '2 графика',
            chart3: '3 графика',
            chart4: '4 графика',
        },
        noConnectionModal: {
            connectionLost: 'Соединение с сервером потеряно',
            retryNow: 'Попробовать еще',
        },
        loginModal: {
            loginToTradeRoom: 'Авторизация',
            email: 'Электронная Почта',
            emailAndId: 'Электронная Почта / ID Номер',
            enterEmail: 'Введите почту',
            twoFactor: 'Код 2FA (если активна)',
            twoFactorAuth: 'Двухфакторная аутентификация',
            password: 'Пароль',
            yourPassword: 'Ваш пароль',
            signIn: 'Войти',
            dontHaveAccount: 'У вас нет аккаунта?',
            restore: 'Восстановить',
            fullName: 'Полное имя',
            havePromo: 'У меня есть промо код',
            promo: 'Промо код',
            login: 'Вход в систему',
            haveAccount: 'Уже есть аккаунт?',
            hide: 'Скрыть',
            goTo: 'Вернуться назад'
        },
        newPasswordModal: {
            setNewPassword: 'Установить новый пароль',
            newPassword: 'Новый пароль',
            confirmNewPassword: 'Подтвердите новый пароль',
            setButton: 'Установить'
        },
        registerModal: {
            createNewAccount: 'Создать Новый Счет',
            email: 'Электронная Почта',
            firstName: 'Имя',
            lastName: 'Фамилия',
            country: 'Страна',
            phone: 'Телефон',
            password: 'Пароль',
            createAccount: 'Создать Счет',
            currency: 'Валюта'
        },
        forgotPasswordModal: {
            forgotPassword: 'Забыли Пароль?',
            submitEmail: 'Пожалуйста, введите имейл, указанный при регистрации, проверьте свой ящик и следуйте инструкциям, полученным в письме',
            submit: 'Отправить'
        },
        notifications: {
            error: 'Ошибка',
            success: 'Успешно',
            deposit: 'Депозит',
            withdrawal: 'Вывод',
            depositApproved: 'Депозит одобрен',
            depositDeclined: 'Депозит отклонен',
            withdrawalApproved: 'Вывод средств одобрен',
            withdrawalDeclined: 'Вывод средств отклонен',
            withdrawalCancelled: 'Вывод средств был отменен'
        },
        markets: {
            forex: 'Форекс',
            stocks: 'Акции',
            commodities: 'Сырье',
            indices: 'Индексы',
            crypto: 'Криптовалюта',
            metals: 'Металлы',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'ПОКУПКА',
            sell: 'ПРОДАЖА',
            volume: 'Объем',
            entryPrice: 'Цена Входа',
            takeProfit: 'Тейк Профит',
            stopLoss: 'Стоп Лосс',
            maxPosition: 'Макс. Позиция',
            calculate: 'Рассчитать',
            calculationsFor: 'Рассчет для',
            leverage: 'Плечо',
            requiredMargin: 'Требуемая Маржа',
            profitFromTP: 'Прибыль от ТП',
            lossFromSL: 'Убыток от СЛ',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Обзор Рынков',
            search: 'Поиск...',
        },
        widgetOrders: {
            commission: 'Комиссия',
            volume: 'Объем',
            margin: 'Маржа',
            active: 'Открытые',
            pending: 'В Ожидании',
            activeOrders: 'Открытые ордера',
            portfolio: 'Портфолио',
            allPositions: 'Все Позиции',
            noPositionsLine1: 'У вас ещё нет',
            noPositionsLine2: 'открытых позиций',
            show: 'Показать',
            more: 'больше',
            less: 'меньше',
            purchaseTime: 'Открыт',
            closePosition: 'Закрыть',
            priceOpen: 'Цена открытия',
            priceSl: 'Цена Стоп Лосс',
            priceTp: 'Цена Тейк Профит',
            type: 'Направление позиции',
            pnl: 'Прибыль/Убыток',
            cancelOrder: 'Отменить Ордер',
            orderType: 'Тип Ордера',
            cancelled: 'Отменено',
            tradingHistory: 'Торговая История',
            noHistoryLine1: 'У вас ещё нет',
            noHistoryLine2: 'закрытых позиций',
            sortByTime: 'Сортировать по времени',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Экономический Календарь'
        },
        widgetMarketNews: {
            marketNews: 'Новости Рынков'
        },
        ordersPane: {
            ordersActive: 'Активные ордеры',
            ordersHistory: 'История Ордеров',
            id: 'ID',
            symbol: 'Символ',
            type: 'Тип',
            volume: 'Объем',
            openPrice: 'Цена открытия',
            openTime: 'Время открытия',
            closePrice: 'Цена закрытия',
            closeTime: 'Время закрытия',
            sl: 'СЛ',
            tp: 'ТП',
            price: 'Цена',
            pnl: 'ПиУ',
            actions: 'Действия',
            edit: 'Править',
            close: 'Закрыть',
            commission: 'Комиссия',
            swap: 'Своп',
            swapLong: 'Своп Лонг',
            swapShort: 'Своп Шорт'
        },
        assetInfoModal: {
            assetInfo: 'Информация об Инструменте',
            tradingConditions: 'Торговые Условия',
            information: 'Информация',
            bid: 'Покупка',
            ask: 'Продажа',
            sessionChange: 'Изменение за сессию',
            tradeNow: 'Торговать',
            opens: 'Открывается',
            closes: 'Закрывается',
            at: 'в',
            open247: 'Открыто круглосуточно',
            today: 'сегодня',
            tomorrow: 'завтра',
            sunday: 'Воскресенье',
            monday: 'Понедельник',
            tuesday: 'Вторник',
            wednesday: 'Среда',
            thursday: 'Четверг',
            friday: 'Пятница',
            saturday: 'Суббота',
            contractSpecification: 'Спецификация контракта',
            symbol: 'Символ',
            name: 'Наименование',
            market: 'Рынок',
            baseCurrency: 'Базовая Валюта',
            digits: 'Точность',
            lotSize: 'Размер лота',
            lotStep: 'Шаг лота',
            minVolume: 'Мин Объем',
            maxVolume: 'Макс Объем',
            leverage: 'Плечо',
            commission: 'Комиссия',
            swapLong: 'Своп Лонг',
            swapShort: 'Своп Шорт',
            schedule: 'Расписание',
            weekday: 'День Недели',
            tradingTime: 'Время Торгов',
            closed: 'закрыто',
            sell: 'Продавать',
            buy: 'Покупать',
            low: 'Мин',
            high: 'Макс',
            oneHourChange: 'Изменение 1 час',
            oneDayChange: 'Изменение 1 день',
            oneWeekChange: 'Изменение 1 неделя',
            oneMonthChange: 'Изменение 1 месяц',
            threeMonthsChange: 'Изменение 3 месяца',
            oneYearChange: 'Изменение 1 год',
            loading: 'Загрузка...',
        },
        fullScreen: {
            startTrading: 'К Торговле',
            deposit: 'Депозит',
            dashboard: 'Панель Управления',
            personalInfo: 'Личные Данные',
            withdrawal: 'Вывод Средств',
            verification: 'Верификация',
            accounts: 'Счета',
            liveChat: 'Поддержка',
            savings: 'Накопительные Счета',
            settings: 'Настройки',
            logOut: 'Выход',
        },
        fullScreenDeposit: {
            name: 'Cardholder\'s name',
            address: 'Address',
            DOB: 'DOB',
            bankName: 'Bank name',
            additionalInfo: 'Чтобы пополнить свой счет, пожалуйста, свяжитесь напрямую с вашим менеджером',
            additionalInfoOnlyBTCpsp: 'Чтобы открыть другие способы оплаты, пожалуйста, предоставьте KYC или свяжитесь с вашим менеджером',
            sofortPspMessage: 'Наша финансовая команда свяжется с вами, чтобы помочь вам внести депозит',
            addWalletAddress: 'Кошелек для данного метода не установлен. Пожалуйста, обратитсь к Вашему агенту или в службу поддержки.',
            addressReceipt: 'Пожалуйста, подождите, получаем текущий адрес',
            makeDeposit: 'ВНЕСТИ ДЕПОЗИТ',
            instant: 'Моментально',
            minutes: 'минут',
            hours: 'часов',
            days: 'дней',
            amount: 'Сумма',
            continue: 'Продолжить',
            qrCode: 'QR код',
            depositAddress: 'АДРЕС КОШЕЛЬКА',
            copy: 'Копировать',
            last: 'ПОСЛЕДНИЕ',
            deposits: 'ДЕПОЗИТОВ',
            time: 'Время',
            currency: 'Валюта',
            status: 'Статус',
            info: 'Инфо',
        },
        fullScreenCcExt: {
            pleaseWait: 'Пожалуйста, подождите. Идет соединение с сервисом приема платежей...',
            cardPayment: 'Оплата картоу',
            inOrder: 'Чтобы отправить сумму',
            pleaseProvide: 'пожалуйста предоставьте данные карты:'
        },
        fullScreenDashboard: {
            totalTaxes: 'Всего налогов',
            totalBalance: 'Итоговый Баланс',
            totalPnl: 'Прибыль / Убыток',
            profitableOrders: 'Прибыльные Ордеры',
            roi: 'ROI',
            activityLog: 'Лог Активности',
            loginFromIp: 'Вход с IP',
            tradingResults: 'Результаты Торговли',
            week: 'неделя',
            month: 'Месяц',
            year: 'Год',
            successRate: 'Успешные сделки',
            closedWithProfit: 'Закрыто с прибылью',
            closedWithLoss: 'Закрыто в убыток',
            account: 'Счет',
            balance: 'Баланс',
            leverage: 'Плечо',
            credit: 'Кредит',
            tradeNow: 'Торговать',
            usingCurrentRate: 'по текущей выбранной валюте',
            totalDeposits: 'Всего депозитов'
        },
        fullScreenPersonal: {
            personalDetails: 'Персональные Данные',
            profilePhoto: 'Фото Пользователя',
            firstName: 'Имя',
            country: 'Страна',
            lastName: 'Фамилия',
            address: 'Адрес',
            email: 'Электронная почта',
            phone: 'Номер телефона',
            saveChanges: 'Сохранить Изменения'
        },
        fullScreenAvatar: {
            dropFile: 'Для загрузки перетащите файл на круг выше',
            notAllowed: 'Не разрешается размещать',
            notAllowedLine1: 'Эротические и порнографические фотографии',
            notAllowedLine2: 'Изображения, разжигающие этническую или расовую ненависть или агрессию',
            notAllowedLine3: 'Фотографии с детьми до 18 лет',
            notAllowedLine4: 'Фотографии, защищенные авторским правом',
            notAllowedLine5: 'Фотографии размером более 5 Мб и в формате, отличном от JPG, GIF или PNG',
            requirements: 'На фото должно быть четко видно Ваше лицо. Все загруженные фотографии должны соответсвовать критериям, описанным выше. В противном случае они будут принудительно удалены.'
        },
        fullScreenWithdrawal: {
            withdrawal: 'Вывод Средств',
            requestNewWithdrawal: 'ЗАПРОСИТЬ ВЫВОД СРЕДСТВ',
            amount: 'Сумма',
            currentBalance: 'Ваш текущий баланс',
            withdrawAll: 'Вывести все',
            requestWithdrawal: 'Запросить Вывод',
            last: 'ПОСЛЕДНИЕ',
            withdrawalRequests: 'ВЫВОД СРЕДСТВ',
            time: 'Время',
            currency: 'Валюта',
            info: 'Инфо',
            status: 'Статус',
            bank: 'Банк',
            bitcoin: 'Биткоин',
            card: 'Карта',
            ethereum: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Детали Вывода Средств',
            address: 'Адрес Кошелька',
            bankAccount: 'Номер Счета',
            bankHolder: 'Имя Владельца Счета',
            bankIban: 'IBAN',
            bankSwift: 'SWIFT код',
            sortCode: 'Код Сортировки',
            ifscCode: 'IFSC код',
            bankName: 'Название Банка',
            bankBranch: 'Филиал Банка',
            cardNumber: 'Номер Карты',
            cardHolder: 'Имя Владельца Карты',
            incorrectAmountByFreeMargin: 'The amount is incorrect you can withdraw the amount of free margin',
            closeOrders: 'before close all orders'
        },
        fullScreenVerification: {
            dragAndDrop: 'для загрузки перетащите документ в эту область',
            documentsList: 'СПИСОК ЗАГРУЖЕННЫХ ДОКУМЕНТОВ',
            document: 'Документ',
            timeUploaded: 'Загружен',
            timeProcessed: 'Обработан',
            status: 'Статус',
            types: ['Подтверждeние Личности', 'Подтверждeние Адреса', 'Карта - Лицевая Сторона', 'Карта - Обратная Сторона', 'Подтверждeние Личности - Обратная Сторона', 'Селфи','Other'],
            types2: ['Proof of Id (ID CARD, PASSPORT, DRIVER LICENCE)', 'Proof of Residence (Utility bill or Bank statement)', 'Credit Card Front', 'Credit Card Back', 'Proof of Id Back (ID CARD BACK, DRIVER LICENCE BACK)', 'Selfie','Other'],
            upload: 'Загрузить',
            isConfirmed: 'одобрено',
            uploadAnotherDocument: 'Загрузите другой документ',
            isUnderReview: 'находится в обработке',
            questionnaire: 'Опросник',
            sendAnswers: 'Отправить Ответы',
            progressBarOf: 'из',
            progressBarText: 'ваших документов были загружены и подтверждены'
        },
        fullScreenAccounts: {
            manageAccounts: 'Перевод средств между счетами',
            transferFrom: 'Со счета',
            transferTo: 'На счет',
            amount: 'Сумма',
            makeTransfer: 'Перевести Средства',
            yourAccounts: 'Ваши Счета',
            account: 'Счет',
            currency: 'Валюта',
            balance: 'Баланс',
            credit: 'Кредит',
            makeActive: 'Сделать Активным',
            active: 'Активен'
        },
        fullScreenSupport: {
            chatOff: 'Чат будет доступен в рабочие часы',
            sendMessage: 'Отправить сообщение'
        },
        fullScreenSavings: {
            pct_0: 'Гибкий',
            pct_30: 'Фиксированный 1 Месяц',
            pct_90: 'Фиксированный 1 Квартал',
            pct_180: 'Фиксированный 6 Месяцев',
            pct_365: 'Фиксированный 1 Год',
            pct_730: 'Фиксированный 2 Года',
            savings: 'Накопительные счета',
            detailedInformation: 'Подробная Информация',
            pleaseSelect: 'Пожалуйста, выберите валюту и период',
            openSavings: 'Открытые накопительные счета',
            currentTime: 'Текущее время',
            releaseTime: 'Время закрытия счета',
            currencyChosen: 'Выбранная валюта',
            periodChosen: 'Выбранный период',
            yourRate: 'Ваша ставка',
            yourAnnualRate: 'Ваша годовая ставка',
            yourEarnings: 'Ваш доход',
            accountFrom: 'Счет, с которого произвести трансфер',
            enterAmount: 'Введите сумму',
            in: 'в',
            openAndInvest: 'Открыть накопительный счет и вложить',
            investment: 'Вложение',
            period: 'Период',
            daysTotal: 'Дней всего',
            finalEarnings: 'Итоговый доход',
            created: 'Создано',
            daysElapsed: 'Дней прошло',
            days: 'дней',
            earnings: 'Доход',
            fundsReleasedIn: 'До закрытия',
            claim: 'Забрать',
        },
        fullScreenSettings: {
            updatePassword: 'ОБНОВИТЬ ПАРОЛЬ',
            currentPassword: 'Текущий Пароль',
            newPassword: 'Новый Пароль',
            changePassword: 'Изменить Пароль',
            activationForGoogle: 'Код активации для Google Authenticator',
            activateAndEnter: 'Активируйте Authenticator и введите сгенерированный код в поле ниже',
            qrCode: 'QR код',
            activateProtection: 'Активировать Двухфакторную Защиту',
            protectionActive: 'ДВУХФАКТОРНАЯ ЗАЩИТА АКТИВНА',
            twoFactorCode: 'Код Верификации',
            disableProtection: 'Отключить Двухфакторную Защиту',
            language: 'Язык',
            dashboardCurrency: 'Валюта для панели управления',
            confirmNewPassword: 'Подтвердите новый пароль',
        },
        fullScreenReferrals: {
            yourLink: 'ВАША ССЫЛКА ДЛЯ ПРИВЛЕЧЕНИЯ',
            referredFriends: 'ПРИВЛЕЧЕННЫЕ ДРУЗЬЯ',
            id: 'ID',
            name: 'Имя',
            registered: 'Зарегистрироваться',
            level: 'Уровень',
            payout: 'Выплата',
            lastPayouts: 'ПОСЛЕДНИЕ ВЫПЛАТЫ',
            time: 'Время',
            amount: 'Сумма',
            currency: 'Валюта',
            info: 'Комментарий'
        },
        months: {
            Jan: 'Янв',
            Feb: 'Фев',
            Mar: 'Мар',
            Apr: 'Апр',
            May: 'Май',
            Jun: 'Июн',
            Jul: 'Июл',
            Aug: 'Авг',
            Sep: 'Сен',
            Oct: 'Окт',
            Nov: 'Ноя',
            Dec: 'Дек'
        },
        orderTypes: ['Покупка', 'Продажа', 'Лимитный Покупка', 'Лимитный Продажа', 'Стоп Покупка', 'Стоп Продажа'],
        statuses: ['Новый', 'Одобрен', 'Отклонен', 'В Обработке','На рассмотрении'],
        errors: {
            INVALID_FIELDS: 'Пожалуйста, проверьте правильность введенных вами данных',
            NOT_FOUND: 'Ошибка запроса',
            FORBIDDEN: 'Доступ запрещен. Пожалуйста, авторизуйтесь заново',
            SERVER_ERROR: 'Действие не удалось. Пожалуйста, попробуйте позже',
            INVALID_ACCOUNT: 'Сессия истекла. Пожалуйста, авторизуйтесь заново',
            INVALID_EMAIL: 'Неверная электронная почта',
            INVALID_PHONE: 'Неверный номер телефона',
            INVALID_COUNTRY: 'Неверная страна',
            INVALID_LOGIN: 'Неверные данные авторизации',
            USER_BLOCKED: 'Аккаунт заблокирован. Пожалуйста, свяжитесь со службой поддержки',
            USER_REGISTERED: 'Пользователь с такими электронной почтой или номером телефона уже зарегистрирован',
            INVALID_USER: 'Пользователь не найден',
            REJECTED: 'Операция запрещена согласно настройкам платформы',
            INVALID_OLD_PASSWORD: 'Текущий пароль неверен',
            INVALID_SYMBOL: 'Неверный торговый инструмент',
            INVALID_SYMBOL_PRECISION: 'Неверная точность после запятой',
            INVALID_FRAME: 'Неверный период',
            INVALID_CURRENCY: 'Неверная валюта',
            INVALID_PRODUCT: 'Неверный тип счета',
            INSUFFICIENT_FUNDS: 'Недостаточно денег. Пожалуйста, внесите депозит',
            INVALID_WITHDRAWAL: 'Запрос на вывод средств не найден',
            INVALID_STATUS: 'Неверный статус',
            INVALID_FILE: 'Неверный тип файла или файл слишком большой',
            INVALID_DOCUMENT: 'Документ не найден',
            INVALID_NAME: 'Неверное имя',
            INVALID_LOT_SIZE: 'Неверный размер лота',
            INVALID_LOT_STEP: 'Неверный шаг лота',
            INVALID_MIN_VOLUME: 'Неверный минимальный объем',
            INVALID_MAX_VOLUME: 'Неверный максимальный объем',
            INVALID_GROUP: 'Неверная группа',
            INVALID_SPREAD: 'Неверный спред',
            REQUEST_TIMEOUT: 'Время ожидания запроса истекло. Пожалуйста, попробуйте еще раз',
            INVALID_TYPE: 'Неверный тип ордера',
            INVALID_VOLUME: 'Неверный объем ордера',
            INVALID_SL: 'Неверный стоп лосс',
            INVALID_TP: 'Неверный тейк профит',
            INVALID_PRICE: 'Неверная цена ордера',
            INVALID_EXPIRATION: 'Неверное время экспирации',
            NOT_ENOUGH_MARGIN: 'Недостаточно маржи',
            INVALID_ORDER: 'Ордер не найден',
            MARKET_CLOSED: 'Торговля недоступна, рынок закрыт',
            INVALID_BALANCE_TYPE: 'Неверный тип балансовой операции',
            INVALID_HASH: 'Неверный хеш',
            HASH_EXPIRED: 'Ваша ссылка на сброс пароля истекла. Пожалуйста, запросите новую',
            INVALID_CODE: 'Неверный код 2FA',
            CHAT_DISABLED: 'Доступ к чату поддержки был закрыт',
            WITHDRAWAL_NOT_ALLOWED: 'Доступ к выводу средств был закрыт',
            TRADING_DISABLED: 'Торговля отключена для этого счета',
            PENDING_DEPOSITS_LIMIT: 'Превышен лимит депозитов',
            CLOSE_OPEN_ORDERS: 'Пожалуйста, закройте открытые ордера',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Возможно только одно неподтвержденное снятие средств',
            LOCAL_DEPOSIT_ONLY_REAL: 'Пополнение возможно только для реального аккаунта',
            LOCAL_DEPOSIT_POSITIVE: 'Сумма депозита должна быть больше 0',
            LOCAL_INVALID_CARD_NUMBER: 'Неверный номер карты',
            LOCAL_INVALID_CARD_EXPIRATION: 'Неверная дата истечения срока действия',
            LOCAL_INVALID_CVC: 'Неверный код CVV/CVC',
            LOCAL_PASSWORDS_NOT_MATCH: 'Пароли не сопадают, пожалуйста, введите снова',
            LOCAL_NO_TRADING_ACCOUNTS: 'У вас нет торговых счетов. Пожалуйста, свяжитесь со службой поддержки',
            LOCAL_MIN_DEPOSIT: 'Минмальная сумма пополнения - $',
            LOCAL_MIN_WITHDRAWAL: 'Минимальная сумма снятия - $',
            LOCAL_INVALID_FILE_TYPE: 'Неподдерживаемый формат файла. Пожалуйста, приложите файл JPG, PNG или PDF',
            LOCAL_MIN_STAKING: 'Минимальная сумма для выбранной валюты ',
            FLOOD_REJECTED: 'Вы отправляете сообщения слишком часто. Пожалуйста, повторите попытку позже',
            REGISTRATIONS_BLOCKED: "Спасибо за ваш запрос, один из наших представителей свяжется с вами как можно скорее",
        },
        countries: {
            AF: 'Афганистан',
            AX: 'Аландские острова',
            AL: 'Албания',
            DZ: 'Алжир',
            AS: 'Американское Самоа',
            AD: 'Андорра',
            AO: 'Ангола',
            AI: 'Ангилья',
            AQ: 'Антарктида',
            AG: 'Антигуа и Барбуда',
            AR: 'Аргентина',
            AM: 'Армения',
            AW: 'Аруба',
            AU: 'Австралия',
            AT: 'Австрия',
            AZ: 'Азербайджан',
            BS: 'Багамские Острова',
            BH: 'Бахрейн',
            BD: 'Бангладеш',
            BB: 'Барбадос',
            BY: 'Белоруссия',
            BE: 'Бельгия',
            BZ: 'Белиз',
            BJ: 'Бенин',
            BM: 'Бермудские Острова',
            BT: 'Бутан',
            BO: 'Боливия',
            BA: 'Босния и Герцеговина',
            BW: 'Ботсвана',
            BV: 'Остров Буве',
            BR: 'Бразилия',
            IO: 'Британская территория в Индийском океане',
            BN: 'Бруней',
            BG: 'Болгария',
            BF: 'Буркина-Фасо',
            BI: 'Бурунди',
            KH: 'Камбоджа',
            CM: 'Камерун',
            CA: 'Канада',
            CV: 'Кабо-Верде',
            KY: 'Острова Кайман',
            CF: 'ЦАР',
            TD: 'Чад',
            CL: 'Чили',
            CN: 'Китай',
            CX: 'Остров Рождества',
            CC: 'Кокосовые острова',
            CO: 'Колумбия',
            KM: 'Коморы',
            CG: 'Конго',
            CD: 'Демократическая Республика Конго',
            CK: 'Острова Кука',
            CR: 'Коста-Рика',
            CI: 'Кот-д’Ивуар',
            HR: 'Хорватия',
            CU: 'Куба',
            CY: 'Кипр',
            CZ: 'Чехия',
            DK: 'Дания',
            DJ: 'Джибути',
            DM: 'Доминика',
            DO: 'Доминиканская Республика',
            EC: 'Эквадор',
            EG: 'Египет',
            SV: 'Сальвадор',
            GQ: 'Экваториальная Гвинея',
            ER: 'Эритрея',
            EE: 'Эстония',
            ET: 'Эфиопия',
            FK: 'Фолклендские острова',
            FO: 'Фарерские острова',
            FJ: 'Фиджи',
            FI: 'Финляндия',
            FR: 'Франция',
            GF: 'Гвиана',
            PF: 'Французская Полинезия',
            TF: 'Французские Южные и Антарктические территории',
            GA: 'Габон',
            GM: 'Гамбия',
            GE: 'Грузия',
            DE: 'Германия',
            GH: 'Гана',
            GI: 'Гибралтар',
            GR: 'Греция',
            GL: 'Гренландия',
            GD: 'Гренада',
            GP: 'Гваделупа',
            GU: 'Гуам',
            GT: 'Гватемала',
            GG: 'Гернси',
            GN: 'Гвинея',
            GW: 'Гвинея-Бисау',
            GY: 'Гайана',
            HT: 'Гаити',
            HM: 'Остров Херд и острова Макдональд',
            VA: 'Ватикан',
            HN: 'Гондурас',
            HK: 'Гонк Конг',
            HU: 'Венгрия',
            IS: 'исландия',
            IN: 'Индия',
            ID: 'Индонезия',
            IR: 'Иран',
            IQ: 'Ирак',
            IE: 'Ирландия',
            IM: 'Остров Мэн',
            IL: 'Израиль',
            IT: 'Италия',
            JM: 'Ямайка',
            JP: 'Япония',
            JE: 'Джерси',
            JO: 'Иордания',
            KZ: 'Казахстан',
            KE: 'Кения',
            KI: 'Кирибати',
            KR: 'Корея',
            KW: 'Кувейт',
            KG: 'Киргизия',
            LA: 'Лаос',
            LV: 'Латвия',
            LB: 'Ливан',
            LS: 'Лесото',
            LR: 'Либерия',
            LY: 'Ливия',
            LI: 'Лихтенштейн',
            LT: 'Литва',
            LU: 'Люксембург',
            MO: 'Макао',
            MK: 'Македония',
            MG: 'Мадагаскар',
            MW: 'Малави',
            MY: 'Малайзия',
            MV: 'Мальдивы',
            ML: 'Мали',
            MT: 'Мальта',
            MH: 'Маршалловы Острова',
            MQ: 'Мартиника',
            MR: 'Мавритания',
            MU: 'Маврикий',
            YT: 'Майотта',
            MX: 'Мексика',
            FM: 'Микронезия',
            MD: 'Молдова',
            MC: 'Монако',
            MN: 'Монголия',
            ME: 'Черногория',
            MS: 'Монтсеррат',
            MA: 'Марокко',
            MZ: 'Мозамбик',
            MM: 'Мьянма',
            NA: 'Намибия',
            NR: 'Науру',
            NP: 'Непал',
            NL: 'Нидерланды',
            AN: 'Нидерландские Антильские острова',
            NC: 'Новая Каледония',
            NZ: 'Новая Зеландия',
            NI: 'Никарагуа',
            NE: 'Нигер',
            NG: 'Нигерия',
            NU: 'Ниуэ',
            NF: 'Норфолк',
            MP: 'Северные Марианские острова',
            NO: 'Норвегия',
            OM: 'Оман',
            PK: 'Пакистан',
            PW: 'Палау',
            PS: 'Палестинские Территории',
            PA: 'Панама',
            PG: 'Папуа — НоваяГвинея',
            PY: 'Парагвай',
            PE: 'Перу',
            PH: 'Филиппины',
            PN: 'Острова Питкэрн',
            PL: 'Польша',
            PT: 'Португалия',
            PR: 'Пуэрто-Рико',
            QA: 'Катар',
            RE: 'Реюньон',
            RO: 'Румыния',
            RU: 'Россия',
            RW: 'Руанда',
            BL: 'Сен-Бартелеми',
            SH: 'Остров Святой Елены',
            KN: 'Сент-Китс и Невис',
            LC: 'Сент-Люсия',
            MF: 'Сен-Мартен',
            PM: 'Сен-Пьер и Микелон',
            VC: 'Сент-Винсент и Гренадины',
            WS: 'Самоа',
            SM: 'Сан Марино',
            ST: 'Сан-Томе и Принсипи',
            SA: 'Саудовская Арабия',
            SN: 'Сенегал',
            RS: 'Сербия',
            SC: 'Сейшелы',
            SL: 'Сьерра-Леоне',
            SG: 'Сингапур',
            SK: 'Словакия',
            SI: 'Словения',
            SB: 'Соломоновы Острова',
            SO: 'Сомали',
            ZA: 'ЮАР',
            GS: 'Южная Георгия и Южные Сандвичевы Острова',
            ES: 'Испания',
            LK: 'Шри-Ланка',
            SD: 'Судан',
            SR: 'Суринам',
            SJ: 'Шпицберген и Ян-Майен',
            SZ: 'Эсватини',
            SE: 'Швеция',
            CH: 'Швейцария',
            SY: 'Сирия',
            TW: 'тайвань',
            TJ: 'Таджикистан',
            TZ: 'Танзания',
            TH: 'Тайланд',
            TL: 'Восточный Тимор',
            TG: 'Того',
            TK: 'Токелау',
            TO: 'Тонга',
            TT: 'Тринидад и Тобаго',
            TN: 'Тунис',
            TR: 'Турция',
            TM: 'Туркменистан',
            TC: 'Тёркс и Кайкос',
            TV: 'Тувалу',
            UG: 'Уганда',
            UA: 'Украина',
            AE: 'ОАЭ',
            GB: 'Великобритания',
            US: 'США',
            UM: 'Внешние малые острова США',
            UY: 'Уругвай',
            UZ: 'Узбекистан',
            VU: 'Вануату',
            VE: 'Венесуэла',
            VN: 'Вьетнам',
            VG: 'Виргинские Острова',
            VI: 'Виргинские Острова (США)',
            WF: 'Уоллис и Футуна',
            EH: 'Западная Сахара',
            YE: 'Йемен',
            ZM: 'Замбия',
            ZW: 'Зимбабве'
        },
    },
    pl: {
        _name: 'Polski',
        autoCloseModal: {
            takeProfitStopLoss: 'Odbierz zysk i zatrzymaj strate',
            takeProfit: ' Odbierz zysk',
            stopLoss: ' Zatrzymaj strate',
            updateOrder: ' Zaktualizuj zamówienie'
        },
        balanceModal: {
            balance: 'Saldo',
            credit: ' Kredyt',
            equity: ' Kapital',
            practice: ' PRAKTYKA',
            real: ' REALNY',
            account: ' KONTO',
            invested: ' Zainwestowany',
            profit: ' Zysk',
            loss: ' Strata',
            margin: ' Margines',
            marginLevel: 'Poziom marginesu',
            marginFree: 'Wolny margines'
        },
        header: {
            cfd: 'CFD',
            platform: ' Platforma',
            deposit: ' Depozyt'
        },
        sideMenu: {
            marketWatch: 'Obserwacja rynku',
            activeOrders: 'Aktywne zamówienia',
            tradingHistory: 'Historia handlu',
            economicCalendar: 'Kalendarz ekonomiczny',
            marketNews: 'Wiadomosci rynkowe'
        },
        closePositionModal: {
            closeOrder: 'Zamknij pozycje',
            areYouSure: 'Czy na pewno zamkniesz pozycje',
            buy: 'Kupic',
            sell: 'Sprzedac',
            yes: 'Tak',
            no: 'Nie',
        },
        chart: {
            addNewChart: 'Dodaj nowy wykres',
        },
        symbolsModal: {
            watchlist: 'Lista obserwowanych',
            asset: 'Aktywa',
            price: 'Cena',
            changePct: 'Wymiana 24h'
        },
        pendingModal: {
            pending: 'W oczekiwaniu',
            assetPrice: 'Cena aktywów',
            current: ' Aktualny',
            revert: 'Powrót do ceny rynkowej',
            automatically: 'Pozycja zostanie otwarta automatycznie, gdy cena osiegnie ten poziom'
        },
        orderMenu: {
            volume: 'Pojemnosc',
            lots: 'partii',
            units: ' jednostki',
            currency: 'waluta',
            contractSize: 'Wielkosc kontraktu',
            position: 'Pozycja',
            margin: 'Margines',
            freeMargin: 'Wolny margines',
            takeProfitStopLoss: 'Odbierz zysk i zatrzymaj strate',
            pending: 'W oczekiwaniu',
            market: 'Rynek',
            leverage: 'Dźwignia',
            spread: 'Spread',
            notSet: 'Nie ustawiony',
            at: 'w',
            buy: 'kupić',
            sell: 'sprzedać',
        },
        footer: {
            supportBanner: 'KAZDEGO DNIA PRZEZ CALE DOBE',
            currentTime: 'OBECNY CZAS',
            liveChat: 'Czat na zywo'
        },
        statusBar: {
            balance: 'Saldo',
            credit: 'Kredyt',
            equity: 'Kapital',
            margin: 'Margines',
            marginLevel: 'Poziom marginesu',
            freeMargin: 'Wolny margines',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Data rejestracji',
            userId: 'Identyfikator uzytkownika',
            dashboard: 'Dashboard',
            personalData: 'Dane osobiste',
            deposit: 'Depozyt',
            withdrawFunds: 'Wypłatę srodków',
            savings: 'Oszczednosci',
            settings: 'Ustawienia',
            logout: 'Wyloguj sie'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            BT_ACCOUNT: 'Account',
            BT_BANK_NAME: 'Bank Name',
            BT_BENEFICIARY: 'Beneficiary',
            BT_ADDRESS: 'Beneficiary Address',
            BT_SWIFT: 'Swift',
            BT_IBAN: 'IBAN',
            BT_REFERENCE_CODE : 'Kod referencyjny banku',
            BT_INN: 'INN',
            BT_KPP: 'Correspondent account',
            BT_CORRESPONDING: '',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Zlozyc depozyt',
            practiceAccount: 'Konto treningowe',
            realAccount: 'Prawdziwe konto',
            noWithdrawals: 'Brak wyplat',
            easyWithdrawals: 'latwe wyplaty',
            allAssets: 'Wszystkie aktywy dostepne',
            fullFledged: 'Pelnoprawna platforma',
            fillUpTo: 'Wypelnij do',
            freeReplenishment: 'Bezplatne uzupelnianie',
            topUp: 'Doladuj swoje konto',
            minimumAmount: 'Stawka minimalna',
            canSwitch: 'Mozesz przelaczac sie miedzy swoimi kontami w dowolnym momencie'
        },
        gridsModal: {
            chartGrids: 'SIATKI WYKRESÓW',
            chart1: '1 wykres',
            chart2: '2 wykres',
            chart3: '3 wykres',
            chart4: '4 wykres',
        },
        noConnectionModal: {
            connectionLost: 'Polaczenie z serwerem zostalo utracone',
            retryNow: 'Spróbuj teraz',
        },
        loginModal: {
            loginToTradeRoom: 'Zaloguj sie do Traderoom',
            email: 'Adres e-mail',
            emailAndId: 'Adres e-mail / Numer identyfikacyjny',
            enterEmail: 'Wpisz swój e-mail',
            twoFactor: 'Kod 2FA (jesli jest wlaczony)',
            twoFactorAuth: 'Uwierzytelnianie dwuskladnikowe',
            password: 'Haslo',
            yourPassword: 'Twoje haslo',
            signIn: 'Zaloguj sie',
            dontHaveAccount: 'Don’t have an account?',
            restore: 'Restore',
            fullName: 'Full Name',
            havePromo: 'I have a promo-code',
            promo: 'Promo code',
            login: 'Login',
            haveAccount: 'Already have an account?',
            hide: 'Hide',
            goTo: 'Go to back'
        },
        newPasswordModal: {
            setNewPassword: 'Ustaw nowe haslo',
            newPassword: 'Nasza zalety',
            confirmNewPassword: 'Potwierdź nowe hasło',
            setButton: 'Ustaw'
        },
        registerModal: {
            createNewAccount: 'Utwórz nowe konto',
            email: 'Adres e-mail',
            firstName: 'Imie',
            lastName: 'Nazwisko',
            country: 'Kraj',
            phone: 'Telefon',
            password: 'Haslo',
            createAccount: 'Utwórz konto',
            currency: 'Waluta'
        },
        forgotPasswordModal: {
            forgotPassword: 'Nie pamietam hasla?',
            submitEmail: 'Prosimy o podanie e-maila uzytego do rejestracji, sprawdzenie skrzynki odbiorczej i postepowanie zgodnie z podanymi instrukcjami.',
            submit: 'Przeslij'
        },
        notifications: {
            error: 'Blad',
            success: 'Sukces',
            deposit: 'Depozyt',
            withdrawal: 'Wycofanie',
            depositApproved: 'Depozyt zostal zatwierdzony',
            depositDeclined: 'Depozyt zostal odrzucony',
            withdrawalApproved: 'Wycofanie zatwierdzone',
            withdrawalDeclined: 'Odmówiono wyplaty',
            withdrawalCancelled: 'Wypłata została anulowana'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Akcje',
            commodities: 'Towary',
            indices: 'Wskazniki',
            crypto: 'Kryptowaluta',
            metals: 'Metale',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'BUY',
            profitCalculator: 'Profit Calculator',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Obserwacja rynku',
            search: 'Szukac  ...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Tom',
            margin: 'Marza',
            active: 'Aktywny',
            pending: 'W trakcie realizacji',
            activeOrders: 'Zamówienia aktywne',
            portfolio: 'Portfolio',
            allPositions: 'Wszystkie stanowiska',
            noPositionsLine1: 'Nie masz otwartego',
            noPositionsLine2: 'jeszcze stanowiska',
            show: 'Pokaz',
            more: 'wiecej',
            less: 'mniej',
            purchaseTime: 'Czas zakupu',
            closePosition: 'Pozycja zamkniecia',
            priceOpen: 'Cena zakupu',
            priceSl: 'Cena Stop Loss',
            priceTp: 'Cena Take Profit',
            type: 'Pozycja Kierunek',
            pnl: 'Zysk/Strata',
            cancelOrder: 'Anuluj zamówienie',
            orderType: 'Typ zamówienia',
            cancelled: 'Anulowane',
            tradingHistory: 'Historia handlu',
            noHistoryLine1: 'Nie masz zadnych',
            noHistoryLine2: 'jeszcze zamkniete transakcje',
            sortByTime: 'Sortuj według czasu',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Kalendarz ekonomiczny'
        },
        widgetMarketNews: {
            marketNews: 'Wiadomosci rynkowe'
        },
        ordersPane: {
            ordersActive: 'Zamówienia Aktywne',
            ordersHistory: 'Historia zamówien',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Typ',
            volume: 'Tom',
            openPrice: 'Cena wywolawcza',
            openTime: 'Czas otwarty',
            closePrice: 'Zamknij cene',
            closeTime: 'Czas zamkniecia',
            sl: 'SL',
            tp: 'TP',
            price: 'Cena',
            pnl: 'PnL',
            actions: 'Dzialania',
            edit: 'Edytować',
            close: 'Zamknać',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Information',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        months: {
            Jan: 'Styczen',
            Feb: 'Luty',
            Mar: 'Marzec',
            Apr: 'Kwiecien',
            May: 'Maj',
            Jun: 'Czerwiec',
            Jul: 'Lipiec',
            Aug: 'Sierpien',
            Sep: 'Wrzesien',
            Oct: 'Pazdziernik',
            Nov: 'Listopad',
            Dec: 'Grudzien'
        },
        fullScreen: {
            startTrading: 'Rozpocznij Handel',
            deposit: 'Dokonaj wplaty',
            dashboard: 'Pulpit',
            personalInfo: 'Aktualizacja danych osobowych',
            withdrawal: 'Wypłata środków',
            verification: 'Weryfikacja tożsamości',
            accounts: 'Zarządzaj kontami',
            liveChat: 'Czat na żywo',
            savings: 'Lokaty',
            settings: 'Ustawienia platformy',
            logOut: 'Wyloguj się',
        },
        fullScreenDeposit: {
            name: 'Cardholder\'s name',
            address: 'Address',
            DOB: 'DOB',
            bankName: 'Bank name',
            additionalInfo: 'Aby wpłacić więcej na swoje konto, skontaktuj się bezpośrednio z swoim menedżerem konta',
            additionalInfoOnlyBTCpsp: 'Aby otworzyć inne metody płatności, proszę dostarczyć KYC lub skontaktować się z menedżerem konta',
            sofortPspMessage: 'Nasz zespół finansowy skontaktuje się z Tobą, aby pomóc Ci dokonać wpłaty',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'DOKONAJ WPLATY DEPOZYTU',
            instant: 'Natychmiast',
            minutes: 'minuty',
            hours: 'godziny',
            days: 'dni',
            amount: 'Kwota',
            continue: 'Kontynuuj',
            qrCode: 'KOD QR',
            depositAddress: 'ADRES DEPOZYTOWY',
            copy: 'Kopia',
            last: 'OSTATNI',
            deposits: 'DEPOZYTY',
            time: 'Czas',
            currency: 'Waluta',
            status: 'Status',
            info: 'Informacje',
        },
        fullScreenCcExt: {
            pleaseWait: 'Prosze czekac. Polaczenie z dostawca uslug platniczych...',
            cardPayment: 'Platnosc karta',
            inOrder: 'W celu wyslania kwoty',
            pleaseProvide: 'Prosze podac dane karty:'
        },
        fullScreenDashboard: {
            totalTaxes: 'Total Taxes',
            totalBalance: 'Całkowite Saldo',
            totalPnl: 'Całkowity PNL',
            profitableOrders: 'Zyskowne pozycję',
            roi: 'ROI',
            activityLog: 'Lista Aktywności',
            loginFromIp: 'Login from IP',
            tradingResults: 'Trading Results',
            week: 'Tydzień',
            month: 'Miesiąc',
            year: 'Rok',
            successRate: 'Wskaźnik Sukcesu',
            closedWithProfit: 'Zamknięte z Zyskiem',
            closedWithLoss: 'Zamknięte ze stratą',
            account: 'Konto',
            balance: 'Saldo',
            leverage: 'Dźwignia',
            credit: 'Kredyt',
            tradeNow: 'Rozpocznij Handel',
            usingCurrentRate: 'using current exchange rate',
            totalDeposits: 'Total Deposits'
        },
        fullScreenPersonal: {
            personalDetails: 'Dane osobowe',
            profilePhoto: 'Zdjecie profilowe',
            firstName: 'Imię',
            country: 'Kraj',
            lastName: 'Nazwisko',
            address: 'Adres',
            email: 'E-mail',
            phone: 'Numer telefonu',
            saveChanges: 'Zapisac zmiany'
        },
        fullScreenAvatar: {
            dropFile: 'Wrzuc plik na okrag powyzej, aby go przeslac',
            notAllowed: 'Niedozwolone jest publikowanie',
            notAllowedLine1: 'Zdjecia o charakterze jednoznacznie seksualnym lub pornograficznym',
            notAllowedLine2: 'Obrazy majace na celu podzeganie do nienawisci lub agresji na tle etnicznym lub rasowym',
            notAllowedLine3: 'Zdjecia z udzialem osób ponizej 18 roku zycia',
            notAllowedLine4: 'Zdjecia chronione prawami autorskimi osób trzecich',
            notAllowedLine5: 'Obrazy wieksze niz 5 MB i w formacie innym niz JPG, GIF lub PNG',
            requirements: 'Twoja twarz musi byc wyraznie widoczna na zdjeciu. Wszystkie zdjecia i filmy zamieszczane przez Ciebie musza spelniac te wymagania, w przeciwnym razie moga zostac usuniete.'
        },
        fullScreenWithdrawal: {
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'WNIOSEK O NOWA WYPŁATĘ',
            amount: 'Kwota',
            currentBalance: 'Stan Twojego rachunku biezacego wynosi',
            withdrawAll: 'Wycofac wszystkie',
            requestWithdrawal: 'Wypłać',
            last: 'OSTATNI',
            withdrawalRequests: 'Historia',
            time: 'Czas',
            currency: 'WALUTA',
            info: 'Informacje',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            card: 'Karta',
            ethereum: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Szczególy wypłaty',
            address: 'Adres portfela',
            bankAccount: 'Numer konta',
            bankHolder: 'Nazwa posiadacza konta',
            bankIban: 'IBAN',
            bankSwift: 'Kod SWIFT banku',
            sortCode: 'Kod sortowania',
            ifscCode: 'Kod IFSC',
            bankName: 'Nazwa Banku',
            bankBranch: 'Oddział Banku',
            cardNumber: 'Numer karty',
            cardHolder: 'Nazwa posiadacza karty',
            incorrectAmountByFreeMargin: 'The amount is incorrect you can withdraw the amount of free margin',
            closeOrders: 'before close all orders'
        },
        fullScreenVerification: {
            dragAndDrop: 'przeciagnij i upuść dokument na ten obszar',
            documentsList: 'LISTA PRZESLANYCH DOKUMENTÓW',
            document: 'Dokument',
            timeUploaded: 'Wyslano',
            timeProcessed: 'Zweryfikowano',
            status: 'Status',
            types: ['Przód dowodu', 'Dokument potwierdzający adres zamieszkania', 'Przód karty kredytowej', 'Tył karty kredytowej', 'Tył dowodu', 'Selfie','Other'],
            types2: ['Proof of Id (ID CARD, PASSPORT, DRIVER LICENCE)', 'Proof of Residence (Utility bill or Bank statement)', 'Credit Card Front', 'Credit Card Back', 'Proof of Id Back (ID CARD BACK, DRIVER LICENCE BACK)', 'Selfie','Other'],
            upload: 'Wyślij',
            isConfirmed: 'jest potwierdzony',
            uploadAnotherDocument: 'Wyślij inny dokument',
            isUnderReview: 'jest w trakcie przegladu',
            questionnaire: 'Kwestionariusz',
            sendAnswers: 'Wyslij odpowiedzi',
            progressBarOf: 'z',
            progressBarText: 'twoich dokumentów zostały wgrane i potwierdzone'
        },
        fullScreenAccounts: {
            manageAccounts: 'Przelewaj srodki pomiedzy swoimi kontami',
            transferFrom: 'Z konta',
            transferTo: 'Na konto',
            amount: 'Kwota',
            makeTransfer: 'Dokonaj transferu',
            yourAccounts: 'Twoje konta',
            account: 'Konto',
            currency: 'Waluta',
            balance: 'Saldo',
            credit: 'Kredyt',
            makeActive: 'Uczyn aktywnym',
            active: 'Aktywny'
        },
        fullScreenSupport: {
            chatOff: 'Chat will be available on working days',
            sendMessage: 'Napisz wiadomość...'
        },
        fullScreenSavings: {
            pct_0: 'Dynamiczna',
            pct_30: 'Terminowa miesiąc',
            pct_90: 'Terminowa kwartał',
            pct_180: 'Terminowa 6 miesięcy',
            pct_365: 'Terminowa rok',
            pct_730: 'Terminowa 2 rok',
            savings: 'Oszczednosci',
            detailedInformation: 'Szczególowe informacje',
            pleaseSelect: 'Prosze wybrac walute i okres',
            openSavings: 'Oszczednosci otwarte',
            currentTime: 'Aktualny czas',
            releaseTime: 'Czas wydania',
            currencyChosen: 'Wybrana waluta',
            periodChosen: 'Wybrany okres',
            yourRate: 'Twoja stawka',
            yourAnnualRate: 'Twój roczny wskaźnik',
            yourEarnings: 'Twoje zarobki',
            accountFrom: 'Z konta',
            enterAmount: 'Wprowadz kwote',
            in: 'W',
            openAndInvest: 'Otwórz konto oszczednosciowe i inwestuj',
            investment: 'Lokata',
            period: 'Okres',
            daysTotal: 'Dni',
            finalEarnings: 'Potencjialny zysk',
            created: 'Utworzony',
            daysElapsed: 'Dni, które uplynely',
            days: 'dni',
            earnings: 'Zarobki',
            fundsReleasedIn: 'Odbłokowane za',
            claim: 'Roszczenie',
        },
        fullScreenSettings: {
            updatePassword: 'AKTUALIZACJA HASLA',
            currentPassword: 'Aktualne haslo',
            newPassword: 'Nowe hasło',
            changePassword: 'Zmiana hasła',
            activationForGoogle: 'Kod aktywacyjny dla Google Authenticator',
            activateAndEnter: 'Aktywuj kod uwierzytelniający i wprowadź wygenerowany kod w polu poniżej',
            qrCode: 'Kod QR',
            activateProtection: 'Aktywuj ochrone 2FA',
            protectionActive: 'Ochrona 2FA jest aktywna',
            twoFactorCode: 'Kod 2FA',
            disableProtection: 'Wylacz ochrone 2FA',
            language: 'Jezyk',
            dashboardCurrency: 'Dashboard Currency',
            confirmNewPassword: 'Potwierdź nowe hasło',
        },
        fullScreenReferrals: {
            yourLink: 'TWÓJ LINK POLECAJACY',
            referredFriends: 'POLECENI ZNAJOMI',
            id: 'ID',
            name: 'Imie',
            registered: 'Zarejestrowany',
            level: 'Poziom',
            payout: 'Wypłatę',
            lastPayouts: 'OSTATNIE WYPLATY ZA POLECENIE',
            time: 'Czas',
            amount: 'Kwota',
            currency: 'Walut',
            info: 'Informacje'
        },
        orderTypes: ['Kupic', 'Sprzedac', 'Wykupic limit', 'Limit sprzedazy', 'Zakup Stop', 'Sprzedac Stop'],
        statuses: ['W trakcie realizacji', 'Zatwierdzony', 'Odrzucony', 'Obróbka','W trakcie przeglądu'],
        errors: {
            INVALID_FIELDS: 'Please check that the data you have entered is correct',
            NOT_FOUND: 'Blad zadania',
            FORBIDDEN: 'Odmowa dostepu. Prosimy o ponowne zalogowanie.',
            SERVER_ERROR: 'Akcja nie powiodla sie. Prosze spróbowac ponownie pózniej',
            INVALID_ACCOUNT: 'Akcja nie powiodla sie. Prosze spróbowac ponownie pózniej',
            INVALID_EMAIL: 'Nieprawidlowy adres e-mail',
            INVALID_PHONE: 'Nieprawidlowy numer telefonu',
            INVALID_COUNTRY: 'Nieprawidlowy kraj',
            INVALID_LOGIN: 'Nieprawidlowe poswiadczenia logowania',
            USER_BLOCKED: 'Konto jest zablokowane. Prosimy o kontakt z obsluga',
            USER_REGISTERED: 'Uzytkownik o podanym adresie e-mail lub numerze telefonu jest juz zarejestrowany',
            INVALID_USER: 'Uzytkownik nie zostal znaleziony',
            REJECTED: 'Dzialanie nie jest dozwolone z powodu ograniczen platformy',
            INVALID_OLD_PASSWORD: 'Stare haslo jest niewazne',
            INVALID_SYMBOL: 'Nieprawidlowy symbol handlowy',
            INVALID_SYMBOL_PRECISION: 'Nieprawidlowa precyzja symbolu',
            INVALID_FRAME: 'Nieprawidlowa ramka wykresu',
            INVALID_CURRENCY: 'Nieprawidlowa waluta',
            INVALID_PRODUCT: 'Nieprawidlowy typ handlowy konta',
            INSUFFICIENT_FUNDS: 'Niewystarczajace srodki finansowe',
            INVALID_WITHDRAWAL: 'Wycofanie nie zostalo odnalezione',
            INVALID_STATUS: 'Nieprawidlowy status',
            INVALID_FILE: 'Nieprawidlowe rozszerzenie pliku lub plik jest zbyt duzy',
            INVALID_DOCUMENT: 'Dokument nie zostal znaleziony',
            INVALID_NAME: 'Nieprawidlowa nazwa',
            INVALID_LOT_SIZE: 'Nieprawidlowa wielkosc dzialki',
            INVALID_LOT_STEP: 'Nieprawidlowy krok partii',
            INVALID_MIN_VOLUME: 'Nieprawidlowa objetosc minimalna',
            INVALID_MAX_VOLUME: 'Nieprawidlowa objetosc maksymalna',
            INVALID_GROUP: 'Nieprawidlowa grupa',
            INVALID_SPREAD: 'Niewazny rozrzut',
            REQUEST_TIMEOUT: 'Zadanie zostalo przerwane. Prosze spróbowac ponownie pózniej',
            INVALID_TYPE: 'Nieprawidlowy typ zamówienia',
            INVALID_VOLUME: 'Nieprawidlowa wielkosc zamówienia',
            INVALID_SL: 'Nieprawidlowa wartosc stop loss',
            INVALID_TP: 'Nieprawidlowa wartosc take profit',
            INVALID_PRICE: 'Nieprawidlowa cena zamówienia',
            INVALID_EXPIRATION: 'Nieprawidlowy termin waznosci zamówienia',
            NOT_ENOUGH_MARGIN: 'Niewystarczajaca marza',
            INVALID_ORDER: 'Zamówienie nie zostalo znalezione',
            MARKET_CLOSED: 'Handel nie jest dostepny. Prosze spróbowac ponownie',
            INVALID_BALANCE_TYPE: 'Nieprawidlowy typ operacji bilansowej',
            INVALID_HASH: 'Nieprawidlowy hash',
            HASH_EXPIRED: 'Twój link do resetowania hasla wygasl. Popros o nowe haslo.',
            INVALID_CODE: 'Nieprawidlowy kod 2FA',
            CHAT_DISABLED: 'Dostep do czatu pomocy technicznej zostal zamkniety',
            WITHDRAWAL_NOT_ALLOWED: 'Dostep do wyplat zostal zamkniety',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            CLOSE_OPEN_ORDERS: 'Please close your open orders',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            REGISTRATIONS_BLOCKED: "Dziękujemy za zapytanie, Jeden z naszych przedstawicieli skontaktuje się z Państwem najszybciej jak to możliwe",
        },
        countries: {
            AF: 'Afganistan',
            AX: 'Wyspy Alandzkie',
            AL: 'Albania',
            DZ: 'Algieria',
            AS: 'Samoa Amerykanskie',
            AD: 'Andora',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktyda',
            AG: 'Antigua i Barbuda',
            AR: 'Argentyna',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbejdzan',
            BS: 'Bahamy',
            BH: 'Bahrajn',
            BD: 'Bangladesz',
            BB: 'Barbados',
            BY: 'Bialorus',
            BE: 'Belgia',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermudy',
            BT: 'Bhutan',
            BO: 'Boliwia',
            BA: 'Bosnia i Hercegowina',
            BW: 'Botswana',
            BV: 'Wyspa Bouveta',
            BR: 'Brazylia',
            IO: 'Brytyjskie Terytorium Oceanu Indyjskiego',
            BN: 'Panstwo Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodza',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Republika Zielonego Przyladka',
            KY: 'Kajmany',
            CF: 'Republika Srodkowoafrykanska',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'Chiny',
            CX: 'Wyspa Bozego Narodzenia',
            CC: 'Wyspy Kokosowe (Keelinga)',
            CO: 'Kolumbia',
            KM: 'Komory',
            CG: 'Kongo',
            CD: 'Kongo, Republika Demokratyczna',
            CK: 'Wyspy Cooka',
            CR: 'Kostaryka',
            CI: 'Wybrzeze Kosci Sloniowej',
            HR: 'Chorwacja',
            CU: 'Kuba',
            CY: 'Cypr',
            CZ: 'Republika Czeska',
            DK: 'Dania',
            DJ: 'Dzibuti',
            DM: 'Dominika',
            DO: 'Republika Dominikanska',
            EC: 'Ekwador',
            EG: 'Egipt',
            SV: 'Salwador',
            GQ: 'Gwinea Równikowa',
            ER: 'Erytrea',
            EE: 'Estonia',
            ET: 'Etiopia',
            FK: 'Wyspy Falklandzkie (Malwiny)',
            FO: 'Wyspy Owcze',
            FJ: 'Fidzi',
            FI: 'Finlandia',
            FR: 'Francja',
            GF: 'Gujana Francuska',
            PF: 'Polinezja Francuska',
            TF: 'Francuskie Terytoria Poludniowe',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Niemcy',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Grecja',
            GL: 'Grenlandia',
            GD: 'Grenada',
            GP: 'Gwadelupa',
            GU: 'Guam',
            GT: 'Gwatemala',
            GG: 'Guernsey',
            GN: 'Gwinea',
            GW: 'Gwinea Bissau',
            GY: 'Gujana',
            HT: 'Haiti',
            HM: 'Wyspa Heard i Wyspy Mcdonalda',
            VA: 'Stolica Apostolska (Panstwo Watykanskie)',
            HN: 'Honduras',
            HK: 'Hongkong',
            HU: 'Wegry',
            IS: 'Islandia',
            IN: 'Indie',
            ID: 'Indonezja',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Irlandia',
            IM: 'Wyspa Man',
            IL: 'Izrael',
            IT: 'Wlochy',
            JM: 'Jamajka',
            JP: 'Japonia',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazachstan',
            KE: 'Kenia',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwejt',
            KG: 'Kirgistan',
            LA: 'Laotanska Republika Ludowo-Demokratyczna',
            LV: 'Lotwa',
            LB: 'Liban',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libijska Arabska Dzamahirija',
            LI: 'Liechtenstein',
            LT: 'Litwa',
            LU: 'Luksemburg',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagaskar',
            MW: 'Malawi',
            MY: 'Malezja',
            MV: 'Malediwy',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Wyspy Marshalla',
            MQ: 'Martynika',
            MR: 'Mauretania',
            MU: 'Mauritius',
            YT: 'Majotta',
            MX: 'Meksyk',
            FM: 'Mikronezja',
            MD: 'Moldawia',
            MC: 'Monako',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Maroko',
            MZ: 'Mozambik',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Niderlandy',
            AN: 'Antyle Holenderskie',
            NC: 'Nowa Kaledonia',
            NZ: 'Nowa Zelandia',
            NI: 'Nikaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Wyspa Norfolk',
            MP: 'Mariany Pólnocne',
            NO: 'Norwegia',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Terytorium Palestynskie, Okupowane',
            PA: 'Panama',
            PG: 'Papua Nowa Gwinea',
            PY: 'Paragwaj',
            PE: 'Peru',
            PH: 'Filipiny',
            PN: 'Pitcairn',
            PL: 'Polska',
            PT: 'Portugalia',
            PR: 'Puerto Rico',
            QA: 'Katar',
            RE: 'Zjazd',
            RO: 'Rumunia',
            RU: 'Rosja',
            RW: 'Rwanda',
            BL: 'Swiety Bartymeusz',
            SH: 'Swieta Helena',
            KN: 'Saint Kitts i Nevis',
            LC: 'Saint Lucia (Lucja Pólnocna)',
            MF: 'Swiety Marcin',
            PM: 'Saint Pierre i Miquelon',
            VC: 'Saint Vincent i Grenadyny',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Wyspy Swietego Tomasza i Ksiazeca',
            SA: 'Arabia Saudyjska',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seszele',
            SL: 'Sierra Leone',
            SG: 'Singapur',
            SK: 'Slowacja',
            SI: 'Slowenia',
            SB: 'Wyspy Salomona',
            SO: 'Somalia',
            ZA: 'Republika Poludniowej Afryki',
            GS: 'Poludniowa Georgia i Sandwich Isl.',
            ES: 'Hiszpania',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard i Jan Mayen',
            SZ: 'Suazi',
            SE: 'Szwecja',
            CH: 'Szwajcaria',
            SY: 'Syryjska Republika Arabska',
            TW: 'Taiwan',
            TJ: 'Tadzykistan',
            TZ: 'Tanzania',
            TH: 'Tajlandia',
            TL: 'Timor Wschodni',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trynidad i Tobago',
            TN: 'Tunezja',
            TR: 'Turcja',
            TM: 'Turkmenistan',
            TC: 'Wyspy Turks i Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraina',
            AE: 'Zjednoczone Emiraty Arabskie',
            GB: 'Zjednoczone Królestwo',
            US: 'Stany Zjednoczone',
            UM: 'Wyspy Zewnetrzne Stanów Zjednoczonych',
            UY: 'Urugwaj',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Wenezuela',
            VN: 'Wietnam',
            VG: 'Brytyjskie Wyspy Dziewicze',
            VI: 'Wyspy Dziewicze, U.S.',
            WF: 'Wallis i Futuna',
            EH: 'Sahara Zachodnia',
            YE: 'Jemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    cz: {
        _name: 'Čeština',
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            takeProfit: 'Přijmout zisk',
            stopLoss: 'Zastavení ztráty',
            updateOrder: 'Aktualizace objednávky',
        },
        balanceModal: {
            balance: 'Rozvaha',
            credit: 'Kredit',
            equity: 'Kapitál',
            practice: 'PRAKTIKA',
            real: 'REÁLNÉ',
            account: 'ÚČET',
            invested: 'Investováno',
            profit: 'Zisk',
            loss: 'Ztráty',
            margin: 'Marže',
            marginLevel: 'Úroveň marže',
            marginFree: 'Volná marže'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platforma',
            deposit: 'Vklad'
        },
        sideMenu: {
            marketWatch: 'Sledování trhu',
            activeOrders: 'Aktivní objednávky',
            tradingHistory: 'Historie obchodování',
            economicCalendar: 'Ekonomický kalendář',
            marketNews: 'Zprávy z trhu'
        },
        closePositionModal: {
            closeOrder: 'Zavřít pozici',
            areYouSure: 'Určitě chcete pozici uzavřít',
            buy: 'Koupit',
            sell: 'Prodej',
            yes: 'Ano',
            no: 'Ne',
        },
        chart: {
            addNewChart: 'Přidat nový graf',
        },
        symbolsModal: {
            watchlist: 'Seznam sledování',
            asset: 'Majetek',
            price: 'Cena',
            changePct: 'Změna 24h'
        },
        pendingModal: {
            pending: 'Čekající',
            assetPrice: 'Cena aktiv',
            current: 'Aktuální',
            revert: 'Návrat k tržní ceně',
            automatically: 'Pozice se otevře automaticky, jakmile cena dosáhne této úrovně.'
        },
        orderMenu: {
            volume: 'Svazek',
            lots: 'šarže',
            units: 'jednotky',
            currency: 'měna',
            contractSize: 'Velikost smlouvy',
            position: 'Pozice',
            margin: 'Marže',
            freeMargin: 'Volná marže',
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            pending: 'Čekající',
            market: 'Trh',
            leverage: 'Pákový efekt',
            spread: 'Rozšíření',
            notSet: 'Není nastaveno',
            at: 'na',
            buy: 'Koupit',
            sell: 'Prodej',
        },
        footer: {
            supportBanner: 'KAŽDÝ DEN, NEPŘETRŽITĚ',
            currentTime: 'BĚŽNÝ ČAS',
            liveChat: 'Živý chat'
        },
        statusBar: {
            balance: 'Bilance',
            credit: 'Kredit',
            equity: 'Kapitál',
            margin: 'Marže',
            marginLevel: 'Úroveň marže',
            freeMargin: 'Volná marže',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Datum registrace',
            userId: 'ID uživatele',
            dashboard: 'Dashboard',
            personalData: 'Osobní údaje',
            deposit: 'Vklad',
            withdrawFunds: 'Výběr finančních prostředků',
            savings: 'Úspory',
            settings: 'Nastavení',
            logout: 'Odhlášení'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            BT_ACCOUNT: 'Account',
            BT_BANK_NAME: 'Bank Name',
            BT_BENEFICIARY: 'Beneficiary',
            BT_ADDRESS: 'Beneficiary Address',
            BT_SWIFT: 'Swift',
            BT_IBAN: 'IBAN',
            BT_REFERENCE_CODE : 'Bankovní referenční kód',
            BT_INN: 'INN',
            BT_KPP: 'Correspondent account',
            BT_CORRESPONDING: '',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Provést vklad',
            practiceAccount: 'Účet pro praxi',
            realAccount: 'Skutečný účet',
            noWithdrawals: 'Žádné výběry',
            easyWithdrawals: 'Snadné výběry',
            allAssets: 'Všechna dostupná aktiva',
            fullFledged: 'Plnohodnotná platforma',
            fillUpTo: 'Naplnit do',
            freeReplenishment: 'Bezplatné doplňování',
            topUp: 'Dobití účtu',
            minimumAmount: 'Minimální částka',
            canSwitch: 'Mezi účty můžete kdykoli přepínat'
        },
        gridsModal: {
            chartGrids: 'GRAFICKÉ MŘÍŽKY',
            chart1: '1 diagram',
            chart2: '2 diagram',
            chart3: '3 diagram',
            chart4: '4 diagram',
        },
        noConnectionModal: {
            connectionLost: 'Spojení se serverem je ztraceno',
            retryNow: 'Zkusit to znovu',
        },
        loginModal: {
            loginToTradeRoom: 'Přihlášení do Traderoom',
            email: 'E-mailová adresa',
            emailAndId: 'Emailová adresa / Číslo ID',
            enterEmail: 'Zadejte svůj e-mail',
            twoFactor: 'Kód 2FA (je-li povolen)',
            twoFactorAuth: 'Dvoufaktorové ověřování',
            password: 'Heslo',
            yourPassword: 'Vaše heslo',
            signIn: 'Přihlásit se',
            dontHaveAccount: 'Don’t have an account?',
            restore: 'Restore',
            fullName: 'Full Name',
            havePromo: 'I have a promo-code',
            promo: 'Promo code',
            login: 'Login',
            haveAccount: 'Already have an account?',
            hide: 'Hide',
            goTo: 'Go to back'
        },
        newPasswordModal: {
            setNewPassword: 'Nastavení nového hesla',
            newPassword: 'Nové heslo',
            confirmNewPassword: 'Potvrzení nového hesla',
            setButton: 'Sada'
        },
        registerModal: {
            createNewAccount: 'Vytvořit nový účet',
            email: 'E-mailová adresa',
            firstName: 'Křestní jméno',
            lastName: 'Příjmení',
            country: 'Stát',
            phone: 'Telefon',
            password: 'Heslo',
            createAccount: 'Vytvořit účet',
            currency: 'Měna'
        },
        forgotPasswordModal: {
            forgotPassword: 'Zapomenuté heslo?',
            submitEmail: 'Odešlete e-mail použitý k registraci, zkontrolujte si doručenou poštu a postupujte podle uvedených pokynů.',
            submit: 'Odeslat'
        },
        notifications: {
            error: 'Chyba',
            success: 'Úspěch',
            deposit: 'Vklad',
            withdrawal: 'Stažení',
            depositApproved: 'Vklad byl schválen',
            depositDeclined: 'Vklad byl odmítnut',
            withdrawalApproved: 'Stažení bylo schváleno',
            withdrawalDeclined: 'Stažení bylo zamítnuto',
            withdrawalCancelled: 'Výběr byl zrušen'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Stocks',
            commodities: 'Komodity',
            indices: 'Indexy',
            crypto: 'Crypto',
            metals: 'Kovy',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'BUY',
            profitCalculator: 'Profit Calculator',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Sledování trhu',
            search: 'Vyhledávání...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Svazek',
            margin: 'Marže',
            active: 'Aktivní',
            pending: 'Čekající',
            activeOrders: 'Aktivní objednávky',
            portfolio: 'Portfolio',
            allPositions: 'Všechny pozice',
            noPositionsLine1: 'Nemáte žádné otevřené',
            noPositionsLine2: 'pozice zatím',
            show: 'Zobrazit',
            more: 'více',
            less: 'méně',
            purchaseTime: 'Čas nákupu',
            closePosition: 'Zavřít pozici',
            priceOpen: 'Nákupní cena',
            priceSl: 'Cena Stop Loss',
            priceTp: 'Cena Take Profit',
            type: 'Pozice Směr',
            pnl: 'Zisk/ztráta',
            cancelOrder: 'Zrušit objednávku',
            orderType: 'Typ objednávky',
            cancelled: 'Zrušeno',
            tradingHistory: 'Historie obchodování',
            noHistoryLine1: 'Nemáte žádné',
            noHistoryLine2: 'dosud uzavřené obchody',
            sortByTime: 'Řadit podle času',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Ekonomický kalendář'
        },
        widgetMarketNews: {
            marketNews: 'Zprávy z trhu'
        },
        ordersPane: {
            ordersActive: 'Objednávky Aktivní',
            ordersHistory: 'Historie objednávek',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Typ',
            volume: 'Svazek',
            openPrice: 'Otevřená cena',
            openTime: 'Otevřený čas',
            closePrice: 'Zavřít Cena',
            closeTime: 'Tiempo de cierre',
            sl: 'SL',
            tp: 'TP',
            price: 'Cena',
            pnl: 'PnL',
            actions: 'Akce',
            edit: 'Upravit',
            close: 'Zavřít',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Information',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        fullScreen: {
            startTrading: 'Start Trading',
            deposit: 'Provést vklad',
            dashboard: 'Dashboard',
            personalInfo: 'Aktualizace osobních údajů',
            withdrawal: 'Žádost o stažení',
            verification: 'Ověřování totožnosti',
            accounts: 'Správa účtů',
            liveChat: 'Živý chat',
            savings: 'Spoření',
            settings: 'Nastavení platformy',
            logOut: 'Odhlášení',
        },
        fullScreenDeposit: {
            name: 'Cardholder\'s name',
            address: 'Address',
            DOB: 'DOB',
            bankName: 'Bank name',
            additionalInfo: 'Chcete-li na svůj účet vložit více peněz, kontaktujte přímo svého účetního manažera',
            additionalInfoOnlyBTCpsp: 'Pro otevření jiných platebních metod, prosím, poskytněte KYC nebo kontaktujte svého správce účtu',
            sofortPspMessage: 'Náš finanční tým vás bude kontaktovat, aby vám pomohl provést vklad',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'UPLATNIT ZÁLOHU',
            instant: 'Instantní',
            minutes: 'minuty',
            hours: 'hodin',
            days: 'dny',
            amount: 'Částka',
            continue: 'Pokračovat',
            qrCode: 'QR KÓD',
            depositAddress: 'ADRESA VKLADU',
            copy: 'Kopírovat',
            last: 'POSLEDNÍ',
            deposits: 'DEPOSITY',
            time: 'Čas',
            currency: 'Měna',
            status: 'Status',
            info: 'Informace',
        },
        fullScreenCcExt: {
            pleaseWait: 'Počkejte prosím. Připojení k poskytovateli platebních služeb...',
            cardPayment: 'Platba kartou',
            inOrder: 'Za účelem odeslání částky',
            pleaseProvide: 'uveďte prosím údaje o kartě:'
        },
        fullScreenDashboard: {
            totalTaxes: 'Total Taxes',
            totalBalance: 'Finální Balance',
            totalPnl: 'Totální Pnl',
            profitableOrders: 'Výdělečné Objednávky',
            roi: 'ROI',
            activityLog: 'Aktivační Log',
            loginFromIp: 'Login from IP',
            tradingResults: 'Tržní Výsledky ',
            week: 'Týden',
            month: 'Měsíc',
            year: 'Rok',
            successRate: 'Úspěšná Hodnota',
            closedWithProfit: 'Zavřeno s Výdělkem',
            closedWithLoss: 'Zavřeno s Prodělkem',
            account: 'Profil',
            balance: 'Balance',
            leverage: 'Obchodní Rameno',
            credit: 'Úvěr',
            tradeNow: 'Obchod Nyní',
            usingCurrentRate: 'using current exchange rate',
            totalDeposits: 'Total Deposits'
        },
        fullScreenPersonal: {
            personalDetails: 'Osobní údaje',
            profilePhoto: 'Profilová fotografie',
            firstName: 'Jméno',
            country: 'Stát',
            lastName: 'Příjmení',
            address: 'Adresa',
            email: 'E-mail',
            phone: 'Telefonní číslo',
            saveChanges: 'Uložit změny'
        },
        fullScreenAvatar: {
            dropFile: 'Nahrajte soubor do výše uvedeného kroužku.',
            notAllowed: 'Není povoleno zveřejňovat',
            notAllowedLine1: 'Fotografie vysloveně sexuální nebo pornografické povahy',
            notAllowedLine2: 'Snímky podněcující etnickou nebo rasovou nenávist nebo agresi.',
            notAllowedLine3: 'Fotografie osob mladších 18 let',
            notAllowedLine4: 'Fotografie třetích stran chráněné autorskými právy',
            notAllowedLine5: 'Obrázky větší než 5 MB a v jiném formátu než JPG, GIF nebo PNG.',
            requirements: 'Na fotografii musí být jasně vidět váš obličej. Všechny vámi nahrané fotografie a videa musí splňovat tyto požadavky, jinak mohou být odstraněny.'
        },
        fullScreenWithdrawal: {
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'POŽÁDAT O NOVÝ VÝBĚR',
            amount: 'Částka',
            currentBalance: 'Zůstatek na vašem běžném účtu je',
            withdrawAll: 'Stáhněte všechny',
            requestWithdrawal: 'Žádost o stažení',
            last: 'POSLEDNÍ',
            withdrawalRequests: 'ŽÁDOSTI O STAŽENÍ',
            time: 'Čas',
            currency: 'Měna',
            info: 'Informace',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            card: 'Karta',
            ethereum: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Podrobnosti o stažení',
            address: 'Adresa peněženky',
            bankAccount: 'Číslo účtu',
            bankHolder: 'Jméno držitele účtu',
            bankIban: 'IBAN',
            bankSwift: 'Bankovní SWIFT kód',
            sortCode: 'Kód řazení',
            ifscCode: 'IFSC kód',
            bankName: 'Název Banky',
            bankBranch: 'Pobočka Banky',
            cardNumber: 'Číslo karty',
            cardHolder: 'Jméno držitele karty',
            incorrectAmountByFreeMargin: 'The amount is incorrect you can withdraw the amount of free margin',
            closeOrders: 'before close all orders'
        },
        fullScreenVerification: {
            dragAndDrop: 'přetáhněte dokument do této oblasti',
            documentsList: 'SEZNAM NAHRANÝCH DOKUMENTŮ',
            document: 'Dokument',
            timeUploaded: 'Čas nahrání',
            timeProcessed: 'Zpracovaný čas',
            status: 'Status',
            types: ['Doklad totožnosti', 'Doklad o bydlišti', 'Přední strana kreditní karty', 'Kreditní karta zpět', 'Doklad o totožnosti zpět', 'Selfie','Other'],
            types2: ['Proof of Id (ID CARD, PASSPORT, DRIVER LICENCE)', 'Proof of Residence (Utility bill or Bank statement)', 'Credit Card Front', 'Credit Card Back', 'Proof of Id Back (ID CARD BACK, DRIVER LICENCE BACK)', 'Selfie','Other'],
            upload: 'Nahrát',
            isConfirmed: 'je potvrzeno',
            uploadAnotherDocument: 'Nahrát další dokument',
            isUnderReview: 'je předmětem přezkumu',
            questionnaire: 'Dotazník',
            sendAnswers: 'Odeslat odpovědi',
            progressBarOf: 'of',
            progressBarText: 'of your documents have been uploaded and confirmed'
        },
        fullScreenAccounts: {
            manageAccounts: 'Převádění prostředků mezi účty',
            transferFrom: 'Z účtu',
            transferTo: 'Na účet',
            amount: 'Částka',
            makeTransfer: 'Proveďte přenos',
            yourAccounts: 'Vaše účty',
            account: 'Účet',
            currency: 'Měna',
            balance: 'Bilance',
            credit: 'Kredit',
            makeActive: 'Udělat aktivní',
            active: 'Aktivní'
        },
        fullScreenSupport: {
            chatOff: 'Chat will be available on working days',
            sendMessage: 'Odeslat zprávu'
        },
        fullScreenSavings: {
            pct_0: 'Flexibilní',
            pct_30: 'Uzamčeno na 1 měsíc',
            pct_90: 'Uzamčeno 1 čtvrtletí',
            pct_180: 'Uzamčeno na 6 měsíců',
            pct_365: 'Uzamčeno na 1 rok',
            pct_730: 'Uzamčeno na 2 rok',
            savings: 'Úspory',
            detailedInformation: 'Podrobné informace',
            pleaseSelect: 'Vyberte měnu a období',
            openSavings: 'Otevřené spoření',
            currentTime: 'Aktuální čas',
            releaseTime: 'Čas vydání',
            currencyChosen: 'Vybraná měna',
            periodChosen: 'Vybrané období',
            yourRate: 'Vaše sazba',
            yourAnnualRate: 'Váš roční sazba',
            yourEarnings: 'Vaše příjmy',
            accountFrom: 'Účet, ze kterého se mají převádět prostředky',
            enterAmount: 'Zadejte částku',
            in: 'V',
            openAndInvest: 'Otevřete si spořicí účet a investujte',
            investment: 'Investice',
            period: 'Období',
            daysTotal: 'Dny celkem',
            finalEarnings: 'Konečné příjmy',
            created: 'Vytvořeno',
            daysElapsed: 'Uplynulé dny',
            days: 'dny',
            earnings: 'Výdělky',
            fundsReleasedIn: 'Uvolněné prostředky v',
            claim: 'Reklamace',
        },
        fullScreenSettings: {
            updatePassword: 'AKTUALIZOVAT HESLO',
            currentPassword: 'Aktuální heslo',
            newPassword: 'Nové heslo',
            changePassword: 'Změna hesla',
            activationForGoogle: 'Aktivační kód pro Google Authenticator',
            activateAndEnter: 'Aktivujte Authenticator a zadejte vygenerovaný kód do pole níže.',
            qrCode: 'QR kód',
            activateProtection: 'Aktivace ochrany 2FA',
            protectionActive: 'Ochrana 2FA je aktivní',
            twoFactorCode: '2FA Kód',
            disableProtection: 'Vypnutí ochrany 2FA',
            language: 'Jazyk',
            dashboardCurrency: 'Dashboard Currency',
            confirmNewPassword: 'Potvrzení nového hesla',
        },
        fullScreenReferrals: {
            yourLink: 'VÁŠ DOPORUČUJÍCÍ ODKAZ',
            referredFriends: 'DOPORUČENÉ PŘÁTELE',
            id: 'ID',
            name: 'Jméno',
            registered: 'Registrováný',
            level: 'Úroveň',
            payout: 'Výplata',
            lastPayouts: 'POSLEDNÍ VÝPLATY ZA DOPORUČENÍ',
            time: 'Čas',
            amount: 'Částka',
            currency: 'Měna',
            info: 'Informace'
        },
        months: {
            Jan: 'Leden',
            Feb: 'Únor',
            Mar: 'Březen',
            Apr: 'Duben',
            May: 'Květen',
            Jun: 'Červen',
            Jul: 'Červenec',
            Aug: 'Srpen',
            Sep: 'Září',
            Oct: 'Říjen',
            Nov: 'Listopad',
            Dec: 'Prosinec'
        },
        orderTypes: ['Koupit', 'Prodej', 'Nákupní limit', 'Prodejní limit', 'Koupit Stop', 'Prodejní zastávka'],
        statuses: ['Čekající', 'Schváleno', 'Odmítnuto', 'Zpracování','V přezkumu'],
        errors: {
            INVALID_FIELDS: 'Please check that the data you have entered is correct',
            NOT_FOUND: 'Chyba požadavku',
            FORBIDDEN: 'Přístup odepřen. Přihlaste se znovu',
            SERVER_ERROR: 'Akce se nezdařila. Zkuste to prosím později',
            INVALID_ACCOUNT: 'Zasedání skončilo. Znovu se přihlaste',
            INVALID_EMAIL: 'Neplatná e-mailová adresa',
            INVALID_PHONE: 'Neplatné telefonní číslo',
            INVALID_COUNTRY: 'Neplatný stát',
            INVALID_LOGIN: 'Neplatné přihlašovací údaje',
            USER_BLOCKED: 'Účet je zablokován. Kontaktujte prosím podpor',
            USER_REGISTERED: 'Uživatel se zadaným e-mailem nebo telefonním číslem je již registrován',
            INVALID_USER: 'Uživatel nebyl nalezen',
            REJECTED: 'Provoz není povolen z důvodu omezení platformy',
            INVALID_OLD_PASSWORD: 'Staré heslo je neplatné',
            INVALID_SYMBOL: 'Neplatný obchodní symbol',
            INVALID_SYMBOL_PRECISION: 'Nesprávná přesnost symbolu',
            INVALID_FRAME: 'Neplatný rámec grafu',
            INVALID_CURRENCY: 'Neplatná měna',
            INVALID_PRODUCT: 'Neplatný typ obchodního účtu',
            INSUFFICIENT_FUNDS: 'Nedostatek finančních prostředků',
            INVALID_WITHDRAWAL: 'Stažení není nalezeno',
            INVALID_STATUS: 'Neplatný stav',
            INVALID_FILE: 'Nesprávná přípona souboru nebo příliš velký soubor',
            INVALID_DOCUMENT: 'Dokument nebyl nalezen',
            INVALID_NAME: 'Nesprávné jméno',
            INVALID_LOT_SIZE: 'Nesprávná velikost pozemku',
            INVALID_LOT_STEP: 'Neplatný krok šarže',
            INVALID_MIN_VOLUME: 'Neplatný minimální objem',
            INVALID_MAX_VOLUME: 'Neplatný maximální objem',
            INVALID_GROUP: 'Neplatná skupina',
            INVALID_SPREAD: 'Neplatné rozpětí',
            REQUEST_TIMEOUT: 'Požadavek vypršel. Zkuste to prosím později',
            INVALID_TYPE: 'Nesprávný typ objednávky',
            INVALID_VOLUME: 'Neplatný objem objednávky',
            INVALID_SL: 'Neplatná hodnota stop loss',
            INVALID_TP: 'Neplatná hodnota take profit',
            INVALID_PRICE: 'Neplatná cena objednávky',
            INVALID_EXPIRATION: 'Neplatné vypršení platnosti objednávky',
            NOT_ENOUGH_MARGIN: 'Nedostatečná marže',
            INVALID_ORDER: 'Objednávka nebyla nalezena',
            MARKET_CLOSED: 'Obchodování není k dispozici. Zkuste to prosím znovu',
            INVALID_BALANCE_TYPE: 'Nesprávný typ operace vyvážení',
            INVALID_HASH: 'Neplatný hash',
            HASH_EXPIRED: 'Platnost odkazu pro obnovení hesla vypršela. Vyžádejte si prosím nové',
            INVALID_CODE: 'Neplatný kód 2FA',
            CHAT_DISABLED: 'Přístup k chatu podpory byl uzavřen',
            WITHDRAWAL_NOT_ALLOWED: 'Přístup k výběrům byl uzavřen',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            CLOSE_OPEN_ORDERS: 'Please close your open orders',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            REGISTRATIONS_BLOCKED: "Děkujeme za váš dotaz, Někdo z našich zástupců vás bude kontaktovat co nejdříve",
        },
        countries: {
            AF: 'Afghánistán',
            AX: 'Alandské ostrovy',
            AL: 'Albánie',
            DZ: 'Alžírsko',
            AS: 'Americká Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktida',
            AG: 'Antigua a Barbuda',
            AR: 'Argentina',
            AM: 'Arménie',
            AW: 'Aruba',
            AU: 'Austrálie',
            AT: 'Austria',
            AZ: 'Ázerbájdžán',
            BS: 'Bahamy',
            BH: 'Bahrajn',
            BD: 'Bangladéš',
            BB: 'Barbados',
            BY: 'Bělorusko',
            BE: 'Belgie',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermudy',
            BT: 'Bhútán',
            BO: 'Bolívie',
            BA: 'Bosna a Hercegovina',
            BW: 'Botswana',
            BV: 'Bouvetův ostrov',
            BR: 'Brazílie',
            IO: 'Britské území v Indickém oceánu',
            BN: 'Brunej Darussalam',
            BG: 'Bulharsko',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodža',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Kapverdy',
            KY: 'Kajmanské ostrovy',
            CF: 'Středoafrická republika',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'Čína',
            CX: 'Vánoční ostrov',
            CC: 'Kokosové (Keelingovy) ostrovy',
            CO: 'Kolumbie',
            KM: 'Komory',
            CG: 'Kongo',
            CD: 'Kongo, Demokratická republika',
            CK: 'Cookovy ostrovy',
            CR: 'Kostarika',
            CI: 'Pobřeží slonoviny ',
            HR: 'Chorvatsko',
            CU: 'Kuba',
            CY: 'Kypr',
            CZ: 'Česká republika',
            DK: 'Dánsko',
            DJ: 'Džibutsko',
            DM: 'Dominika',
            DO: 'Dominikánská republika',
            EC: 'Ekvádor',
            EG: 'Egypt',
            SV: 'Salvador',
            GQ: 'Rovníková Guinea',
            ER: 'Eritrea',
            EE: 'Estonsko',
            ET: 'Etiopie',
            FK: 'Falklandy (Malvíny)',
            FO: 'Faerské ostrovy',
            FJ: 'Fidži',
            FI: 'Finsko',
            FR: 'Francie',
            GF: 'Francouzská Guyana',
            PF: 'Francouzská Polynésie',
            TF: 'Francouzská jižní území',
            GA: 'Gabon',
            GM: 'Gambie',
            GE: 'Georgia',
            DE: 'Německo',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Řecko',
            GL: 'Grónsko',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heardův ostrov a Mcdonaldovy ostrovy',
            VA: 'Svatý stolec (Vatikánský městský stát)',
            HN: 'Honduras',
            HK: 'Hongkong',
            HU: 'Maďarsko',
            IS: 'Island',
            IN: 'Indie',
            ID: 'Indonésie',
            IR: 'Írán',
            IQ: 'Irák',
            IE: 'Irsko',
            IM: 'Ostrov Man',
            IL: 'Izrael',
            IT: 'Itálie',
            JM: 'Jamajka',
            JP: 'Japonsko',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazachstán',
            KE: 'Keňa',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuvajt',
            KG: 'Kyrgyzstán',
            LA: 'Laoská lidově demokratická republika',
            LV: 'Lotyšsko',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Libérie',
            LY: 'Libyjská arabská džamáhíríja',
            LI: 'Lichtenštejnsko',
            LT: 'Litva',
            LU: 'Lucembursko',
            MO: 'Macao',
            MK: 'Makedonie',
            MG: 'Madagaskar',
            MW: 'Malawi',
            MY: 'Malajsie',
            MV: 'Maledivy',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshallovy ostrovy',
            MQ: 'Martinik',
            MR: 'Mauritánie',
            MU: 'Mauricius',
            YT: 'Mayotte',
            MX: 'Mexiko',
            FM: 'Mikronésie',
            MD: 'Moldavsko',
            MC: 'Monako',
            MN: 'Mongolsko',
            ME: 'Černá Hora',
            MS: 'Montserrat',
            MA: 'Maroko',
            MZ: 'Mosambik',
            MM: 'Myanmar',
            NA: 'Namibie',
            NR: 'Nauru',
            NP: 'Nepál',
            NL: 'Nizozemsko',
            AN: 'Nizozemské Antily',
            NC: 'Nová Kaledonie',
            NZ: 'Nový Zéland',
            NI: 'Nikaragua',
            NE: 'Niger',
            NG: 'Nigérie',
            NU: 'Niue',
            NF: 'Ostrov Norfolk',
            MP: 'Severní Mariany',
            NO: 'Norsko',
            OM: 'Omán',
            PK: 'Pákistán',
            PW: 'Palau',
            PS: 'Palestinské území, okupované území',
            PA: 'Panama',
            PG: 'Papua-Nová Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filipíny',
            PN: 'Pitcairn',
            PL: 'Polsko',
            PT: 'Portugalsko',
            PR: 'Portoriko',
            QA: 'Katar',
            RE: 'Sraz',
            RO: 'Rumunsko',
            RU: 'Rusko',
            RW: 'Rwanda',
            BL: 'Svatý Barthelemy',
            SH: 'Svatá Helena',
            KN: 'Svatý Kryštof a Nevis',
            LC: 'Svatá Lucie',
            MF: 'Svatý Martin',
            PM: 'Svatý Pierre a Miquelon',
            VC: 'Svatý Vincenc a Grenadiny',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Svatý Tomáš a Princův',
            SA: 'Saúdská Arábie',
            SN: 'Senegal',
            RS: 'Srbsko',
            SC: 'Seychely',
            SL: 'Sierra Leone',
            SG: 'Singapur',
            SK: 'Slovensko',
            SI: 'Slovinsko',
            SB: 'Šalamounovy ostrovy',
            SO: 'Somálsko',
            ZA: 'Jižní Afrika',
            GS: 'Jižní Georgie a Sandwichovy ostrovy',
            ES: 'Španělsko',
            LK: 'Srí Lanka',
            SD: 'Súdán',
            SR: 'Surinam',
            SJ: 'Špicberky a Jan Mayen',
            SZ: 'Svazijsko',
            SE: 'Švédsko',
            CH: 'Švýcarsko',
            SY: 'Syrská arabská republika',
            TW: 'Tchaj-wan',
            TJ: 'Tádžikistán',
            TZ: 'Tanzanie',
            TH: 'Thajsko',
            TL: 'Východní Timor',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad a Tobago',
            TN: 'Tunisko',
            TR: 'Krocan',
            TM: 'Turkmenistán',
            TC: 'Ostrovy Turks a Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukrajina',
            AE: 'Arabské emiráty',
            GB: 'Velká Británie',
            US: 'Spojené státy americké',
            UM: 'Odlehlé ostrovy Spojených států',
            UY: 'Uruguay',
            UZ: 'Uzbekistán',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Britské Panenské ostrovy',
            VI: 'Panenské ostrovy, USA',
            WF: 'Wallis a Futuna',
            EH: 'Západní Sahara',
            YE: 'Jemen',
            ZM: 'Zambie',
            ZW: 'Zimbabwe'
        }
    },
    de: {
        _name: 'Deutsch',
        autoCloseModal: {
            takeProfitStopLoss: 'Gewinnmitnahme & Stop-Loss',
            takeProfit: ' Gewinnmitnahme',
            stopLoss: 'Stop-Loss',
            updateOrder: 'Handel aktualisieren',
        },
        balanceModal: {
            balance: 'Kontostand',
            credit: ' Kredit',
            equity: 'Eigenkapital ',
            practice: 'Praxis',
            real: 'Real',
            account: 'Konto',
            invested: 'Investiert',
            profit: 'Profit',
            loss: 'Verlust',
            margin: 'Margin',
            marginLevel: 'Margin Level',
            marginFree: 'Freie Margin'
        },
        header: {
            cfd: 'CFD',
            platform: 'Plattform',
            deposit: 'Einzahlung '
        },
        sideMenu: {
            marketWatch: 'Marktübersicht',
            activeOrders: 'Aktive Handel',
            tradingHistory: 'Handelsgeschichte',
            economicCalendar: 'Wirtschaftskalender',
            marketNews: 'Marktnachrichten'
        },
        closePositionModal: {
            closeOrder: 'Handel schließen',
            areYouSure: 'Sind Sie sicher, den Order zu schließen?',
            buy: 'Kaufen',
            sell: 'Verkaufen',
            yes: 'Ja',
            no: 'Nein',
        },
        chart: {
            addNewChart: 'Neue Chart hinzufügen',
        },
        symbolsModal: {
            watchlist: 'Überwachungsliste',
            asset: 'Vermögenswert',
            price: 'Preise',
            changePct: '24h Wechsel'
        },
        pendingModal: {
            pending: 'Ausstehend',
            assetPrice: 'Vermögenswertpreis',
            current: 'aktuell',
            revert: 'Zurück zum Marktpreis',
            automatically: 'Handel wird automatisch eröffnet, wenn der Preis dieses Niveau erreicht'
        },
        orderMenu: {
            volume: 'Volumen',
            lots: 'lots',
            units: 'einheiten',
            currency: 'währung',
            contractSize: 'kontraktgröße',
            position: 'Position',
            margin: 'Margin',
            freeMargin: 'Freie Margin',
            takeProfitStopLoss: 'Gewinnmitnahme & Stop-Loss',
            pending: 'Ausstehend',
            market: 'Markt',
            leverage: 'Hebelwirkung',
            spread: 'Spread',
            notSet: 'Nicht eingestellt',
            at: 'um',
            buy: 'Kaufen',
            sell: 'Verkaufen',
        },
        footer: {
            supportBanner: 'JEDEN TAG, RUND UM DIE UHR',
            currentTime: 'AKTUELLE UHRZEIT',
            liveChat: 'Live Chat'
        },
        statusBar: {
            balance: 'Kontostand',
            credit: 'Kredit',
            equity: 'Eigenkapital',
            margin: 'Margin',
            marginLevel: 'Margin level',
            freeMargin: 'Freie Margin',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Registrierungsdatum',
            userId: 'Benutzer ID',
            dashboard: 'Dashboard',
            personalData: 'Persönliche Daten',
            deposit: 'Einzahlung',
            withdrawFunds: 'Auszahlung',
            savings: 'Ersparnisse',
            settings: 'Einstelungen',
            logout: 'Ausloggen'
        },
        depositModal: {
            BT_INVOICE: 'Betrag eingeben und Rechnung erstellen',
            BT_TITLE: 'Unsere Bankverbindung',
            PENDING_DEPOSITS_LIMIT: 'Einzahlungsgrenze',
            BT_ACCOUNT: 'Konto',
            BT_BANK_NAME: 'Name der Bank',
            BT_BENEFICIARY: 'Begünstigter',
            BT_ADDRESS: 'Adresse des Begünstigten',
            BT_SWIFT: 'Schnell',
            BT_IBAN: 'IBAN',
            BT_REFERENCE_CODE : 'Bankreferenzcode',
            BT_INN: 'INN',
            BT_KPP: 'Korrespondentenkonto',
            BT_CORRESPONDING: 'Korrespondentenkonto',
            TOKEN_AMOUNT: 'Betrag',
            TOKEN_SUBTOTAL: 'Zwischensumme',
            TOKEN_TOTAL: 'Insgesamt',
            TOKEN_DESCRIPTION: 'Beschreibung',
            TOKEN_PAYMENT_DETAILS: 'Details zur Bezahlung',
            makeDeposit: 'Einzahlung tätigen',
            practiceAccount: 'Demokonto',
            realAccount: 'Reales Konto',
            noWithdrawals: 'Keine Auszahlungen',
            easyWithdrawals: 'Einfache Auszahlungen ',
            allAssets: 'Alle Vermögenswerte verfügbar',
            fullFledged: 'Vollgeführte Plattform',
            fillUpTo: 'Auffüllen bis',
            freeReplenishment: 'Kostenloser Nachschub',
            topUp: 'Laden Sie Ihr Konto auf',
            minimumAmount: 'Mindestbetrag',
            canSwitch: 'Sie können jederzeit zwischen Ihren Konten wechseln'
        },
        gridsModal: {
            chartGrids: 'Chartaufstellung',
            chart1: '1 chart',
            chart2: '2 charts',
            chart3: '3 charts',
            chart4: '4 charts',
        },
        noConnectionModal: {
            connectionLost: 'Die Verbindung mit dem Server wurde unterbrochen',
            retryNow: 'Jetzt erneut versuchen',
        },
        loginModal: {
            loginToTradeRoom: 'Melden Sie sich bei Handelsroom an',
            email: 'E-Mail-Addresse',
            emailAndId: 'E-Mail-Adresse / ID-Nummer',
            enterEmail: 'Geben sie ihre E-Mail Adresse ein',
            twoFactor: '2FA-Code (falls aktiviert)',
            twoFactorAuth: 'Zwei-Faktor-Authentifizierung',
            password: 'Passwort',
            yourPassword: 'Ihr Passwort',
            signIn: 'Anmelden',
            dontHaveAccount: 'Don’t have an account?',
            restore: 'Restore',
            fullName: 'Full Name',
            havePromo: 'I have a promo-code',
            promo: 'Promo code',
            login: 'Login',
            haveAccount: 'Already have an account?',
            hide: 'Hide',
            goTo: 'Go to back'
        },
        newPasswordModal: {
            setNewPassword: 'Neues Passwort festlegen',
            newPassword: 'Neues Passwort',
            confirmNewPassword: 'Bestätige neues Passwort',
            setButton: 'Festsetzen'
        },
        registerModal: {
            createNewAccount: 'Ein neues Konto erstellen',
            email: 'E-Mail-Addresse',
            firstName: 'Vorname',
            lastName: 'Nachname',
            country: 'Land',
            phone: 'Telefon',
            password: 'Passwort',
            createAccount: 'Benutzerkonto erstellen',
            currency: 'Währung'
        },
        forgotPasswordModal: {
            forgotPassword: 'Passwort vergessen?',
            submitEmail: 'Bitte senden Sie eine für die Registrierung verwendete E-Mail-Adresse, überprüfen Sie Ihren Posteingang und befolgen Sie die bereitgestellten Anweisungen',
            submit: 'Bestätigen'
        },
        notifications: {
            error: 'Fehler',
            success: 'Erfolg',
            deposit: 'Einzahlung',
            withdrawal: 'Auszahlung',
            depositApproved: 'Einzahlung wurde genehmigt',
            depositDeclined: 'Einzahlung wurde abgelehnt',
            withdrawalApproved: 'Auszahlung wurde genehmigt',
            withdrawalDeclined: 'Auszahlung wurde abgelehnt',
            withdrawalCancelled: 'Die Auszahlung wurde storniert'
        },
        markets: {
            forex: 'Devisen',
            stocks: 'Aktien',
            commodities: 'Rohstoffe',
            indices: 'Indizes',
            crypto: 'Krypto',
            metals: 'Metalle',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'BUY',
            profitCalculator: 'Gewinnrechner',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Marktübersicht',
            search: 'Suche...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volumen',
            margin: 'Margin',
            active: 'Aktiv',
            pending: 'Ausstehend',
            activeOrders: 'Aktive Orders',
            portfolio: 'Portfolio',
            allPositions: 'Alle Positionen',
            noPositionsLine1: 'Sie haben noch keine',
            noPositionsLine2: 'geöffneten Positionen',
            show: 'Anzeigen',
            more: 'mehr',
            less: 'weniger',
            purchaseTime: 'Transaktionszeit',
            closePosition: 'Position schließen',
            priceOpen: 'Transaktionspreis',
            priceSl: 'Stop Loss Preis',
            priceTp: 'Take Profit Pries',
            type: 'Positionsrichtung',
            pnl: 'Profit/Verlust',
            cancelOrder: 'Order stornieren',
            orderType: 'Ordertyp',
            cancelled: 'Storniert',
            tradingHistory: 'Handelsgeschichte',
            noHistoryLine1: 'Sie haben noch keine',
            noHistoryLine2: 'geschlossenen Positionen',
            sortByTime: 'Nach Zeit sortieren',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Wirtschaftskalender'
        },
        widgetMarketNews: {
            marketNews: 'Marktnachrichten'
        },
        ordersPane: {
            ordersActive: 'Aktive Orders',
            ordersHistory: 'Handelsgeschichte',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Type',
            volume: 'Volumen',
            openPrice: 'Eröffnungspreis',
            openTime: 'Eröffnungszeit',
            closePrice: 'Schlusskurs',
            closeTime: 'Schließzeit',
            sl: 'SL',
            tp: 'TP',
            price: 'Preis',
            pnl: 'PnL',
            actions: 'Aktionen',
            edit: 'Bearbeiten',
            close: 'Schließen',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Information',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        fullScreen: {
            startTrading: 'Zum Handel',
            deposit: 'Einzahlung tätigen',
            dashboard: 'Dashboard',
            personalInfo: 'Persönliche Daten aktualisieren',
            withdrawal: 'Fordern Sie eine Auszahlung an',
            verification: 'Identitätsprüfung',
            accounts: 'Konten verwalten',
            liveChat: 'Live Chat',
            savings: 'Ersparnisse',
            settings: 'Plattformeinstellungen',
            logOut: 'Ausloggen',
        },
        fullScreenDeposit: {
            name: 'Cardholder\'s name',
            address: 'Address',
            DOB: 'DOB',
            bankName: 'Bank name',
            additionalInfo: 'Um mehr auf Ihr Konto einzuzahlen, wenden Sie sich bitte direkt an Ihren Kontomanager',
            additionalInfoOnlyBTCpsp: 'Um andere Zahlungsmethoden zu öffnen, bitte KYC bereitstellen oder sich an Ihren Kontomanager wenden',
            sofortPspMessage: 'Unser Finanzteam wird sich mit Ihnen in Verbindung setzen, um Ihnen bei der Einzahlung zu helfen',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'MACHEN SIE EINE EINZAHLUNG',
            instant: 'Sofort',
            minutes: 'Minuten',
            hours: 'Stunden',
            days: 'Tage',
            amount: 'Betrag',
            continue: 'Fortsetzen',
            qrCode: 'QR-CODE',
            depositAddress: 'EINZAHLUNGSADRESSE',
            copy: 'Kopieren',
            last: 'ZULETZT',
            deposits: 'EINZAHLUNGEN',
            time: 'Zeit',
            currency: 'Währung',
            status: 'Status',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Warten Sie mal. Verbindung zum Zahlungsdienstleister wird hergestellt...',
            cardPayment: 'Kartenzahlung',
            inOrder: 'Um den Betrag zu senden',
            pleaseProvide: 'Bitte geben Sie die Kartendaten an:'
        },
        fullScreenDashboard: {
            totalTaxes: 'Total Taxes',
            totalBalance: 'Kontostand',
            totalPnl: 'PnL Insgesamt',
            profitableOrders: 'Erfolgreiche Trades',
            roi: 'ROI',
            activityLog: 'Aktivitätsprotokoll',
            loginFromIp: 'Login from IP',
            tradingResults: 'Handelsergebnisse',
            week: 'Woche',
            month: 'Monat',
            year: 'Jahr',
            successRate: 'Erfolgsquote',
            closedWithProfit: 'Geschlossen mit Gewinn',
            closedWithLoss: 'Geschlossen mit Verlust',
            account: 'Konto',
            balance: 'Saldo',
            leverage: 'Hebelwirkung',
            credit: 'Kredit',
            tradeNow: 'Zum Handel',
            usingCurrentRate: 'zum aktuellen Wechselkurs',
            totalDeposits: 'Gesamteinlagen'
        },
        fullScreenPersonal: {
            personalDetails: 'Persönliche Daten',
            profilePhoto: 'Profilfoto',
            firstName: 'Vorname',
            country: 'Land',
            lastName: 'Nachname',
            address: 'Adresse',
            email: 'E-Mail',
            phone: 'Telefonnummer',
            saveChanges: 'Änderungen speichern'
        },
        fullScreenAvatar: {
            dropFile: 'Legen Sie eine Datei zum Hochladen auf dem Kreis oben ab',
            notAllowed: 'Es darf nicht veröffentlicht werden',
            notAllowedLine1: 'Fotos explizit sexueller oder pornografischer Natur',
            notAllowedLine2: 'Bilder, die darauf abzielen, zu ethnischem oder rassistischem Hass oder Aggression aufzustacheln',
            notAllowedLine3: 'Fotos mit Personen unter 18 Jahren',
            notAllowedLine4: 'Fotos mit Third-party Urheberrechte Schutz',
            notAllowedLine5: 'Bilder größer als 5 MB und in einem anderen Format als JPG, GIF oder PNG',
            requirements: 'Auf dem Foto muss Ihr Gesicht gut zu erkennen sein. Alle von Ihnen hochgeladenen Fotos und Videos müssen diese Anforderungen erfüllen, andernfalls können sie entfernt werden.'
        },
        fullScreenWithdrawal: {
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'NEUE AUSZAHLUNG ANFORDERN',
            amount: 'Betrag',
            currentBalance: 'Ihr aktueller Kontostand ist',
            withdrawAll: 'Alles auszahlen',
            requestWithdrawal: 'Auszahlung anfordern',
            last: 'ZULETZT',
            withdrawalRequests: 'AUSZAHLUNGSANTRÄGE',
            time: 'Zeit',
            currency: 'Währung',
            info: 'Info',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            card: 'Karte',
            ethereum: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Auszahlungsdetails',
            address: 'Wallet Address',
            bankAccount: 'Kontonummer',
            bankHolder: 'Name des Kontoinhabers',
            bankIban: 'IBAN',
            bankSwift: 'Bank SWIFT code',
            sortCode: 'Sortiercode',
            ifscCode: 'IFSC-Code',
            bankName: 'Bankname',
            bankBranch: 'Bankzweig',
            cardNumber: 'Kartennummer',
            cardHolder: 'Name des Karteninhabers',
            incorrectAmountByFreeMargin: 'The amount is incorrect you can withdraw the amount of free margin',
            closeOrders: 'before close all orders'
        },
        fullScreenVerification: {
            dragAndDrop: 'Ziehen Sie das Dokument per Drag & Drop in diesen Bereich',
            documentsList: 'LISTE DER HOCHGELADENEN DOKUMENTE',
            document: 'Dokument',
            timeUploaded: 'Zeit hochgeladen',
            timeProcessed: 'Zeit verarbeitet',
            status: 'Status',
            types: ['Ausweis', 'Nachweis des Wohnsitzes', 'Kreditkartenvorderseite', 'Kreditkartenrückseite', 'Ausweisrückseite', 'Selfie','Other'],
            types2: ['Proof of Id (ID CARD, PASSPORT, DRIVER LICENCE)', 'Proof of Residence (Utility bill or Bank statement)', 'Credit Card Front', 'Credit Card Back', 'Proof of Id Back (ID CARD BACK, DRIVER LICENCE BACK)', 'Selfie','Other'],
            upload: 'Hochladen',
            isConfirmed: 'bestätigt',
            uploadAnotherDocument: 'Anderes Dokument hochladen',
            isUnderReview: 'wird überprüft',
            questionnaire: 'Fragebogen',
            sendAnswers: 'Antworten senden',
            progressBarOf: 'von',
            progressBarText: 'Ihrer Dokumente sind hochgeladen und geprüft'
        },
        fullScreenAccounts: {
            manageAccounts: 'Überweisen Sie Gelder zwischen Ihren Konten',
            transferFrom: 'Vom Konto',
            transferTo: 'Auf Konto ',
            amount: 'Betrag',
            makeTransfer: 'Überweisung vornehmen',
            yourAccounts: 'Ihre Konten',
            account: 'Konto',
            currency: 'Währung',
            balance: 'Kontostand',
            credit: 'Kredit',
            makeActive: 'Aktivieren',
            active: 'Aktiv'
        },
        fullScreenSupport: {
            chatOff: 'Chat will be available on working days',
            sendMessage: 'Nachricht zu senden'
        },
        fullScreenSavings: {
            pct_0: 'Flexibel',
            pct_30: '1 Monat gesperrt',
            pct_90: '1 Quartal gesperrt',
            pct_180: '6 Monate gesperrt',
            pct_365: '1 Jahr gesperrt',
            pct_730: '2 Jahr gesperrt',
            savings: 'Ersparnisse',
            detailedInformation: 'Genaue Information',
            pleaseSelect: 'Bitte wählen Sie eine Währung und einen Zeitraum',
            openSavings: 'Ersparnisse öffnen',
            currentTime: 'Aktuelle Uhrzeit',
            releaseTime: 'Eröffnungsdatum',
            currencyChosen: 'Gewählte Währung',
            periodChosen: 'Gewählter Zeitraum',
            yourRate: 'Ihr Tarif',
            yourAnnualRate: 'Ihr jährlicher Satz',
            yourEarnings: 'Dein Verdienst',
            accountFrom: 'Konto, von dem Geld überwiesen werden soll',
            enterAmount: 'Menge Eingeben',
            in: 'In',
            openAndInvest: 'Sparkonto eröffnen und anlegen',
            investment: 'Investition',
            period: 'Zeitraum',
            daysTotal: 'Tage insgesamt',
            finalEarnings: 'Totales Einkommen',
            created: 'Erstellt',
            daysElapsed: 'Verstrichene Tage',
            days: 'Tage',
            earnings: 'Verdienste',
            fundsReleasedIn: 'Freigegebene Mittel in',
            claim: 'Versicherungsanspruch ',
        },
        fullScreenSettings: {
            updatePassword: 'Passwort AKTUALISIEREN',
            currentPassword: 'Aktuelles Passwort',
            newPassword: 'Neues Passwort',
            changePassword: 'Passwort ändern',
            activationForGoogle: 'Aktivierungscode für Google Authenticator',
            activateAndEnter: 'Aktivieren Sie Authenticator und geben Sie einen generierten Code in das Feld unten ein',
            qrCode: 'QR-Code',
            activateProtection: 'Aktivieren Sie den 2FA-Schutz',
            protectionActive: '2FA-Schutz ist aktiv',
            twoFactorCode: '2FA-Code',
            disableProtection: 'Deaktivieren Sie den 2FA-Schutz',
            language: 'Sprache',
            dashboardCurrency: 'Dashboard Währung',
            confirmNewPassword: 'Bestätige neues Passwort',
        },
        fullScreenReferrals: {
            yourLink: 'IHR EMPFEHLUNGSLINK',
            referredFriends: 'EMPFOHLENE FREUNDE',
            id: 'ID',
            name: 'Name',
            registered: 'Registriert',
            level: 'Niveua',
            payout: 'Gewinnauszahlung',
            lastPayouts: 'LETZTE EMPFOHLENE Gewinnauszahlung',
            time: 'Zeit',
            amount: 'Betrag',
            currency: 'Währung',
            info: 'Info'
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'May',
            Jun: 'Jun',
            Jul: 'Jul',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Oct',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Kaufen', 'Verkaufen', 'Kaufen Limit', 'Verkaufen Limit', 'Kaufen Stop', 'Verkaufen Stop'],
        statuses: ['Ausstehend', 'Genehmigt', 'Abgelehnt', 'Wird bearbeitet','In Überprüfung'],
        errors: {
            INVALID_FIELDS: 'Please check that the data you have entered is correct',
            NOT_FOUND: 'Anforderungsfehler',
            FORBIDDEN: 'Zugriff abgelehnt. Bitte neu einloggen',
            SERVER_ERROR: 'Aktion: fehlgeschlagen. Bitte versuchen Sie es später noch einmal.',
            INVALID_ACCOUNT: 'Sitzung abgelaufen. Bitte neu einloggen',
            INVALID_EMAIL: 'Ungültige E-Mail-Adresse',
            INVALID_PHONE: 'Ungültige Telefonnummer',
            INVALID_COUNTRY: 'Ungültiges Land',
            INVALID_LOGIN: 'Ungültige Login-Details',
            USER_BLOCKED: 'Konto ist gesperrt. Bitte wenden Sie sich an den Support.',
            USER_REGISTERED: 'Benutzer mit eingegebener E-Mail oder Telefonnummer ist bereits registriert',
            INVALID_USER: 'Benutzer wurde nicht gefunden',
            REJECTED: 'Der Betrieb ist aufgrund von Plattformbeschränkungen nicht erlaubt',
            INVALID_OLD_PASSWORD: 'Altes Passwort ist ungültig',
            INVALID_SYMBOL: 'Ungültiges Handelssymbol',
            INVALID_SYMBOL_PRECISION: 'Ungültige Symbolpräzision',
            INVALID_FRAME: 'Ungültiger Diagrammrahmen',
            INVALID_CURRENCY: 'Ungültige Währung',
            INVALID_PRODUCT: 'Ungültiger Typ des Handelskontos',
            INSUFFICIENT_FUNDS: 'Nicht genügend Geld auf dem Konto',
            INVALID_WITHDRAWAL: 'Auszahlung nicht gefunden',
            INVALID_STATUS: 'Ungültiger Status',
            INVALID_FILE: 'Ungültige Dateierweiterung oder Datei ist zu groß',
            INVALID_DOCUMENT: 'Dokument wurde nicht gefunden',
            INVALID_NAME: 'Ungültiger Name',
            INVALID_LOT_SIZE: 'Ungültige Lotgröße',
            INVALID_LOT_STEP: 'Ungültiger Lotschritt',
            INVALID_MIN_VOLUME: 'Ungültiges Mindestvolumen',
            INVALID_MAX_VOLUME: 'Ungültiges maximales Volumen',
            INVALID_GROUP: 'Ungültige Gruppe',
            INVALID_SPREAD: 'Ungültiger Spread',
            REQUEST_TIMEOUT: 'Zeitüberschreitung der Anforderung. Bitte versuchen Sie es später noch einmal',
            INVALID_TYPE: 'Ungültiger Auftragstyp',
            INVALID_VOLUME: 'Ungültiges Auftragsvolumen',
            INVALID_SL: 'Ungültiger Stop-Verlust-Wert',
            INVALID_TP: 'Ungültiger Gewinnmitnahme-Wert',
            INVALID_PRICE: 'Ungültiger Auftragspreis',
            INVALID_EXPIRATION: 'Ungültiger Auftragsablauf',
            NOT_ENOUGH_MARGIN: 'Nicht genug Margin',
            INVALID_ORDER: 'Auftrag nicht gefunden',
            MARKET_CLOSED: 'Handel ist nicht verfügbar. Bitte versuche es erneut',
            INVALID_BALANCE_TYPE: 'Ungültiger Typ der Kontostandoperation',
            INVALID_HASH: 'Ungültiger Hash',
            HASH_EXPIRED: 'Ihr Link zum Zurücksetzen des Passworts ist abgelaufen. Bitte fordern Sie eine neue an',
            INVALID_CODE: 'Ungültiger 2FA-Code',
            CHAT_DISABLED: 'Zugriff auf den Support-Chat wurde geschlossen',
            WITHDRAWAL_NOT_ALLOWED: 'Zugriff auf die Auszahlungen wurde gesperrt',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            CLOSE_OPEN_ORDERS: 'Please close your open orders',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later',
            REGISTRATIONS_BLOCKED: "Vielen Dank für Ihre Anfrage, Einer unserer Vertreter wird sich so schnell wie möglich mit Ihnen in Verbindung setzen",
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland-Inseln',
            AL: 'Albanien',
            DZ: 'Algerien',
            AS: 'Amerikanisch-Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktis',
            AG: 'Antigua und Barbuda',
            AR: 'Argentinien',
            AM: 'Armenien',
            AW: 'Aruba',
            AU: 'Australien',
            AT: 'Österreich',
            AZ: 'Aserbaidschan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesch',
            BB: 'Barbados',
            BY: 'Weißrussland',
            BE: 'Belgien',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivien',
            BA: 'Bosnien und Herzegowina',
            BW: 'Botswana',
            BV: 'Bouvetinsel',
            BR: 'Brasilien',
            IO: 'Britisches Territorium im Indischen Ozean',
            BN: 'Brunei Darussalam',
            BG: 'Bulgarien',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodscha',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Kap Verde',
            KY: 'Kaimaninseln',
            CF: 'Zentralafrikanische Republik',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Weihnachtsinsel',
            CC: 'Cocos (Keeling) Inseln',
            CO: 'Kolumbien',
            KM: 'Komoren',
            CG: 'Kongo',
            CD: 'Kongo, Demokratische Republik',
            CK: 'Cookinseln',
            CR: 'Costa Rica',
            CI: 'Cote D\'Ivoire',
            HR: 'Kroatien',
            CU: 'Kuba',
            CY: 'Zypern',
            CZ: 'Tschechische Republik',
            DK: 'Dänemark',
            DJ: 'Dschibuti',
            DM: 'Dominica',
            DO: 'Dominikanische Republik',
            EC: 'Ecuador',
            EG: 'Ägypten',
            SV: 'El Salvador',
            GQ: 'Äquatorialguinea',
            ER: 'Eritrea',
            EE: 'Estland',
            ET: 'Äthiopien',
            FK: 'Falklandinseln (Malwinen)',
            FO: 'Färöer Inseln',
            FJ: 'Fidschi',
            FI: 'Finnland',
            FR: 'Frankreich',
            GF: 'Französisch-Guayana',
            PF: 'Französisch-Polynesien',
            TF: 'Französische Süd-Territorien',
            GA: 'Gabun',
            GM: 'Gambia',
            GE: 'Georgien',
            DE: 'Deutschland',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Griechenland',
            GL: 'Grönland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard-Insel & Mcdonald-Inseln',
            VA: 'Heiliger Stuhl (Staat Vatikanstadt)',
            HN: 'Honduras',
            HK: 'Hongkong',
            HU: 'Ungarn',
            IS: 'Island',
            IN: 'Indien',
            ID: 'Indonesien',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Irland',
            IM: 'Isle Of Man',
            IL: 'Israel',
            IT: 'Italien',
            JM: 'Jamaika',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordanien',
            KZ: 'Kasachstan',
            KE: 'Kenia',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwait',
            KG: 'Kirgisistan',
            LA: 'Demokratische Volksrepublik Laos',
            LV: 'Lettland',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libysch-Arabische Dschamahirija',
            LI: 'Liechtenstein',
            LT: 'Litauen',
            LU: 'Luxemburg',
            MO: 'Macao',
            MK: 'Mazedonien',
            MG: 'Madagaskar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Malediven',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshallinseln',
            MQ: 'Martinique',
            MR: 'Mauretanien',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexiko',
            FM: 'Mikronesien',
            MD: 'Moldawien',
            MC: 'Monaco',
            MN: 'Mongolei',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Marokko',
            MZ: 'Mosambik',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Niederlande',
            AN: 'Niederländische Antillen',
            NC: 'Neukaledonien',
            NZ: 'Neuseeland',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolkinsel',
            MP: 'Nördliche Marianen',
            NO: 'Norwegen',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palästinensische Gebiete, Besetzt',
            PA: 'Panama',
            PG: 'Papua-Neuguinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippinen',
            PN: 'Pitcairn',
            PL: 'Polen',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Katar',
            RE: 'Wiedersehen',
            RO: 'Rumänien',
            RU: 'Russland',
            RW: 'Ruanda',
            BL: 'St. Barthelemy',
            SH: 'Sankt Helena',
            KN: 'St. Kitts und Nevis',
            LC: 'St. Lucia',
            MF: 'Sankt Martin',
            PM: 'St. Pierre und Miquelon',
            VC: 'St. Vincent und Grenadinen',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome und Principe',
            SA: 'Saudi-Arabien',
            SN: 'Senegal',
            RS: 'Serbien',
            SC: 'Seychellen',
            SL: 'Sierra Leone',
            SG: 'Singapur',
            SK: 'Slowakei',
            SI: 'Slowenien',
            SB: 'Salomoninseln',
            SO: 'Somalia',
            ZA: 'Südafrika',
            GS: 'Südgeorgien und Sandwichinseln',
            ES: 'Spanien',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard und Jan Mayen',
            SZ: 'Swasiland',
            SE: 'Schweden',
            CH: 'Schweiz',
            SY: 'Arabische Republik Syrien',
            TW: 'Taiwan',
            TJ: 'Tadschikistan',
            TZ: 'Tansania',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad und Tobago',
            TN: 'Tunesien',
            TR: 'Türkei',
            TM: 'Turkmenistan',
            TC: 'Turks- und Caicosinseln',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'Vereinigte Arabische Emirate',
            GB: 'Vereinigtes Königreich',
            US: 'Vereinigte Staaten',
            UM: 'Überseeische Inseln der Vereinigten Staaten',
            UY: 'Uruguay',
            UZ: 'Usbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Jungferninseln, Britisch',
            VI: 'Jungferninseln, U.S.A.',
            WF: 'Wallis und Futuna',
            EH: 'Westsahara',
            YE: 'Jemen',
            ZM: 'Sambia',
            ZW: 'Simbabwe'
        },
    },
    fr: {
        _name: 'Française',
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            updateOrder: 'Ordre de mise à jour',
        },
        balanceModal: {
            balance: 'Balance',
            credit: 'Crédit',
            equity: 'Equity',
            practice: 'PRACTICE',
            real: 'REAL',
            account: 'ACCOUNT',
            invested: 'Investi',
            profit: 'Profit',
            loss: 'Perte',
            margin: 'Marge',
            marginLevel: 'Marge Niveau',
            marginFree: 'Marge libre'
        },
        header: {
            cfd: 'CFD',
            platform: 'Plateforme',
            deposit: 'Dépôt'
        },
        sideMenu: {
            marketWatch: 'Surveillance du marché',
            activeOrders: 'Commandes actives',
            tradingHistory: 'Historique des transactions',
            economicCalendar: 'Calendrier économique',
            marketNews: 'Nouvelles du marché'
        },
        closePositionModal: {
            closeOrder: 'Position fermée',
            areYouSure: 'Etes-vous sûr de fermer la position',
            buy: 'Acheter',
            sell: 'Vendez',
            yes: 'Oui',
            no: 'Non',
        },
        chart: {
            addNewChart: 'Ajouter un nouveau graphique',
        },
        symbolsModal: {
            watchlist: 'Liste de surveillance',
            asset: 'Actif',
            price: 'Prix',
            changePct: 'Changement 24h'
        },
        pendingModal: {
            pending: 'En attente',
            assetPrice: 'Prix de l\'actif',
            current: 'Actuel',
            revert: 'Retour au prix du marché',
            automatically: 'La position sera ouverte automatiquement lorsque le prix atteindra ce niveau'
        },
        orderMenu: {
            volume: 'Volume',
            lots: 'lots',
            units: 'unités',
            currency: 'devise',
            contractSize: 'Taille du contrat',
            position: 'Position',
            margin: 'Marge',
            freeMargin: 'Marge libre',
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            pending: 'En attente',
            market: 'Marché',
            leverage: 'Effet de levier',
            spread: 'Écartement',
            notSet: 'Non défini',
            at: 'à l\'adresse',
            buy: 'acheter',
            sell: 'vendre',
        },
        footer: {
            supportBanner: 'TOUS LES JOURS, 24 HEURES SUR 24',
            currentTime: 'HEURE ACTUELLE',
            liveChat: 'Chat en direct'
        },
        statusBar: {
            balance: 'Balance',
            credit: 'Crédit',
            equity: 'Equity',
            margin: 'Marge',
            marginLevel: 'Niveau de marge',
            freeMargin: 'Marge libre',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Date d\'enregistrement',
            userId: 'ID utilisateur',
            dashboard: 'Dashboard',
            personalData: 'Données personnelles',
            deposit: 'Dépôt',
            withdrawFunds: 'Retirer des fonds',
            savings: 'Épargne',
            settings: 'Paramètres',
            logout: 'Déconnexion'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            BT_ACCOUNT: 'Account',
            BT_BANK_NAME: 'Bank Name',
            BT_BENEFICIARY: 'Beneficiary',
            BT_ADDRESS: 'Beneficiary Address',
            BT_SWIFT: 'Swift',
            BT_IBAN: 'IBAN',
            BT_REFERENCE_CODE : 'Code de référence bancaire',
            BT_INN: 'INN',
            BT_KPP: 'Correspondent account',
            BT_CORRESPONDING: '',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Effectuer un dépôt',
            practiceAccount: 'Effectuer un dépôt',
            realAccount: 'Compte réel',
            noWithdrawals: 'Pas de retraits',
            easyWithdrawals: 'Retraits faciles',
            allAssets: 'Tous les actifs disponibles',
            fullFledged: 'Une plateforme complète',
            fillUpTo: 'Remplir jusqu\'à',
            freeReplenishment: 'Réapprovisionnement gratuit',
            topUp: 'Rechargez votre compte',
            minimumAmount: 'Montant minimum',
            canSwitch: 'Vous pouvez passer d\'un compte à l\'autre à tout moment'
        },
        gridsModal: {
            chartGrids: 'GRILLES DE CARTES',
            chart1: '1 graphique',
            chart2: '2 graphiques',
            chart3: '3 graphiques',
            chart4: '4 graphiques',
        },
        noConnectionModal: {
            connectionLost: 'La connexion avec le serveur est perdue',
            retryNow: 'Réessayer maintenant',
        },
        loginModal: {
            loginToTradeRoom: 'CONNEXION A LA TRADEROOM',
            email: 'Adresse électronique',
            emailAndId: `Adresse électronique / Numéro d'ID`,
            enterEmail: 'Entrez votre courriel',
            twoFactor: 'Code 2FA (si activé)',
            twoFactorAuth: 'Authentification à deux facteurs',
            password: 'Mot de passe',
            yourPassword: 'Votre mot de passe',
            signIn: 'Connexion',
            dontHaveAccount: 'Vous n\'avez pas de compte?',
            restore: 'Restaurer',
            fullName: 'Nom complet',
            havePromo: 'J\'ai un code promo',
            promo: 'Code promo',
            login: 'Connexion',
            haveAccount: 'Vous avez déjà un compte?',
            hide: 'Cacher',
            goTo: 'Retourner au début'
        },
        newPasswordModal: {
            setNewPassword: 'Définir un nouveau mot de passe',
            newPassword: 'Nouveau mot de passe',
            confirmNewPassword: 'Confirmer le nouveau mot de passe',
            setButton: 'Définir'
        },
        registerModal: {
            createNewAccount: 'Créer un nouveau compte',
            email: 'Adresse électronique',
            firstName: 'Prénom',
            lastName: 'Nom de famille',
            country: 'Pays',
            phone: 'Téléphone',
            password: 'Mot de passe',
            createAccount: 'Créer un compte',
            currency: 'Monnaie'
        },
        forgotPasswordModal: {
            forgotPassword: 'Mot de passe oublié?',
            submitEmail: 'Veuillez soumettre un courriel utilisé pour l\'inscription, vérifiez votre boîte de réception et suivez les instructions fournies.',
            submit: 'Soumettre'
        },
        notifications: {
            error: 'Erreur',
            success: 'Succès',
            deposit: 'Dépôt',
            withdrawal: 'Retrait',
            depositApproved: 'Le dépôt a été approuvé',
            depositDeclined: 'Le dépôt a été refusé',
            withdrawalApproved: 'Le retrait a été approuvé',
            withdrawalDeclined: 'Le retrait a été refusé',
            withdrawalCancelled: 'Le retrait a été annulé'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Stocks',
            commodities: 'Produits de base',
            indices: 'Indices',
            crypto: 'Crypto',
            metals: 'Métaux',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'BUY',
            profitCalculator: 'Profit Calculator',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Dépôt réussi',
            text: 'Votre dépôt a été traité avec succès !',
        },
        depositFailModal: {
            title: 'Dépôt échoué',
            text: 'Votre dépôt n\'a pas été traité.',
        },
        widgetMarketWatch: {
            marketWatch: 'Surveillance du marché',
            search: 'Recherche...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volume',
            margin: 'Marge',
            active: 'Actif',
            pending: 'En attente',
            activeOrders: 'Commandes actives',
            portfolio: 'Portefeuille',
            allPositions: 'Tous les postes',
            noPositionsLine1: 'Vous n\'avez pas d\'ouverture',
            noPositionsLine2: 'postes encore',
            show: 'Afficher',
            more: 'plus',
            less: 'moins',
            purchaseTime: 'Temps d\'achat',
            closePosition: 'Position fermée',
            priceOpen: 'Prix d\'achat',
            priceSl: 'Prix du Stop Loss',
            priceTp: 'Prix du Take Profit',
            type: 'Envoyer les réponses',
            pnl: 'Bénéfice/perte',
            cancelOrder: 'Annuler la commande',
            orderType: 'Type de commande',
            cancelled: 'Annulé',
            tradingHistory: 'Historique des transactions',
            noHistoryLine1: 'Vous n\'avez pas de',
            noHistoryLine2: 'des affaires conclues pour le moment',
            sortByTime: 'Trier par heure',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Calendrier économique'
        },
        widgetMarketNews: {
            marketNews: 'Nouvelles du marché'
        },
        ordersPane: {
            ordersActive: 'Commandes actives',
            ordersHistory: 'Historique des commandes',
            id: 'ID',
            symbol: 'Symbole',
            type: 'Type',
            volume: 'Volume',
            openPrice: 'Prix ouvert',
            openTime: 'Temps ouvert',
            closePrice: 'Fermer le prix',
            closeTime: 'Heure de fermeture',
            sl: 'SL',
            tp: 'TP',
            price: 'Prix',
            pnl: 'PnL',
            actions: 'Actions',
            edit: 'Modifier',
            close: 'Fermer',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Information',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        fullScreen: {
            startTrading: 'Start Trading',
            deposit: 'Effectuer un dépôt',
            dashboard: 'Dashboard',
            personalInfo: 'Mise à jour des données personnelles',
            withdrawal: 'Demander un retrait',
            verification: 'Vérification de l\'identité',
            accounts: 'Gérer les comptes',
            liveChat: 'Chat en direct',
            savings: 'Épargne',
            settings: 'Paramètres de la plate-forme',
            logOut: 'Déconnexion',
        },
        fullScreenDeposit: {
            name: 'Cardholder\'s name',
            address: 'Address',
            DOB: 'DOB',
            bankName: 'Bank name',
            additionalInfo: 'Pour déposer plus sur votre compte, veuillez contacter directement votre gestionnaire de compte',
            additionalInfoOnlyBTCpsp: "Pour ouvrir d'autres modes de paiement, veuillez fournir le KYC ou contacter votre gestionnaire de compte",
            sofortPspMessage: 'Notre équipe financière vous contactera pour vous aider à effectuer un dépôt',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'FAIRE UN DÉPÔT',
            instant: 'Instantané',
            minutes: 'minutes',
            hours: 'heures',
            days: 'jours',
            amount: 'Montant',
            continue: 'Continuer',
            qrCode: 'QR CODE',
            depositAddress: 'ADRESSE DE DÉPÔT',
            copy: 'Copie',
            last: 'DERNIER',
            deposits: 'DÉPÔTS',
            time: 'Temps',
            currency: 'Monnaie',
            status: 'Statut',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Veuillez patienter. Connexion au fournisseur de services de paiement...',
            cardPayment: 'Paiement par carte',
            inOrder: 'Afin d\'envoyer le montant',
            pleaseProvide: 'veuillez fournir les détails de la carte :'
        },
        fullScreenDashboard: {
            totalTaxes: 'Total Taxes',
            totalBalance: 'Solde Total',
            totalPnl: 'PnL Total',
            profitableOrders: 'Commandes Rentables',
            roi: 'ROI',
            activityLog: "Journal d'activités",
            loginFromIp: 'Login from IP',
            tradingResults: 'Résultats de Trading',
            week: 'Semaine',
            month: 'Mois',
            year: 'Année',
            successRate: 'Taux de Réussite',
            closedWithProfit: 'Clôturé avec un Bénéfice',
            closedWithLoss: 'Clôturé avec une Perte',
            account: 'Compte',
            balance: 'Solde',
            leverage: 'Levier',
            credit: 'Crédit',
            tradeNow: 'Trading Maintenant',
            usingCurrentRate: 'using current exchange rate',
            totalDeposits: 'Total Deposits'
        },
        fullScreenPersonal: {
            personalDetails: 'Détails personnels',
            profilePhoto: 'Photo de profil',
            firstName: 'Prénom',
            country: 'Pays',
            lastName: 'Nom de famille',
            address: 'Adresse',
            email: 'Courriel',
            phone: 'Numéro de téléphone',
            saveChanges: 'Sauvegarder les changements'
        },
        fullScreenAvatar: {
            dropFile: 'Déposez un fichier sur le cercle ci-dessus pour le télécharger',
            notAllowed: 'Il n\'est pas permis de publier',
            notAllowedLine1: 'Photos à caractère explicitement sexuel ou pornographique',
            notAllowedLine2: 'Images visant à inciter à la haine ou à l\'agression ethnique ou raciale',
            notAllowedLine3: 'Photos impliquant des personnes de moins de 18 ans',
            notAllowedLine4: 'Photos protégées par le droit d\'auteur de tiers',
            notAllowedLine5: 'Images de plus de 5 MB et dans un format autre que JPG, GIF ou PNG',
            requirements: 'Votre visage doit être clairement visible sur la photo. Toutes les photos et vidéos que vous mettez en ligne doivent respecter ces exigences, faute de quoi elles peuvent être supprimées.'
        },
        fullScreenWithdrawal: {
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'DEMANDER UN NOUVEAU RETRAIT',
            amount: 'Montant',
            currentBalance: 'Le solde de votre compte courant est de',
            withdrawAll: 'Retirer tout',
            requestWithdrawal: 'Demande de retrait',
            last: 'DERNIER',
            withdrawalRequests: 'DEMANDES DE RETRAIT',
            time: 'Temps',
            currency: 'Monnaie',
            info: 'Info',
            status: 'Statut',
            bank: 'Banque',
            bitcoin: 'Bitcoin',
            card: 'Carte',
            ethereum: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Détails du retrait',
            address: 'Adresse du portefeuille',
            bankAccount: 'Numéro de compte',
            bankHolder: 'Nom du titulaire du compte',
            bankIban: 'IBAN',
            bankSwift: 'Code SWIFT de la banque',
            sortCode: 'Code de tri',
            ifscCode: 'Code IFSC',
            bankName: 'Nom de la banque',
            bankBranch: 'Agence bancaire',
            cardNumber: 'Numéro de la carte',
            cardHolder: 'Nom du titulaire de la carte',
            incorrectAmountByFreeMargin: 'The amount is incorrect you can withdraw the amount of free margin',
            closeOrders: 'before close all orders'
        },
        fullScreenVerification: {
            dragAndDrop: 'glisser et déposer le document dans cette zone',
            documentsList: 'LISTE DES DOCUMENTS TÉLÉCHARGÉS',
            document: 'Document',
            timeUploaded: 'Temps téléchargé',
            timeProcessed: 'Temps traité',
            status: 'Statut',
            types: ['Preuve d\'identité', 'Preuve de résidence', 'Recto de la carte de crédit', 'Retour de la carte de crédit', 'Preuve d\'identité en arrière', 'Selfie','Other'],
            types2: ['Proof of Id (ID CARD, PASSPORT, DRIVER LICENCE)', 'Proof of Residence (Utility bill or Bank statement)', 'Credit Card Front', 'Credit Card Back', 'Proof of Id Back (ID CARD BACK, DRIVER LICENCE BACK)', 'Selfie','Other'],
            upload: 'Télécharger',
            isConfirmed: 'est confirmé',
            uploadAnotherDocument: 'Télécharger un autre document',
            isUnderReview: 'est en cours de révision',
            questionnaire: 'Questionnaire',
            sendAnswers: 'Envoyer les réponses',
            progressBarOf: 'of',
            progressBarText: 'of your documents have been uploaded and confirmed'
        },
        fullScreenAccounts: {
            manageAccounts: 'Transférer des fonds entre vos comptes',
            transferFrom: 'Du compte',
            transferTo: 'Pour le compte',
            amount: 'Montant',
            makeTransfer: 'Effectuer un transfert',
            yourAccounts: 'Vos comptes',
            account: 'Compte',
            currency: 'Monnaie',
            balance: 'Balance',
            credit: 'Crédit',
            makeActive: 'Rendre actif',
            active: 'Actif'
        },
        fullScreenSupport: {
            chatOff: 'Chat will be available on working days',
            sendMessage: 'Envoyer un message'
        },
        fullScreenSavings: {
            pct_0: 'Flexible',
            pct_30: 'Verrouillé 1 mois',
            pct_90: 'Verrouillé 1 trimestre',
            pct_180: 'Verrouillé 6 mois',
            pct_365: 'Verrouillé 1 an',
            pct_730: 'Verrouillé 2 an',
            savings: 'Épargne',
            detailedInformation: 'Informations détaillées',
            pleaseSelect: 'Veuillez sélectionner une devise et une période',
            openSavings: 'Épargne ouverte',
            currentTime: 'Heure actuelle',
            releaseTime: 'Heure de sortie',
            currencyChosen: 'Devise choisie',
            periodChosen: 'Période choisie',
            yourRate: 'Votre taux',
            yourAnnualRate: 'Votre taux annuel',
            yourEarnings: 'Vos revenus',
            accountFrom: 'Compte pour transférer des fonds de',
            enterAmount: 'Entrez le montant',
            in: 'Sur',
            openAndInvest: 'Ouvrir un compte d\'épargne et investir',
            investment: 'Investissement',
            period: 'Période',
            daysTotal: 'Nombre total de jours',
            finalEarnings: 'Revenu final',
            created: 'Créé',
            daysElapsed: 'Jours écoulés',
            days: 'jours',
            earnings: 'Revenus',
            fundsReleasedIn: 'Fonds libérés en',
            claim: 'Demande d\'indemnisation',
        },
        fullScreenSettings: {
            updatePassword: 'METTRE À JOUR LE MOT DE PASSE',
            currentPassword: 'Mot de passe actuel',
            newPassword: 'Nouveau mot de passe',
            changePassword: 'Changer le mot de passe',
            activationForGoogle: 'Code d\'activation pour Google Authenticator',
            activateAndEnter: 'Activez l\'Authenticator et entrez un code généré dans le champ ci-dessous.',
            qrCode: 'QR Code',
            activateProtection: 'Activer la protection 2FA',
            protectionActive: 'La protection 2FA est active',
            twoFactorCode: 'Code 2FA',
            disableProtection: 'Désactiver la protection 2FA',
            language: 'Langue',
            dashboardCurrency: 'Dashboard Currency',
            confirmNewPassword: 'Confirmer le nouveau mot de passe',
        },
        fullScreenReferrals: {
            yourLink: 'VOTRE LIEN DE RÉFÉRENCE',
            referredFriends: 'AMIS RÉFÉRENCÉS',
            id: 'ID',
            name: 'Nom',
            registered: 'Enregistré',
            level: 'Niveau',
            payout: 'Payout',
            lastPayouts: 'LES DERNIERS PAIEMENTS DE PARRAINAGE',
            time: 'Temps',
            amount: 'Montant',
            currency: 'Monnaie',
            info: 'Info'
        },
        months: {
            Jan: 'Jan',
            Feb: 'Fev',
            Mar: 'Mar',
            Apr: 'Abr',
            May: 'Mai',
            Jun: 'Juin',
            Jul: 'Jui',
            Aug: 'Aoû',
            Sep: 'Sep',
            Oct: 'Oct',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Acheter', 'Vendez', 'Limite d\'achat', 'Limite de vente', 'Stop à l\'achat', 'Stop à la vente'],
        statuses: ['En attente', 'Approuvé', 'Refusé', 'Traitement',`En cours d'examen`],
        errors: {
            INVALID_FIELDS: 'Please check that the data you have entered is correct',
            NOT_FOUND: 'Erreur de demande',
            FORBIDDEN: 'Accès refusé. Veuillez vous reconnecter',
            SERVER_ERROR: 'L\'action a échoué. Veuillez réessayer plus tard',
            INVALID_ACCOUNT: 'La session a expiré. Veuillez vous reconnecter',
            INVALID_EMAIL: 'Adresse électronique non valide',
            INVALID_PHONE: 'Numéro de téléphone non valide',
            INVALID_COUNTRY: 'Pays non valide',
            INVALID_LOGIN: 'Identifiants de connexion non valides',
            USER_BLOCKED: 'Le compte est bloqué. Veuillez contacter le support',
            USER_REGISTERED: 'L\'utilisateur dont l\'adresse électronique ou le numéro de téléphone a été saisi est déjà enregistré.',
            INVALID_USER: 'L\'utilisateur n\'est pas trouvé',
            REJECTED: 'L\'opération n\'est pas autorisée en raison des restrictions de la plate-forme',
            INVALID_OLD_PASSWORD: 'L\'ancien mot de passe n\'est pas valide',
            INVALID_SYMBOL: 'Symbole commercial non valide',
            INVALID_SYMBOL_PRECISION: 'Précision du symbole non valide',
            INVALID_FRAME: 'Cadre graphique non valide',
            INVALID_CURRENCY: 'Devise non valide',
            INVALID_PRODUCT: 'Type de trading de compte invalide',
            INSUFFICIENT_FUNDS: 'Fonds insuffisants',
            INVALID_WITHDRAWAL: 'Le retrait n\'est pas trouvé',
            INVALID_STATUS: 'Statut non valide',
            INVALID_FILE: 'Extension de fichier non valide ou fichier trop volumineux',
            INVALID_DOCUMENT: 'Le document n\'est pas trouvé',
            INVALID_NAME: 'Nom non valide',
            INVALID_LOT_SIZE: 'Taille de lot non valide',
            INVALID_LOT_STEP: 'Pas de lot invalide',
            INVALID_MIN_VOLUME: 'Volume minimal non valide',
            INVALID_MAX_VOLUME: 'Volume maximal invalide',
            INVALID_GROUP: 'Groupe non valide',
            INVALID_SPREAD: 'Diffusion non valide',
            REQUEST_TIMEOUT: 'La demande a expiré. Veuillez réessayer plus tard',
            INVALID_TYPE: 'Type de commande non valide',
            INVALID_VOLUME: 'Volume d\'ordre invalide',
            INVALID_SL: 'Valeur invalide de l\'excédent de pertes',
            INVALID_TP: 'Valeur de prise de bénéfices invalide',
            INVALID_PRICE: 'Prix de la commande invalide',
            INVALID_EXPIRATION: 'Expiration de la commande invalide',
            NOT_ENOUGH_MARGIN: 'Pas assez de marge',
            INVALID_ORDER: 'L\'ordre n\'est pas trouvé',
            MARKET_CLOSED: 'Le commerce n\'est pas disponible. Veuillez réessayer',
            INVALID_BALANCE_TYPE: 'Type d\'opération de balance non valide',
            INVALID_HASH: 'Hachage non valide',
            HASH_EXPIRED: 'Le lien de réinitialisation de votre mot de passe a expiré. Veuillez en demander un nouveau',
            INVALID_CODE: 'Code 2FA invalide',
            CHAT_DISABLED: 'L\'accès au chat d\'assistance a été fermé',
            WITHDRAWAL_NOT_ALLOWED: 'L\'accès aux retraits a été fermé',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            CLOSE_OPEN_ORDERS: 'Please close your open orders',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            REGISTRATIONS_BLOCKED: "Merci pour votre demande, Un de nos représentants vous contactera dès que possible",
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland Islands',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'American Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua And Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belgium',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnia And Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet Island',
            BR: 'Brazil',
            IO: 'British Indian Ocean Territory',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodia',
            CM: 'Cameroon',
            CA: 'Canada',
            CV: 'Cape Verde',
            KY: 'Cayman Islands',
            CF: 'Central African Republic',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands',
            CO: 'Colombia',
            KM: 'Comoros',
            CG: 'Congo',
            CD: 'Congo, Democratic Republic',
            CK: 'Cook Islands',
            CR: 'Costa Rica',
            CI: 'Cote D\'Ivoire',
            HR: 'Croatia',
            CU: 'Cuba',
            CY: 'Cyprus',
            CZ: 'Czech Republic',
            DK: 'Denmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominican Republic',
            EC: 'Ecuador',
            EG: 'Egypt',
            SV: 'El Salvador',
            GQ: 'Equatorial Guinea',
            ER: 'Eritrea',
            EE: 'Estonia',
            ET: 'Ethiopia',
            FK: 'Falkland Islands (Malvinas)',
            FO: 'Faroe Islands',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'France',
            GF: 'French Guiana',
            PF: 'French Polynesia',
            TF: 'French Southern Territories',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Germany',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Greece',
            GL: 'Greenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island & Mcdonald Islands',
            VA: 'Holy See (Vatican City State)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungary',
            IS: 'Iceland',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Iran',
            IQ: 'Iraq',
            IE: 'Ireland',
            IM: 'Isle Of Man',
            IL: 'Israel',
            IT: 'Italy',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwait',
            KG: 'Kyrgyzstan',
            LA: 'Lao People\'s Democratic Republic',
            LV: 'Latvia',
            LB: 'Lebanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libyan Arab Jamahiriya',
            LI: 'Liechtenstein',
            LT: 'Lithuania',
            LU: 'Luxembourg',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldives',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshall Islands',
            MQ: 'Martinique',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesia',
            MD: 'Moldova',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morocco',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Netherlands',
            AN: 'Netherlands Antilles',
            NC: 'New Caledonia',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk Island',
            MP: 'Northern Mariana Islands',
            NO: 'Norway',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestinian Territory, Occupied',
            PA: 'Panama',
            PG: 'Papua New Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippines',
            PN: 'Pitcairn',
            PL: 'Poland',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Romania',
            RU: 'Russia',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts And Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre And Miquelon',
            VC: 'Saint Vincent And Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome And Principe',
            SA: 'Saudi Arabia',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovakia',
            SI: 'Slovenia',
            SB: 'Solomon Islands',
            SO: 'Somalia',
            ZA: 'South Africa',
            GS: 'South Georgia And Sandwich Isl.',
            ES: 'Spain',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Suriname',
            SJ: 'Svalbard And Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Sweden',
            CH: 'Switzerland',
            SY: 'Syrian Arab Republic',
            TW: 'Taiwan',
            TJ: 'Tajikistan',
            TZ: 'Tanzania',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad And Tobago',
            TN: 'Tunisia',
            TR: 'Turkey',
            TM: 'Turkmenistan',
            TC: 'Turks And Caicos Islands',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'United Arab Emirates',
            GB: 'United Kingdom',
            US: 'United States',
            UM: 'United States Outlying Islands',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Virgin Islands, British',
            VI: 'Virgin Islands, U.S.',
            WF: 'Wallis And Futuna',
            EH: 'Western Sahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    es: {
        _name: 'Español',
        autoCloseModal: {
            takeProfitStopLoss: 'Tomar ganancias y detener pérdida',
            takeProfit: 'Tomar ganancias',
            stopLoss: 'Detener la pérdida',
            updateOrder: 'Actualizar pedido',
        },
        balanceModal: {
            balance: 'Saldo',
            credit: 'Crédito',
            equity: 'Capital',
            practice: 'PRÁCTICA',
            real: 'VERDADERO',
            account: 'CUENTA',
            invested: 'Invertido',
            profit: 'Lucro',
            loss: 'Pérdida',
            margin: 'Margen',
            marginLevel: 'Nivel de margen',
            marginFree: 'Margen libre'
        },
        header: {
            cfd: 'CFD (Contratos por Diferencia)',
            platform: 'Plataforma',
            deposit: 'Depositar'
        },
        sideMenu: {
            marketWatch: 'Reloj de mercado',
            activeOrders: 'Pedidos Activos',
            tradingHistory: 'Historial de operaciones',
            economicCalendar: 'Calendario económico',
            marketNews: 'Noticias del mercado'
        },
        closePositionModal: {
            closeOrder: 'Cerrar Posición',
            areYouSure: '¿Está seguro de cerrar la posición?',
            buy: 'Comprar',
            sell: 'Vender',
            yes: 'Sí',
            no: 'No',
        },
        chart: {
            addNewChart: 'Agregar nuevo gráfico',
        },
        symbolsModal: {
            watchlist: 'Lista de observación',
            asset: 'Activo',
            price: 'Precio',
            changePct: 'Cambio 24h'
        },
        pendingModal: {
            pending: 'Pendiente',
            assetPrice: 'Precios de los activos',
            current: 'Actual',
            revert: 'Volver al precio de mercado',
            automatically: 'La posición se abrirá automáticamente cuando el precio alcance este nivel'
        },
        orderMenu: {
            volume: 'Volumen',
            lots: 'lotes',
            units: 'unidades',
            currency: 'divisa',
            contractSize: 'Tamaño del contrato',
            position: 'Posición',
            margin: 'Margen',
            freeMargin: 'Margen libre',
            takeProfitStopLoss: 'Tomar ganancias y detener pérdidas',
            pending: 'Pendiente',
            market: 'Mercado',
            leverage: 'apalancamiento',
            spread: 'Spread',
            notSet: 'No establecido',
            at: 'en',
            buy: 'Comprar',
            sell: 'Vender',
        },
        footer: {
            supportBanner: 'TODOS LOS DÍAS, LAS 24 HORAS',
            currentTime: 'TIEMPO ACTUAL',
            liveChat: 'Chat en vivo'
        },
        statusBar: {
            balance: 'Saldo',
            credit: 'Crédito',
            equity: 'Capital',
            margin: 'Margen',
            marginLevel: 'Nivel de margen',
            freeMargin: 'Margen libre',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Fecha de registro',
            userId: 'ID de usuario',
            dashboard: 'Dashboard',
            personalData: 'Información personal',
            deposit: 'Depositar',
            withdrawFunds: 'Retirar Fondos',
            savings: 'Ahorros',
            settings: 'Ajustes',
            logout: 'Cerrar sesión'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            BT_ACCOUNT: 'Account',
            BT_BANK_NAME: 'Bank Name',
            BT_BENEFICIARY: 'Beneficiary',
            BT_ADDRESS: 'Beneficiary Address',
            BT_SWIFT: 'Swift',
            BT_IBAN: 'IBAN',
            BT_REFERENCE_CODE : 'Código de referencia bancaria',
            BT_INN: 'INN',
            BT_KPP: 'Correspondent account',
            BT_CORRESPONDING: '',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Hacer un depósito',
            practiceAccount: 'Cuenta de práctica',
            realAccount: 'Cuenta real',
            noWithdrawals: 'Sin retiros',
            easyWithdrawals: 'Retiros Fáciles',
            allAssets: 'Todos los activos disponibles',
            fullFledged: 'Plataforma completa',
            fillUpTo: 'Llenar hasta',
            freeReplenishment: 'Reposición gratuita',
            topUp: 'Recargue su cuenta',
            minimumAmount: 'Monto minimo',
            canSwitch: 'Usted puede cambiar entre sus cuentas en cualquier momento'
        },
        gridsModal: {
            chartGrids: 'Cuadrículas de gráficos',
            chart1: '1 gráfico',
            chart2: '2 gráficos',
            chart3: '3 gráficos',
            chart4: '4 gráficos',
        },
        noConnectionModal: {
            connectionLost: 'Se perdió la conexión con el servidor',
            retryNow: 'Reintentar ahora',
        },
        loginModal: {
            loginToTradeRoom: 'Iniciar sesión en Traderoom',
            email: 'Dirección de correo electrónico',
            emailAndId: 'Dirección de correo electrónico / Número de ID',
            enterEmail: 'Introduzca su correo electrónico',
            twoFactor: 'Código de 2FA(si está activado)',
            twoFactorAuth: 'Verificación en dos pasos',
            password: 'Contraseña',
            yourPassword: 'Su contraseña',
            signIn: 'Iniciar sesión',
            dontHaveAccount: 'Don’t have an account?',
            restore: 'Restore',
            fullName: 'Full Name',
            havePromo: 'I have a promo-code',
            promo: 'Promo code',
            login: 'Login',
            haveAccount: 'Already have an account?',
            hide: 'Hide',
            goTo: 'Go to back'
        },
        newPasswordModal: {
            setNewPassword: 'Establecer nueva contraseña',
            newPassword: 'Nueva contraseña',
            confirmNewPassword: 'Confirmar nueva contraseña',
            setButton: 'Establecer'
        },
        registerModal: {
            createNewAccount: 'Crear una cuenta nueva',
            email: 'Dirección de correo electrónico',
            firstName: 'Nombre',
            lastName: 'Apellido',
            country: 'País',
            phone: 'Teléfono',
            password: 'Contraseña',
            createAccount: 'Crear una cuenta',
            currency: 'Divisa'
        },
        forgotPasswordModal: {
            forgotPassword: 'Ha olvidado su contraseña?',
            submitEmail: 'Envíe un correo electrónico utilizado para el registro, verifique su bandeja de entrada y siga las instrucciones provistas.',
            submit: 'Enviar'
        },
        notifications: {
            error: 'Error',
            success: 'Éxito',
            deposit: 'Depositar',
            withdrawal: 'Retirar',
            depositApproved: 'El depósito ha sido aprobado',
            depositDeclined: 'El depósito ha sido rechazado',
            withdrawalApproved: 'Retiro ha sido aprobado',
            withdrawalDeclined: 'Retiro ha sido rechazado',
            withdrawalCancelled: 'La retirada ha sido cancelada'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Acciones',
            commodities: 'Mercancías',
            indices: 'Índices',
            crypto: 'Cripto',
            metals: 'Metales',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'BUY',
            profitCalculator: 'Profit Calculator',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Reloj de mercado',
            search: 'Buscar...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volumen',
            margin: 'Margen',
            active: 'Activo',
            pending: 'Pendiente',
            activeOrders: 'Órdenes activas',
            portfolio: 'Cartera',
            allPositions: 'Todas las posiciones',
            noPositionsLine1: 'No tienes abierto',
            noPositionsLine2: 'Posiciones todavía',
            show: 'mostrar',
            more: 'mas',
            less: 'menos',
            purchaseTime: 'Tiempo de compra',
            closePosition: 'Cerrar posición',
            priceOpen: 'Precio de compra',
            priceSl: 'precio de parada de perdida',
            priceTp: 'precio de toma de ganancias',
            type: 'Dirección de posición',
            pnl: 'Beneficios/pérdidas',
            cancelOrder: 'Cancelar orden',
            orderType: 'Tipo de orden',
            cancelled: 'Cancelado',
            tradingHistory: 'Historial comercial',
            noHistoryLine1: 'No tienes ninguno',
            noHistoryLine2: 'Acuerdos cerrados todavía',
            sortByTime: 'Ordenar por tiempo',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Calendario económico'
        },
        widgetMarketNews: {
            marketNews: 'Noticias de mercado'
        },
        ordersPane: {
            ordersActive: 'Ordenes activas',
            ordersHistory: 'Historial de ordenes',
            id: 'ID',
            symbol: 'Símbolo',
            type: 'Tipo',
            volume: 'Volumen',
            openPrice: 'Tiempo de apertura',
            openTime: 'Precio de cierre',
            closePrice: 'Hora de cierre',
            closeTime: 'Tiempo de cierre',
            sl: 'SL',
            tp: 'TP',
            price: 'Precio',
            pnl: 'PnL',
            actions: 'Acciones',
            edit: 'Editar',
            close: 'Cerrar',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Information',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        fullScreen: {
            startTrading: 'Start Trading',
            deposit: 'Hacer un depósito',
            dashboard: 'Dashboard',
            personalInfo: 'Actualizar datos personales',
            withdrawal: 'Solicitar un Retiro',
            verification: 'Verificación de identidad',
            accounts: 'Cuentas de administración',
            liveChat: 'Chat en vivo',
            savings: 'Ahorros',
            settings: 'Configuración de la plataforma',
            logOut: 'Cerrar sesión',
        },
        fullScreenDeposit: {
            name: 'Cardholder\'s name',
            address: 'Address',
            DOB: 'DOB',
            bankName: 'Bank name',
            additionalInfo: 'Para depositar más en su cuenta, por favor, póngase en contacto directamente con su gerente de cuenta',
            additionalInfoOnlyBTCpsp: 'Para abrir otros métodos de pago, por favor proporcione KYC o contacte a su gerente de cuenta',
            sofortPspMessage: 'Nuestro equipo financiero se pondrá en contacto contigo para ayudarte a realizar un depósito',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'HACER UN DEPÓSITO',
            instant: 'Instante',
            minutes: 'minutos',
            hours: 'horas',
            days: 'días',
            amount: 'Monto',
            continue: 'Continuar',
            qrCode: 'CÓDIGO QR',
            depositAddress: 'DIRECCIÓN DE DEPÓSITO',
            copy: 'Copiar',
            last: 'ULTIMO',
            deposits: 'DEPÓSITOS',
            time: 'Hora',
            currency: 'Divisa',
            status: 'Estado',
            info: 'Información',
        },
        fullScreenCcExt: {
            pleaseWait: 'Espere por favor. Conectando con el proveedor de servicios de pago...',
            cardPayment: 'Pagar con tarjeta',
            inOrder: 'Para enviar el importe',
            pleaseProvide: 'por favor proporcione los detalles de la tarjeta:'
        },
        fullScreenDashboard: {
            totalTaxes: 'Total Taxes',
            totalBalance: 'Saldo Total',
            totalPnl: 'PnL Total',
            profitableOrders: 'Pedidos Rentables',
            roi: 'ROI',
            activityLog: 'Registro de Actividad',
            loginFromIp: 'Login from IP',
            tradingResults: 'Resultados de Negocios',
            week: 'Semana',
            month: 'Mes',
            year: 'Año',
            successRate: 'Tasa de Éxito',
            closedWithProfit: 'Cerrado con Beneficio',
            closedWithLoss: 'Cerrado con Pérdida',
            account: 'Cuenta',
            balance: 'Saldo',
            leverage: 'Apalancamiento',
            credit: 'Crédito',
            tradeNow: 'Negociar Ahora',
            usingCurrentRate: 'using current exchange rate',
            totalDeposits: 'Total Deposits'
        },
        fullScreenPersonal: {
            personalDetails: 'Detalles personales',
            profilePhoto: 'Foto de perfil',
            firstName: 'Nombre',
            country: 'País',
            lastName: 'Apellido',
            address: 'Dirección',
            email: 'E-mail',
            phone: 'Número de teléfono',
            saveChanges: 'Guardar cambios'
        },
        fullScreenAvatar: {
            dropFile: 'Soltar un archivo en el círculo de arriba para subirlo',
            notAllowed: 'No está permitido publicar',
            notAllowedLine1: 'Fotos de carácter explícitamente sexual o pornográfico',
            notAllowedLine2: 'Imágenes destinadas a incitar al odio o la agresión étnica o racial',
            notAllowedLine3: 'Fotos de personas menores de 18 años',
            notAllowedLine4: 'Fotos protegidas por derechos de autor de terceros',
            notAllowedLine5: 'Imágenes de más de 5 MB y en un formato que no sea JPG, GIF o PNG',
            requirements: 'Su cara debe ser claramente visible en la foto. Todas las fotos y videos subidos por usted deben cumplir con estos requisitos, de lo contrario, pueden ser eliminados.'
        },
        fullScreenWithdrawal: {
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'SOLICITE UNA NUEVA RETIRADA',
            amount: 'Cantidad',
            currentBalance: 'El saldo de su cuenta corriente es',
            withdrawAll: 'Retirar todo',
            requestWithdrawal: 'Solicitar retiro',
            last: 'ÚLTIMO',
            withdrawalRequests: 'SOLICITUDES DE RETIRAR',
            time: 'Tiempo',
            currency: 'Moneda',
            info: 'Información',
            status: 'Estado',
            bank: 'Banco',
            bitcoin: 'Bitcoin',
            card: 'Tarjeta',
            ethereum: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Detalles del retiro',
            address: 'Dirección de la billetera',
            bankAccount: 'Número de cuenta',
            bankHolder: 'Nombre del titular de la cuenta',
            bankIban: 'IBAN',
            bankSwift: 'Código SWIFT del banco',
            sortCode: 'Código de clasificación',
            ifscCode: 'Código IFSC',
            bankName: 'Nombre del banco',
            bankBranch: 'Sucursal bancaria',
            cardNumber: 'Número de tarjeta',
            cardHolder: 'Nombre del titular de la tarjeta',
            incorrectAmountByFreeMargin: 'The amount is incorrect you can withdraw the amount of free margin',
            closeOrders: 'before close all orders'
        },
        fullScreenVerification: {
            dragAndDrop: 'Arrastrar y soltar el documento a esta área',
            documentsList: 'LISTA DE DOCUMENTOS SUBIDOS',
            document: 'Documento',
            timeUploaded: 'Tiempo subido',
            timeProcessed: 'Tiempo de procesamiento',
            status: 'Estado',
            types: ['Prueba de identificación', 'Prueba de residencia', 'Parte frontal de tarjeta de crédito', 'Parte trasera de tarjeta de crédito', 'Parte trasera de prueba de identificacion', 'Autofoto','Other'],
            types2: ['Proof of Id (ID CARD, PASSPORT, DRIVER LICENCE)', 'Proof of Residence (Utility bill or Bank statement)', 'Credit Card Front', 'Credit Card Back', 'Proof of Id Back (ID CARD BACK, DRIVER LICENCE BACK)', 'Selfie','Other'],
            upload: 'Subir',
            isConfirmed: 'Está confirmado',
            uploadAnotherDocument: 'Subir otro documento',
            isUnderReview: 'Está bajo revisió',
            questionnaire: 'Cuestionario',
            sendAnswers: 'Enviar respuestas',
            progressBarOf: 'of',
            progressBarText: 'of your documents have been uploaded and confirmed'
        },
        fullScreenAccounts: {
            manageAccounts: 'Transferir fondos entre sus cuentas',
            transferFrom: 'De la cuenta',
            transferTo: 'A la cuenta',
            amount: 'Monto',
            makeTransfer: 'Hacer transferencia',
            yourAccounts: 'Sus Cuentas',
            account: 'Cuenta',
            currency: 'Divisa',
            balance: 'Saldo',
            credit: 'Crédito',
            makeActive: 'Activar',
            active: 'Activo'
        },
        fullScreenSupport: {
            chatOff: 'Chat will be available on working days',
            sendMessage: 'Enviar mensaje'
        },
        fullScreenSavings: {
            pct_0: 'Flexible',
            pct_30: 'Bloqueado 1 mes',
            pct_90: 'Bloqueado 1 cuarto',
            pct_180: 'Bloqueado 6 meses',
            pct_365: 'Bloqueado 1 año',
            pct_730: 'Bloqueado 2 año',
            savings: 'Ahorros',
            detailedInformation: 'Información detallada',
            pleaseSelect: 'Seleccione una moneda y un período',
            openSavings: 'Ahorros abiertos',
            currentTime: 'Tiempo actual',
            releaseTime: 'Tiempo de lanzamiento',
            currencyChosen: 'Moneda elegida',
            periodChosen: 'Período elegido',
            yourRate: 'Tu tarifa',
            yourAnnualRate: 'Tu tasa anual',
            yourEarnings: 'Tus ganancias',
            accountFrom: 'Cuenta de la que se va transferir fondos',
            enterAmount: 'Introduzca la cantidad',
            in: 'V',
            openAndInvest: 'Abre una cuenta de ahorros e invierte',
            investment: 'Inversión',
            period: 'Período',
            daysTotal: 'Días totales',
            finalEarnings: 'Ganancias finales',
            created: 'Creado',
            daysElapsed: 'Días transcurridos',
            days: 'Días',
            earnings: 'Ganancias',
            fundsReleasedIn: 'Fondos liberados',
            claim: 'Reclamación',
        },
        fullScreenSettings: {
            updatePassword: 'ACTUALIZAR CONTRASEÑA',
            currentPassword: 'Contraseña actual',
            newPassword: 'Nueva contraseña',
            changePassword: 'Cambiar la contraseña',
            activationForGoogle: 'Código de activación para Google Authenticator',
            activateAndEnter: 'Active Authenticator e ingrese un código generado en el campo a continuación',
            qrCode: 'QR Código',
            activateProtection: 'Activar Protección de 2FA',
            protectionActive: 'La protección 2FA está activada',
            twoFactorCode: 'Código de 2FA',
            disableProtection: 'Desactivar protección de 2F',
            language: 'Idioma',
            dashboardCurrency: 'Dashboard Currency',
            confirmNewPassword: 'Confirmar nueva contraseña',
        },
        fullScreenReferrals: {
            yourLink: 'SU ENLACE DE REFERENCIA',
            referredFriends: 'AMIGOS RECOMENDADOS',
            id: 'ID',
            name: 'Nombre',
            registered: 'Registrado',
            level: 'Nivel',
            payout: 'Pagar',
            lastPayouts: 'ÚLTIMOS PAGOS DE REFERENCIA',
            time: 'Hora',
            amount: 'Monto',
            currency: 'Divisa',
            info: 'Información'
        },
        months: {
            Jan: 'Enero',
            Feb: 'Febrero',
            Mar: 'Marzo',
            Apr: 'Abril',
            May: 'Mayo',
            Jun: 'Junio',
            Jul: 'Julio',
            Aug: 'Agosto',
            Sep: 'Septiembre',
            Oct: 'Octubre',
            Nov: 'Noviembre',
            Dec: 'Diciembre'
        },
        orderTypes: ['Comprar', 'Vender', 'Limite de compra', 'Límite de venta', 'Parada de compra', 'Parada de venta'],
        statuses: ['Pendiente', 'Aprovado', 'Declinado', 'Procesamiento','En revisión'],
        errors: {
            INVALID_FIELDS: 'Please check that the data you have entered is correct',
            NOT_FOUND: 'Solicitar error',
            FORBIDDEN: 'Acceso denegado.Vuelve a iniciar sesión,',
            SERVER_ERROR: 'La acción falló. Inténtalo de nuevo más tarde',
            INVALID_ACCOUNT: 'La sesión expiró.Vuelve a iniciar sesión,',
            INVALID_EMAIL: 'Dirección de correo electrónico no válida',
            INVALID_PHONE: 'Número de teléfono no válido',
            INVALID_COUNTRY: 'País no válido',
            INVALID_LOGIN: 'Credenciales de acceso invalidos',
            USER_BLOCKED: 'La cuenta está bloqueada. Póngase en contacto con el soporte técnico',
            USER_REGISTERED: 'El usuario con correo electrónico o número de teléfono introducido ya está registrado',
            INVALID_USER: 'No se encuentra al usuario',
            REJECTED: 'La operación no está permitida debido a las restricciones de la plataforma',
            INVALID_OLD_PASSWORD: 'La contraseña antigua no es válida',
            INVALID_SYMBOL: 'Símbolo comercial no válido',
            INVALID_SYMBOL_PRECISION: 'Precisión del símbolo no válida',
            INVALID_FRAME: 'Marco de gráfico no válido',
            INVALID_CURRENCY: 'Moneda no válida',
            INVALID_PRODUCT: 'Tipo de operación de cuenta no válida',
            INSUFFICIENT_FUNDS: 'Fondos insuficientes',
            INVALID_WITHDRAWAL: 'No se encuentra la retirada',
            INVALID_STATUS: 'Estado no válido',
            INVALID_FILE: 'La extensión de archivo o archivo no válido es demasiado grande',
            INVALID_DOCUMENT: 'No se encuentra el documento',
            INVALID_NAME: 'Nombre no válido',
            INVALID_LOT_SIZE: 'Tamaño de lote no válido',
            INVALID_LOT_STEP: 'Escalón de lote no válido',
            INVALID_MIN_VOLUME: 'Volumen mínimo no válido',
            INVALID_MAX_VOLUME: 'Volumen máximo no válido',
            INVALID_GROUP: 'Grupo no válido',
            INVALID_SPREAD: 'Extienda no válida',
            REQUEST_TIMEOUT: 'La solicitud agotó el tiempo de espera. Inténtalo de nuevo más tarde',
            INVALID_TYPE: 'Tipo de orden no válido',
            INVALID_VOLUME: 'Volumen de pedidos no valido',
            INVALID_SL: 'Valor de perdida no valido',
            INVALID_TP: 'Valor de beneficio de toma no válido',
            INVALID_PRICE: 'Precio de pedido no válido',
            INVALID_EXPIRATION: 'Caducidad de orden no válida',
            NOT_ENOUGH_MARGIN: 'No hay suficiente margen',
            INVALID_ORDER: 'No se encuentra el pedido',
            MARKET_CLOSED: 'El comercio no está disponible. Por favor, inténtelo de nuevo',
            INVALID_BALANCE_TYPE: 'Tipo de operación de saldo no válido',
            INVALID_HASH: 'Hash no válido',
            HASH_EXPIRED: 'El enlace de restablecimiento de contraseña ha caducado. Por favor, solicite uno nuevo',
            INVALID_CODE: 'Código 2FA no válido',
            CHAT_DISABLED: 'Se ha cerrado el acceso al chat de soporte',
            WITHDRAWAL_NOT_ALLOWED: 'Se ha cerrado el acceso a los retiros',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            CLOSE_OPEN_ORDERS: 'Please close your open orders',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later',
            REGISTRATIONS_BLOCKED: "Gracias por su consulta, Uno de nuestros representantes se pondrá en contacto con usted lo antes posible",
        },
        countries: {
            AF: 'Afganistán',
            AX: 'Islas Aland',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'Samoa Americana',
            AD: 'Andora',
            AO: 'Angola',
            AI: 'Anguila',
            AQ: 'Antarctida',
            AG: 'Antigua And Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaiyan',
            BS: 'Bahamas',
            BH: 'Barein',
            BD: 'Banglades',
            BB: 'Barbados',
            BY: 'Bielorrusia',
            BE: 'Belgica',
            BZ: 'Belice',
            BJ: 'Benin',
            BM: 'Islas Bermudas',
            BT: 'Butan',
            BO: 'Bolivia',
            BA: 'Bosnia y Herzegovin',
            BW: 'Botsuana',
            BV: 'Islas Bouvet',
            BR: 'Brasil',
            IO: 'Territorio Británico del Océano Indico',
            BN: 'Brunei Darassalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Camboya',
            CM: 'Camerun',
            CA: 'Canada',
            CV: 'Cabo Verde',
            KY: 'Islas Caiman',
            CF: 'República Centroafricana',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Isla de Navidad',
            CC: 'Islas de Cocos',
            CO: 'Colombia',
            KM: 'Comoras',
            CG: 'Congo',
            CD: 'República Dominicana',
            CK: 'Islas Cook',
            CR: 'Costa Rica',
            CI: 'Costa de Marfil',
            HR: 'Croacia',
            CU: 'Cuba',
            CY: 'Chipre',
            CZ: 'República Checa',
            DK: 'Dinamarca',
            DJ: 'Yibuti',
            DM: 'Dominica',
            DO: 'República Dominicana',
            EC: 'Ecuador',
            EG: 'Egipto',
            SV: 'El Salvador',
            GQ: 'Guinea Ecuatorial',
            ER: 'Eritrea',
            EE: 'Estonia',
            ET: 'Etiopía',
            FK: 'Islas Malvinas',
            FO: 'Islas Faroe',
            FJ: 'Fiyi',
            FI: 'Finlandia',
            FR: 'Francia',
            GF: 'Guayana Francesa',
            PF: 'Polinesia Frances',
            TF: 'Territorios Franceses del Sur',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Alemania',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Grecia',
            GL: 'Groenlandia',
            GD: 'Granada',
            GP: 'Guadelupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernesey',
            GN: 'Guinea',
            GW: 'Guinea-Bisau',
            GY: 'Guayana',
            HT: 'Haiti',
            HM: 'Isla Mcdonald',
            VA: 'Estado de la Ciudad del Vaticano',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungría',
            IS: 'Islandia',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Irán',
            IQ: 'Irak',
            IE: 'Irlanda',
            IM: 'Isla del Hombre',
            IL: 'Israel',
            IT: 'Italia',
            JM: 'Jamaica',
            JP: 'Japón',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazaistan',
            KE: 'Kenia',
            KI: 'Kiribati',
            KR: 'Corea',
            KW: 'Kuwait',
            KG: 'Kirguistán',
            LA: 'República Democrática Popular de Laos',
            LV: 'Letonia',
            LB: 'Líbano',
            LS: 'Lesoto',
            LR: 'Liberia',
            LY: 'Jamahiriya Arabe Libia',
            LI: 'Liechtenstein',
            LT: 'Lituania',
            LU: 'Luxemburgo',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagascar',
            MW: 'Malauí',
            MY: 'Malasia',
            MV: 'Maldivas',
            ML: 'Malí',
            MT: 'Malta',
            MH: 'Islas Marshall',
            MQ: 'Martinica',
            MR: 'Mauritania',
            MU: 'Mauricio',
            YT: 'Mayotte',
            MX: 'México',
            FM: 'Micronesia',
            MD: 'Moldavia',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Marruecos',
            MZ: 'Mozambique',
            MM: 'Birmania',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Países Bajos',
            AN: 'Antillas Holandesas',
            NC: 'Nueva Caledonia',
            NZ: 'Nueva Zelanda',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Isla Norfolk',
            MP: 'Islas Marianas del Norte',
            NO: 'Noruega',
            OM: 'Omán',
            PK: 'Pakistán',
            PW: 'Palaos',
            PS: 'Territorio Palestino Ocupado',
            PA: 'Panama',
            PG: 'Papúa Nueva Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filipinas',
            PN: 'Pitcairn',
            PL: 'Polonia',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Katar',
            RE: 'Reunion',
            RO: 'Rumania',
            RU: 'Rusia',
            RW: 'Ruanda',
            BL: 'San Bartolomé',
            SH: 'Santa Elena',
            KN: 'San Cristóbal y Nieves',
            LC: 'Santa Lucía',
            MF: 'San Martín',
            PM: 'San Pedro y Miquelon',
            VC: 'San Vicente y las Granadillas',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tomé And Principe',
            SA: 'Arabia Saudita',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leona',
            SG: 'Singapur',
            SK: 'Eslovaquia',
            SI: 'Eslovenia',
            SB: 'Islas Solomon',
            SO: 'Somalia',
            ZA: 'Sudáfrica',
            GS: 'Islas Georgias del Sur y Sandwich.',
            ES: 'España',
            LK: 'Sri Lanka',
            SD: 'Sudán',
            SR: 'Surinam',
            SJ: 'Svalbard And Jan Mayen',
            SZ: 'Suazilandia',
            SE: 'Suecia',
            CH: 'Suiza',
            SY: 'República Árabe Siria',
            TW: 'Taiwán',
            TJ: 'Tayikistán',
            TZ: 'Tanzania',
            TH: 'Tailandia',
            TL: 'Timor Oriental',
            TG: 'Ir',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad y Tobago',
            TN: 'Túnez',
            TR: 'Pavo',
            TM: 'Turkmenistán',
            TC: 'Islas Turcas y Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ucrania',
            AE: 'Emiratos Árabes Unidos',
            GB: 'Reino Unido',
            US: 'Estados Unidos',
            UM: 'Islas periféricas de Estados Unidos',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuata',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Islas Virgenes Británicas',
            VI: 'Islas Virgenes.',
            WF: 'Wallis y Futuna',
            EH: 'Sáhara Occidental',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabue'
        },
    },
    dk: {
        _name: 'Dansk',
        autoCloseModal: {
            takeProfitStopLoss: 'Tag overskud  & Stop Tab',
            takeProfit: 'Tag overskud',
            stopLoss: 'Stop Tab',
            updateOrder: 'Opdatering af ordre',
        },
        balanceModal: {
            balance: 'Balance',
            credit: 'Kredit',
            equity: 'Egenkapital',
            practice: 'PRAKTIK',
            real: 'REAL',
            account: 'KONTO',
            invested: 'Investeret',
            profit: 'Overskud',
            loss: 'Tab',
            margin: 'Margen',
            marginLevel: 'Margin-niveau',
            marginFree: 'Fri margen'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Depositum'
        },
        sideMenu: {
            marketWatch: 'Markedsovervågning',
            activeOrders: 'Aktive ordrer',
            tradingHistory: 'Handelshistorie',
            economicCalendar: 'Økonomisk kalender',
            marketNews: 'Nyheder om markedet'
        },
        closePositionModal: {
            closeOrder: 'Luk position',
            areYouSure: 'Er du sikker på, at du vil lukke stillingen',
            buy: 'Køb',
            sell: 'Sælg',
            yes: 'Ja',
            no: 'Nej',
        },
        chart: {
            addNewChart: 'Tilføj nyt diagram',
        },
        symbolsModal: {
            watchlist: 'Overvågningsliste',
            asset: 'Formue',
            price: 'Pris',
            changePct: 'Ændring 24 timer'
        },
        pendingModal: {
            pending: 'I vente',
            assetPrice: 'Aktivpris',
            current: 'Nuværende',
            revert: 'Tilbage til markedsprisen',
            automatically: 'Positionen vil blive åbnet automatisk, når prisen når dette niveau'
        },
        orderMenu: {
            volume: 'Volumen',
            lots: 'partier',
            units: 'enheder',
            currency: 'valuta',
            contractSize: 'Kontraktens størrelse',
            position: 'Position',
            margin: 'Margen',
            freeMargin: 'Fri margen',
            takeProfitStopLoss: 'Tag overskud  & Stop Tab',
            pending: 'I vente',
            market: 'Marked',
            leverage: 'Løftestang',
            spread: 'Spred',
            notSet: 'Ikke indstillet',
            at: 'på',
            buy: 'Køb',
            sell: 'Sælg',
        },
        footer: {
            supportBanner: 'HVER DAG, DØGNET RUNDT',
            currentTime: 'AKTUEL TID',
            liveChat: 'Live Chat'
        },
        statusBar: {
            balance: 'Balance',
            credit: 'Kredit',
            equity: 'Egenkapital',
            margin: 'Margen',
            marginLevel: 'Margen-niveau',
            freeMargin: 'Fri margen',
            pnl: 'PnL',
            profitTotal: 'Livstid PnL'
        },
        accountModal: {
            dateRegistered: 'Dato for registrering',
            userId: 'Bruger ID',
            dashboard: 'Instrumentbræt',
            personalData: 'Personlige data',
            deposit: 'Depositum',
            withdrawFunds: 'Trække penge ud',
            savings: 'Opsparing',
            settings: 'Indstillinger',
            logout: 'Logout'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            BT_ACCOUNT: 'Account',
            BT_BANK_NAME: 'Bank Name',
            BT_BENEFICIARY: 'Beneficiary',
            BT_ADDRESS: 'Beneficiary Address',
            BT_SWIFT: 'Swift',
            BT_IBAN: 'IBAN',
            BT_REFERENCE_CODE : 'Bankreferencekode',
            BT_INN: 'INN',
            BT_KPP: 'Correspondent account',
            BT_CORRESPONDING: '',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Foretag en indbetaling',
            practiceAccount: 'Praksiskonto',
            realAccount: 'Rigtig konto',
            noWithdrawals: 'No Withdrawals',
            easyWithdrawals: 'Nemme udbetalinger',
            allAssets: 'Alle disponible aktiver',
            fullFledged: 'En fuldgyldig platform',
            fillUpTo: 'Fyld op til',
            freeReplenishment: 'Gratis genopfyldning',
            topUp: 'Opfyld din konto',
            minimumAmount: 'Mindste beløb',
            canSwitch: 'Du kan skifte mellem dine konti når som helst'
        },
        gridsModal: {
            chartGrids: 'DIAGRAMMER',
            chart1: '1 diagram',
            chart2: '2 diagrammer',
            chart3: '3 diagrammer',
            chart4: '4 diagrammer',
        },
        noConnectionModal: {
            connectionLost: 'Forbindelsen med serveren er mistet',
            retryNow: 'Prøv igen nu',
        },
        loginModal: {
            loginToTradeRoom: 'Log ind på Traderoom',
            email: 'E-mail-adresse',
            emailAndId: 'E-mail adresse / ID-nummer',
            enterEmail: 'Indtast din e-mail',
            twoFactor: '2FA-kode (hvis aktiveret)',
            twoFactorAuth: 'To-faktor-autentifikation',
            password: 'Adgangskode',
            yourPassword: 'Your password',
            signIn: 'Log ind',
            dontHaveAccount: 'Har du ikke en konto?',
            restore: 'Gendan',
            fullName: 'Fuldt navn',
            havePromo: 'Jeg har en promo-kode',
            promo: 'Promo-kode',
            login: 'Login',
            haveAccount: 'Har du allerede en konto?',
            hide: 'Skjul',
            goTo: 'Gå tilbage'
        },
        newPasswordModal: {
            setNewPassword: 'Indstil nyt kodeord',
            newPassword: 'Ny adgangskode',
            confirmNewPassword: 'Bekræft ny adgangskode',
            setButton: 'Indstil'
        },
        registerModal: {
            createNewAccount: 'Opret en ny konto',
            email: 'E-mail-adresse',
            firstName: 'Fornavn',
            lastName: 'Efternavn',
            country: 'Land',
            phone: 'Telefon',
            password: 'Adgangskode',
            createAccount: 'Opret konto',
            currency: 'Valuta'
        },
        forgotPasswordModal: {
            forgotPassword: 'Har du glemt din adgangskode?',
            submitEmail: 'Send venligst en e-mail, der anvendes til registrering, tjek din indbakke og følg instruktionerne',
            submit: 'Indsend'
        },
        notifications: {
            error: 'Fejl',
            success: 'Succes',
            deposit: 'Depositum',
            withdrawal: 'Tilbagetrækning',
            depositApproved: 'Depositum er blevet godkendt',
            depositDeclined: 'Depositum er blevet afvist',
            withdrawalApproved: 'Tilbagetrækningen er blevet godkendt',
            withdrawalDeclined: 'Tilbagetrækning er blevet afvist',
            withdrawalCancelled: 'Udbetalingen er blevet annulleret'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Aktier',
            commodities: 'Råvarer',
            indices: 'Indekser',
            crypto: 'Crypto',
            metals: 'Metals',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'KØB',
            sell: 'SÆLG',
            volume: 'Volumen',
            entryPrice: 'Indgangspris',
            takeProfit: 'Tag overskud',
            stopLoss: 'Stop Tab',
            maxPosition: 'Max Position',
            calculate: 'Beregn',
            calculationsFor: 'Beregninger for',
            leverage: 'Løftestang',
            requiredMargin: 'Nødvendig margen',
            profitFromTP: 'Overskud fra TP',
            lossFromSL: 'Tab fra SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Depositum lykkedes',
            text: 'Din indbetaling er blevet behandlet med succes!',
        },
        depositFailModal: {
            title: 'Depositum mislykkedes',
            text: 'Din indbetaling er ikke blevet behandlet.',
        },
        widgetMarketWatch: {
            marketWatch: 'Markedsovervågning',
            search: 'Søg på...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volumen',
            margin: 'Margen',
            active: 'Aktiv',
            pending: 'I vente',
            activeOrders: 'Aktive ordrer',
            portfolio: 'Portefølje',
            allPositions: 'Alle stillinger',
            noPositionsLine1: 'Du har ingen åbne',
            noPositionsLine2: 'stillinger endnu',
            show: 'Vis',
            more: 'mere',
            less: 'mindre',
            purchaseTime: 'Tidspunkt for køb',
            closePosition: 'Luk position',
            priceOpen: 'Købspris',
            priceSl: 'Stop Loss-pris',
            priceTp: 'Take Profit Pris',
            type: 'Position Retning',
            pnl: 'Overskud/tab',
            cancelOrder: 'Annuller bestilling',
            orderType: 'Bestillingstype',
            cancelled: 'Annulleret',
            tradingHistory: 'Handelshistorie',
            noHistoryLine1: 'Du har ikke nogen',
            noHistoryLine2: 'endnu ikke indgået aftaler',
            sortByTime: 'Sorter efter tid',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Økonomisk kalender'
        },
        widgetMarketNews: {
            marketNews: 'Nyheder om markedet'
        },
        ordersPane: {
            ordersActive: 'Aktive ordrer',
            ordersHistory: 'Ordrehistorik',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Type',
            volume: 'Volumen',
            openPrice: 'Åben pris',
            openTime: 'Åben tid',
            closePrice: 'Luk pris',
            closeTime: 'Luk tid',
            sl: 'SL',
            tp: 'TP',
            price: 'Pris',
            pnl: 'PnL',
            actions: 'Foranstaltninger',
            edit: 'Rediger',
            close: 'Luk',
            commission: 'Kommissionen',
            swap: 'Byt',
            swapLong: 'Bytte lang',
            swapShort: 'Bytte kort'
        },
        assetInfoModal: {
            assetInfo: 'Oplysninger om aktiver',
            tradingConditions: 'Handelsbetingelser',
            information: 'Oplysninger',
            bid: 'Bud',
            ask: 'Spørg',
            sessionChange: 'Ændring af session',
            tradeNow: 'Handel nu',
            opens: 'Åbner',
            closes: 'Lukker',
            at: 'på',
            open247: 'Åben 24/7',
            today: 'i dag',
            tomorrow: 'i morgen',
            sunday: 'Søndag',
            monday: 'Mandag',
            tuesday: 'Tirsdag',
            wednesday: 'Onsdag',
            thursday: 'Torsdag',
            friday: 'Fredag',
            saturday: 'Lørdag',
            contractSpecification: 'Kontraktspecifikation',
            symbol: 'Symbol',
            name: 'Navn',
            market: 'Marked',
            baseCurrency: 'Basisvaluta',
            digits: 'Cifre',
            lotSize: 'Størrelse af parti',
            lotStep: 'Parti trin',
            minVolume: 'Min Volumen',
            maxVolume: 'Max Volumen',
            leverage: 'Løftestang',
            commission: 'Kommissionen',
            swapLong: 'Bytte lang',
            swapShort: 'Bytte kort',
            schedule: 'Tidsplan',
            weekday: 'Hverdag',
            tradingTime: 'Handelstid',
            closed: 'lukket',
            sell: 'Sælg',
            buy: 'Køb',
            low: 'Lav',
            high: 'Høj',
            oneHourChange: '1 times ændring',
            oneDayChange: '1 dags ændring',
            oneWeekChange: '1 uges ændring',
            oneMonthChange: '1 måneds ændring',
            threeMonthsChange: '3 måneders ændring',
            oneYearChange: '1 års ændring',
            loading: 'Indlæsning...',
        },
        fullScreen: {
            startTrading: 'Start handel',
            deposit: 'Depositum',
            dashboard: 'Instrumentbræt',
            personalInfo: 'Personlig information',
            withdrawal: 'Tilbagetrækning',
            verification: 'Verifikation',
            accounts: 'Regnskaber',
            liveChat: 'Live Chat',
            savings: 'Opsparing',
            settings: 'Indstillinger',
            logOut: 'Log ud',
        },
        fullScreenDeposit: {
            name: 'Cardholder\'s name',
            address: 'Address',
            DOB: 'DOB',
            bankName: 'Bank name',
            additionalInfo: 'For at indsætte mere på din konto, bedes du kontakte din kontoleder direkte',
            additionalInfoOnlyBTCpsp: 'For at åbne andre betalingsmetoder, skal du venligst give KYC eller kontakte din kontomanager',
            sofortPspMessage: 'Vores finansteam vil kontakte dig for at hjælpe dig med at foretage en indbetaling',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'GØR ET INDBETALING',
            instant: 'Øjeblikkelig',
            minutes: 'minutter',
            hours: 'timer',
            days: 'dage',
            amount: 'Beløb',
            continue: 'Fortsæt',
            qrCode: 'QR CODE',
            depositAddress: 'INDBETALINGSADRESSE',
            copy: 'Kopier',
            last: 'SIDSTE',
            deposits: 'INDBETALINGER',
            time: 'Tid',
            currency: 'Valuta',
            status: 'Status',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Vent venligst. Oprettelse af forbindelse til udbyder af betalingstjenester...',
            cardPayment: 'Card payment',
            inOrder: 'For at sende beløbet',
            pleaseProvide: 'Angiv venligst kortoplysninger:'
        },
        fullScreenDashboard: {
            totalTaxes: 'Total Taxes',
            totalBalance: 'Samlet saldo',
            totalPnl: 'Samlet PNL',
            profitableOrders: 'Rentable ordrer',
            roi: 'ROI',
            activityLog: 'Aktivitetslogbog',
            loginFromIp: 'Login fra IP',
            tradingResults: 'Handelsresultater',
            week: 'Uge',
            month: 'Måned',
            year: 'År',
            successRate: 'Succesrate',
            closedWithProfit: 'Lukket med overskud',
            closedWithLoss: 'Lukket med tab',
            account: 'Konto',
            balance: 'Balance',
            leverage: 'Løftestang',
            credit: 'Kredit',
            tradeNow: 'Handel nu',
            usingCurrentRate: 'ved anvendelse af den aktuelle valutakurs',
            totalDeposits: 'Total Deposits'
        },
        fullScreenPersonal: {
            personalDetails: 'Personlige oplysninger',
            profilePhoto: 'Profilbillede',
            firstName: 'Fornavn',
            country: 'Land',
            lastName: 'Efternavn',
            address: 'Adresse',
            email: 'E-mail',
            phone: 'Telefon nummer',
            saveChanges: 'Gem ændringer',
        },
        fullScreenAvatar: {
            dropFile: 'Smid en fil på cirklen ovenfor for at uploade',
            notAllowed: 'Det er ikke tilladt at offentliggøre',
            notAllowedLine1: 'Billeder af eksplicit seksuel eller pornografisk karakter',
            notAllowedLine2: 'Billeder, der har til formål at tilskynde til etnisk eller racemæssigt had eller aggression',
            notAllowedLine3: 'Billeder, der involverer personer under 18 år',
            notAllowedLine4: 'Ophavsretligt beskyttede fotos fra tredjeparter',
            notAllowedLine5: 'Billeder, der er større end 5 MB og i et andet format end JPG, GIF eller PNG',
            requirements: 'Dit ansigt skal være tydeligt synligt på billedet. Alle billeder og videoer, som du uploader, skal opfylde disse krav, ellers kan de blive fjernet.'
        },
        fullScreenWithdrawal: {
            withdrawal: 'Tilbagetrækning',
            requestNewWithdrawal: 'ANMODE OM EN NY UDBETALING',
            amount: 'Beløb',
            currentBalance: 'Din nuværende kontosaldo er',
            withdrawAll: 'Tilbagetrækning af alle',
            requestWithdrawal: 'Anmodning om tilbagetrækning',
            last: 'SIDSTE',
            withdrawalRequests: 'ANMODNINGER OM TILBAGETRÆKNING',
            time: 'Tid',
            currency: 'Valuta',
            info: 'Info',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            card: 'Kort',
            ethereum: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Oplysninger om tilbagetrækning',
            address: 'Tegnebogens adresse',
            bankAccount: 'Kontonummer',
            bankHolder: 'Kontohaverens navn',
            bankIban: 'IBAN',
            bankSwift: 'Bankens SWIFT-kode',
            sortCode: 'Sorteringskode',
            ifscCode: 'IFSC-kode',
            bankName: 'Banknavn',
            bankBranch: 'Bankfilial',
            cardNumber: 'Kortnummer',
            cardHolder: 'Kortindehaverens navn',
            incorrectAmountByFreeMargin: 'The amount is incorrect you can withdraw the amount of free margin',
            closeOrders: 'before close all orders'
        },
        fullScreenVerification: {
            dragAndDrop: 'træk og slip dokumentet til dette område',
            documentsList: 'LISTE OVER UPLOADEDE DOKUMENTER',
            document: 'Dokument',
            timeUploaded: 'Tidspunkt uploadet',
            timeProcessed: 'Behandlet tid',
            status: 'Status',
            types: ['Bevis for id', 'Bevis for bopæl', 'Kreditkort forside', 'Kreditkort tilbage', 'Bevis for id tilbage', 'Selfie','Other'],
            types2: ['Proof of Id (ID CARD, PASSPORT, DRIVER LICENCE)', 'Proof of Residence (Utility bill or Bank statement)', 'Credit Card Front', 'Credit Card Back', 'Proof of Id Back (ID CARD BACK, DRIVER LICENCE BACK)', 'Selfie','Other'],
            upload: 'Overfør',
            isConfirmed: 'er bekræftet',
            uploadAnotherDocument: 'Upload et andet dokument',
            isUnderReview: 'er under revision',
            questionnaire: 'Spørgeskema',
            sendAnswers: 'Send svar',
            progressBarOf: 'på',
            progressBarText: 'alle dine dokumenter er blevet uploadet og bekræftet'
        },
        fullScreenAccounts: {
            manageAccounts: 'Overfør penge mellem dine konti',
            transferFrom: 'Fra konto',
            transferTo: 'Til regnskab',
            amount: 'Beløb',
            makeTransfer: 'Foretag overførsel',
            yourAccounts: 'Dine konti',
            account: 'Konto',
            currency: 'Valuta',
            balance: 'Balance',
            credit: 'Kredit',
            makeActive: 'Gør aktiv',
            active: 'Aktiv'
        },
        fullScreenSupport: {
            chatOff: 'Chat will be available on working days',
            sendMessage: 'Send besked'
        },
        fullScreenSavings: {
            pct_0: 'Fleksibel',
            pct_30: 'Låst 1 måned',
            pct_90: 'Låst 1 kvartal',
            pct_180: 'Låst 6 måneder',
            pct_365: 'Låst 1 år',
            pct_730: 'Låst 2 år',
            savings: 'Opsparing',
            detailedInformation: 'Detaljerede oplysninger',
            pleaseSelect: 'Vælg venligst en valuta og en periode',
            openSavings: 'Åben opsparing',
            currentTime: 'Nuværende tid',
            releaseTime: 'Udgivelsestidspunkt',
            currencyChosen: 'Valgt valuta',
            periodChosen: 'Valgt periode',
            yourRate: 'Din pris',
            yourAnnualRate: 'Din årlige sats',
            yourEarnings: 'Din indtjening',
            accountFrom: 'Konto til overførsel af midler fra',
            enterAmount: 'Indtast beløbet',
            in: 'På',
            openAndInvest: 'Åbn en opsparingskonto og invester',
            investment: 'Investering',
            period: 'Periode',
            daysTotal: 'Antal dage i alt',
            finalEarnings: 'Endelig indtjening',
            created: 'Oprettet',
            daysElapsed: 'Forløbne dage',
            days: 'dage',
            earnings: 'Indtjening',
            fundsReleasedIn: 'Midler frigivet i',
            claim: 'Krav',
        },
        fullScreenSettings: {
            updatePassword: 'OPDATERE ADGANGSKODE',
            currentPassword: 'Nuværende adgangskode',
            newPassword: 'Nyt kodeord',
            changePassword: 'Ændre adgangskode',
            activationForGoogle: 'Aktiveringskode til Google Authenticator',
            activateAndEnter: 'Aktivér Authenticator, og indtast en genereret kode i feltet nedenfor',
            qrCode: 'QR-kode',
            activateProtection: 'Aktiver 2FA-beskyttelse',
            protectionActive: '2FA-beskyttelse er aktiv',
            twoFactorCode: '2FA-kode',
            disableProtection: 'Deaktivere 2FA-beskyttelse',
            language: 'Sprog',
            dashboardCurrency: 'Dashboard Valuta',
            confirmNewPassword: 'Bekræft ny adgangskode',
        },
        fullScreenReferrals: {
            yourLink: 'DIT HENVISNINGSLINK',
            referredFriends: 'HENVISTE VENNER',
            id: 'ID',
            name: 'Navn',
            registered: 'Registreret',
            level: 'Niveau',
            payout: 'Udbetaling',
            lastPayouts: 'SENESTE UDBETALINGER VED HENVISNING',
            time: 'Tid',
            amount: 'Beløb',
            currency: 'Valuta',
            info: 'Info'
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'Maj',
            Jun: 'Jun',
            Jul: 'Jul',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Oct',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Køb', 'Salg', 'Køb Limit', 'Salg Limit', 'Køb Stop', 'Salg Stop'],
        statuses: ['Afventer', 'Godkendt', 'Afvist', 'Behandling','Under gennemgang'],
        errors: {
            INVALID_FIELDS: 'Please check that the data you have entered is correct',
            NOT_FOUND: 'Fejl i anmodningen',
            FORBIDDEN: 'Adgang nægtet. Log venligst ind igen',
            SERVER_ERROR: 'Indsatsen mislykkedes. Prøv venligst igen senere',
            INVALID_ACCOUNT: 'Sessionen udløb. Log venligst ind igen',
            INVALID_EMAIL: 'Ugyldig e-mail-adresse',
            INVALID_PHONE: 'Ugyldigt telefonnummer',
            INVALID_COUNTRY: 'Ugyldigt land',
            INVALID_LOGIN: 'Ugyldige loginoplysninger',
            USER_BLOCKED: 'Kontoen er spærret. Kontakt venligst support',
            USER_REGISTERED: 'Brugeren med det indtastede e-mail eller telefonnummer er allerede registreret',
            INVALID_USER: 'Brugeren er ikke fundet',
            REJECTED: 'Drift er ikke tilladt på grund af platformsrestriktioner',
            INVALID_OLD_PASSWORD: 'Det gamle password er ugyldigt',
            INVALID_SYMBOL: 'Ugyldigt handelssymbol',
            INVALID_SYMBOL_PRECISION: 'Ugyldig symbolpræcision',
            INVALID_FRAME: 'Ugyldig diagramramme',
            INVALID_CURRENCY: 'Ugyldig valuta',
            INVALID_PRODUCT: 'Ugyldig konto handelstype',
            INSUFFICIENT_FUNDS: 'Utilstrækkelige midler',
            INVALID_WITHDRAWAL: 'Tilbagetrækning er ikke fundet',
            INVALID_STATUS: 'Ugyldig status',
            INVALID_FILE: 'Ugyldig filudvidelse eller filen er for stor',
            INVALID_DOCUMENT: 'Dokumentet er ikke fundet',
            INVALID_NAME: 'Ugyldigt navn',
            INVALID_LOT_SIZE: 'Ugyldig partistørrelse',
            INVALID_LOT_STEP: 'Ugyldigt partitrin',
            INVALID_MIN_VOLUME: 'Ugyldig minimumsvolumen',
            INVALID_MAX_VOLUME: 'Ugyldig maksimal volumen',
            INVALID_GROUP: 'Ugyldig gruppe',
            INVALID_SPREAD: 'Ugyldig spredning',
            REQUEST_TIMEOUT: 'Anmodningen er udløbet. Prøv venligst igen senere',
            INVALID_TYPE: 'Ugyldig bestillingstype',
            INVALID_VOLUME: 'Ugyldig ordremængde',
            INVALID_SL: 'Ugyldig stop loss-værdi',
            INVALID_TP: 'Ugyldig take profit-værdi',
            INVALID_PRICE: 'Ugyldig ordrepris',
            INVALID_EXPIRATION: 'Ugyldig ordreudløb',
            NOT_ENOUGH_MARGIN: 'Ikke tilstrækkelig margen',
            INVALID_ORDER: 'Ordren er ikke fundet',
            MARKET_CLOSED: 'Handel er ikke tilgængelig. Prøv venligst igen',
            INVALID_BALANCE_TYPE: 'Ugyldig type af balanceoperation',
            INVALID_HASH: 'Ugyldig hash',
            HASH_EXPIRED: 'Dit link til nulstilling af adgangskode er udløbet. Anmod venligst om et nyt',
            INVALID_CODE: 'Ugyldig 2FA-kode',
            CHAT_DISABLED: 'Adgang til supportchatten er blevet lukket',
            WITHDRAWAL_NOT_ALLOWED: 'Adgangen til tilbagekøb er blevet lukket',
            TRADING_DISABLED: 'Handel er deaktiveret for den løbende konto',
            PENDING_DEPOSITS_LIMIT: 'Kun én igangværende indbetaling er tilladt',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Kun én igangværende udbetaling er tilladt',
            LOCAL_DEPOSIT_ONLY_REAL: 'Indbetaling er kun tilgængelig for rigtige konti',
            LOCAL_DEPOSIT_POSITIVE: 'Indbetalingsbeløbet skal være over 0',
            LOCAL_INVALID_CARD_NUMBER: 'Ugyldigt kortnummer',
            LOCAL_INVALID_CARD_EXPIRATION: 'Ugyldigt kortudløb',
            LOCAL_INVALID_CVC: 'Ugyldig CVV/CVC-kode',
            LOCAL_PASSWORDS_NOT_MATCH: 'Adgangskoderne stemmer ikke overens, indtast venligst igen',
            LOCAL_NO_TRADING_ACCOUNTS: 'Du har ingen handelskonti til rådighed. Kontakt venligst supporten',
            LOCAL_MIN_DEPOSIT: 'Mindste indbetaling er $',
            LOCAL_MIN_WITHDRAWAL: 'Mindste udbetalingsbeløb er $',
            LOCAL_INVALID_FILE_TYPE: 'Filformat ikke understøttet. Vedhæft venligst kun JPG, PNG eller PDF',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later',
            REGISTRATIONS_BLOCKED: "Tak for din forespørgsel, En af vores repræsentanter vil kontakte dig så hurtigt som muligt",
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland-øerne',
            AL: 'Albanien',
            DZ: 'Algeriet',
            AS: 'Amerikanske Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktis',
            AG: 'Antigua og Barbuda',
            AR: 'Argentina',
            AM: 'Armenien',
            AW: 'Aruba',
            AU: 'Australien',
            AT: 'Østrig',
            AZ: 'Aserbajdsjan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Hviderusland',
            BE: 'Belgien',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnien og Hercegovina',
            BW: 'Botswana',
            BV: 'Bouvet-øen',
            BR: 'Brasilien',
            IO: 'Britisk territorium i Det Indiske Ocean',
            BN: 'Brunei Darussalam',
            BG: 'Bulgarien',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodja',
            CM: 'Cameroun',
            CA: 'Canada',
            CV: 'Kap Verde',
            KY: 'Caymanøerne',
            CF: 'Den Centralafrikanske Republik',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'Kina',
            CX: 'Juleøen',
            CC: 'Cocos- (Keeling) øerne',
            CO: 'Colombia',
            KM: 'Comorerne',
            CG: 'Congo',
            CD: 'Congo, Den Demokratiske Republik',
            CK: 'Cookøerne',
            CR: 'Costa Rica',
            CI: 'Elfenbenskysten',
            HR: 'Kroatien',
            CU: 'Cuba',
            CY: 'Cypern',
            CZ: 'Tjekkiet',
            DK: 'Danmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Den Dominikanske Republik',
            EC: 'Ecuador',
            EG: 'Egypten',
            SV: 'El Salvador',
            GQ: 'Ækvatorialguinea',
            ER: 'Eritrea',
            EE: 'Estland',
            ET: 'Etiopien',
            FK: 'Falklandsøerne (Malvinas)',
            FO: 'Færøerne',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'Frankrig',
            GF: 'Fransk Guyana',
            PF: 'Fransk Polynesien',
            TF: 'Franske sydlige territorier',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgien',
            DE: 'Tyskland',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Grækenland',
            GL: 'Grønland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island og Mcdonald-øerne',
            VA: 'Den Hellige Stol (Vatikanstaten)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Ungarn',
            IS: 'Island',
            IN: 'Indien',
            ID: 'Indonesien',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Irland',
            IM: 'Man-øen',
            IL: 'Israel',
            IT: 'Italien',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kasakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwait',
            KG: 'Kirgisistan',
            LA: 'Den Demokratiske Folkerepublik Laos',
            LV: 'Letland',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libysk-Arabisk Jamahiriya',
            LI: 'Liechtenstein',
            LT: 'Litauen',
            LU: 'Luxembourg',
            MO: 'Macao',
            MK: 'Makedonien',
            MG: 'Madagaskar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldiverne',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshalløerne',
            MQ: 'Martinique',
            MR: 'Mauritanien',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Mikronesien',
            MD: 'Moldova',
            MC: 'Monaco',
            MN: 'Mongoliet',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morokko',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Nederlandene',
            AN: 'De Nederlandske Antiller',
            NC: 'Ny Kaledonien',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk-øen',
            MP: 'Nordmarianerne',
            NO: 'Norge',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palæstinensisk område, besat',
            PA: 'Panama',
            PG: 'Papua Ny Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filippinerne',
            PN: 'Pitcairn',
            PL: 'Polen',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Rumænien',
            RU: 'Rusland',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts And Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre og Miquelon',
            VC: 'Saint Vincent og Grenadinerne',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome Og Principe',
            SA: 'Saudi Arabien',
            SN: 'Senegal',
            RS: 'Serbien',
            SC: 'Seychellerne',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovakiet',
            SI: 'Slovenien',
            SB: 'Salomonøerne',
            SO: 'Somalia',
            ZA: 'Sydafrika',
            GS: 'Sydgeorgien og Sandwichøerne',
            ES: 'Spanien',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard og Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Sverige',
            CH: 'Schweiz',
            SY: 'Den Syriske Arabiske Republik',
            TW: 'Taiwan',
            TJ: 'Tadsjikistan',
            TZ: 'Tanzania',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad Og Tobago',
            TN: 'Tunisien',
            TR: 'Turkiet',
            TM: 'Turkmenistan',
            TC: 'Turks- og Caicosøerne',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'Forenede Arabiske Emirater',
            GB: 'Det Forenede Kongerige',
            US: 'Forenede Stater',
            UM: 'United States Outlying Islands',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Jomfruøerne, Britiske',
            VI: 'Virgin Islands, U.S.',
            WF: 'Wallis Og Futuna',
            EH: 'Vestsahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    nl: {
        _name: 'Nederlandse taal',
        autoCloseModal: {
            takeProfitStopLoss: 'Neem Winst & Stoppen Verlies',
            takeProfit: 'Neem Winst ',
            stopLoss: 'Stoppen Verlies',
            updateOrder: 'Bestelling Bijwerken',
        },
        balanceModal: {
            balance: 'Balans',
            credit: 'krediet',
            equity: 'Equity',
            practice: 'PRAKTIJK',
            real: 'ECHT',
            account: 'ACCOUNT',
            invested: 'Geïnvesteerd',
            profit: 'Winst',
            loss: 'Verlies',
            margin: 'marge',
            marginLevel: 'Onderhoudsmarge',
            marginFree: 'Vrij Marge'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Storting'
        },
        sideMenu: {
            marketWatch: 'Markt bekijken',
            activeOrders: 'Open Posities',
            tradingHistory: 'Handel Geschiedenis',
            economicCalendar: 'Economisch Kalender',
            marketNews: 'Markt Nieuws'
        },
        closePositionModal: {
            closeOrder: 'Gesloten positie ',
            areYouSure: 'Weet je zeker dat je de positie sluit?',
            buy: 'Kopen',
            sell: 'Verkopen',
            yes: 'Ja',
            no: 'Nee',
        },
        chart: {
            addNewChart: 'Nieuwe grafiek toevoegen',
        },
        symbolsModal: {
            watchlist: 'Volglijst',
            asset: 'Bedrijfsmiddel',
            price: 'Prijs',
            changePct: 'verandering 24u'
        },
        pendingModal: {
            pending: 'Laden',
            assetPrice: 'Bedrijfsmiddel Prijs',
            current: 'Actueel',
            revert: 'Terug naar Markt Prijs',
            automatically: 'Positie wordt automatisch geopend wanneer de prijs dit niveau bereikt'
        },
        orderMenu: {
            volume: 'Volume',
            lots: 'lots',
            units: 'units',
            currency: 'valuta',
            contractSize: 'Contractgrootte',
            position: 'Positie',
            margin: 'Marge',
            freeMargin: 'Vrij Marge',
            takeProfitStopLoss: 'Neem Winst & Stoppen Verlies',
            pending: 'Laden',
            market: 'Markt',
            leverage: 'Hefboom',
            spread: 'Verspreiding',
            notSet: 'Niet ingesteld',
            at: 'Bij',
            buy: 'kopen',
            sell: 'verkopen',
        },
        footer: {
            supportBanner: 'ELKE DAG, DE KLOK ROND',
            currentTime: 'ACTUEEL TIJD',
            liveChat: 'Live chatten'
        },
        statusBar: {
            balance: 'Balans',
            credit: 'Credit',
            equity: 'Eigen vermogen',
            margin: 'Marge',
            marginLevel: 'Onderhoudsmarge',
            freeMargin: 'Vrij Marge',
            pnl: 'PNL',
            profitTotal: 'Winst totaal'
        },
        accountModal: {
            dateRegistered: 'Datum Geregistreerd',
            userId: 'Gebruiker ID',
            dashboard: 'Dashboard',
            personalData: 'Persoonlijke gegevens',
            deposit: 'Storting',
            withdrawFunds: 'Geld opnemen',
            savings: 'Spaargeld',
            settings: 'Instellingen',
            logout: 'Uitloggen'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            BT_ACCOUNT: 'Account',
            BT_BANK_NAME: 'Bank Name',
            BT_BENEFICIARY: 'Beneficiary',
            BT_ADDRESS: 'Beneficiary Address',
            BT_SWIFT: 'Swift',
            BT_IBAN: 'IBAN',
            BT_REFERENCE_CODE : 'Bankreferentiecode',
            BT_INN: 'INN',
            BT_KPP: 'Correspondent account',
            BT_CORRESPONDING: '',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Storting doen',
            practiceAccount: 'praktijk account',
            realAccount: 'Echte account',
            noWithdrawals: 'Geen opnames',
            easyWithdrawals: 'Gemakkelijke opnames',
            allAssets: 'Alle middelen beschikbaar',
            fullFledged: 'Full-ledged platform',
            fillUpTo: 'Fill Up To',
            freeReplenishment: 'Gratis aanvulling',
            topUp: 'Waardeer je account op',
            minimumAmount: 'Minimale bedrag',
            canSwitch: 'U kunt op elk moment tussen uw accounts schakelen'
        },
        gridsModal: {
            chartGrids: 'GRAFIEKRASTERS',
            chart1: '1 grafiek',
            chart2: '2 grafieken',
            chart3: '3 grafieken',
            chart4: '4 grafieken',
        },
        noConnectionModal: {
            connectionLost: 'Verbinding met de server is verbroken',
            retryNow: 'Probeer het nu opnieuw',
        },
        loginModal: {
            loginToTradeRoom: 'Inloggen op Traderoom',
            email: 'E-mailadres',
            emailAndId: 'E-mailadres / ID-nummer',
            enterEmail: 'Vul je e-mailadres in',
            twoFactor: '2FA-code (indien ingeschakeld)',
            twoFactorAuth: 'Tweefactorauthenticatie',
            password: 'Wachtwoord',
            yourPassword: 'Uw wachtwoord',
            signIn: 'Log in',
            dontHaveAccount: 'Heb je geen account?',
            restore: 'Herstellen',
            fullName: 'Voor-en achternaam',
            havePromo: 'Ik heb een promotiecode',
            promo: 'Promotiecode',
            login: 'Login',
            haveAccount: 'Heb je al een account?',
            hide: 'Zich verstoppen',
            goTo: 'Ga naar terug'
        },
        newPasswordModal: {
            setNewPassword: 'Nieuw wachtwoord instellen',
            newPassword: 'Nieuw wachtwoord',
            confirmNewPassword: 'Bevestig nieuw wachtwoord',
            setButton: 'Set'
        },
        registerModal: {
            createNewAccount: 'Maak een nieuw account aan',
            email: 'E-mailadres',
            firstName: 'Voornaam',
            lastName: 'Achternaam',
            country: 'Land',
            phone: 'Telefoon',
            password: 'Wachtwoord',
            createAccount: 'Maak account',
            currency: 'Valuta'
        },
        forgotPasswordModal: {
            forgotPassword: 'Wachtwoord vergeten?',
            submitEmail: 'Dien een e-mail in die is gebruikt voor registratie, controleer uw inbox en volg de instructies',
            submit: 'Indienen'
        },
        notifications: {
            error: 'Fout',
            success: 'Succes',
            deposit: 'Storting',
            withdrawal: 'Opname',
            depositApproved: 'Aanbetaling is goedgekeurd',
            depositDeclined: 'Aanbetaling is geweigerd',
            withdrawalApproved: 'Opname goedgekeurd',
            withdrawalDeclined: 'Opname geweigerd',
            withdrawalCancelled: 'De opname is geannuleerd'
        },
        markets: {
            forex: 'Valuta',
            stocks: 'Aandelen',
            commodities: 'Grondstoffen',
            indices: 'Indexen',
            crypto: 'Crypto',
            metals: 'Metalen',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'Kopen',
            sell: 'Verkopen',
            volume: 'Volume',
            entryPrice: 'Toegangs prijs',
            takeProfit: 'Winst nemen',
            stopLoss: 'Stop-Loss',
            maxPosition: 'Maximale positie',
            calculate: 'Berekenen',
            calculationsFor: 'Berekeningen voor',
            leverage: 'Hefboom',
            requiredMargin: 'Vereiste marge',
            profitFromTP: 'Profiteer van TP',
            lossFromSL: 'Verlies van SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Storting succesvol',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Storting mislukt',
            text: 'Uw storting is niet verwerkt.',
        },
        widgetMarketWatch: {
            marketWatch: 'Markt bekijken',
            search: 'Zoeken...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volume',
            margin: 'Margen',
            active: 'Actief',
            pending: 'Laden',
            activeOrders: 'Open posities',
            portfolio: 'Portefeuille',
            allPositions: 'Alle posities',
            noPositionsLine1: 'Je hebt geen open',
            noPositionsLine2: 'posities nog',
            show: 'Tonen',
            more: 'meer',
            less: 'minder',
            purchaseTime: 'Aankooptijd',
            closePosition: 'Positie sluiten',
            priceOpen: 'Aankoopprijs',
            priceSl: 'Stop Loss-prijs',
            priceTp: 'Winstprijs nemen',
            type: 'Positie Richting',
            pnl: 'Winst/verlies',
            cancelOrder: 'Annuleer bestelling',
            orderType: 'Order Type',
            cancelled: 'Geannuleerd',
            tradingHistory: 'Geschiedenis',
            noHistoryLine1: 'Je hebt er geen',
            noHistoryLine2: 'nog gesloten deals',
            sortByTime: 'Sorteer op tijd',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Economische kalender'
        },
        widgetMarketNews: {
            marketNews: 'Marktnieuws'
        },
        ordersPane: {
            ordersActive: 'Open posities',
            ordersHistory: 'Handel geschiedenis',
            id: 'ID',
            symbol: 'Symbool',
            type: 'Type',
            volume: 'Volume',
            openPrice: 'Open prijs',
            openTime: 'Open tijd',
            closePrice: 'Sluit prijs',
            closeTime: 'Sluitingstijd',
            sl: 'SL',
            tp: 'TP',
            price: 'Prijs',
            pnl: 'PnL',
            actions: 'Acties',
            edit: 'Bewerken',
            close: 'Sluiten',
            commission: 'Commissie',
            swap: 'Swap',
            swapLong: 'Ruil lang',
            swapShort: 'Wissel kort'
        },
        assetInfoModal: {
            assetInfo: 'Activa-info',
            tradingConditions: 'Handelsvoorwaarden',
            information: 'Informatie',
            bid: 'Bod',
            ask: 'Vragen',
            sessionChange: 'Sessie wijzigen',
            tradeNow: 'Nu handelen',
            opens: 'Opent',
            closes: 'Sluit',
            at: 'bij',
            open247: 'Open 24/7',
            today: 'Vandaag',
            tomorrow: 'Morgen',
            sunday: 'Zondag',
            monday: 'Maandag',
            tuesday: 'Dinsdag',
            wednesday: 'Woensdag',
            thursday: 'Donderdag',
            friday: 'Vrijdag',
            saturday: 'Zaterdag',
            contractSpecification: 'Contractspecificatie',
            symbol: 'Symbool',
            name: 'Naam',
            market: 'Markt',
            baseCurrency: 'Basisvaluta',
            digits: 'Cijfers',
            lotSize: 'Kavelgrootte',
            lotStep: 'Lot stap',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Hefboom',
            commission: 'Commissie',
            swapLong: 'Ruil lang',
            swapShort: 'Wissel kort',
            schedule: 'Schema',
            weekday: 'Weekdag',
            tradingTime: 'Handelstijd',
            closed: 'gesloten',
            sell: 'Verkopen',
            buy: 'Kopen',
            low: 'Laag',
            high: 'Hoog',
            oneHourChange: '1 uur wissel',
            oneDayChange: '1 dag verandering',
            oneWeekChange: '1 week wissel',
            oneMonthChange: '1 maand verandering',
            threeMonthsChange: '3 maanden verandering',
            oneYearChange: '1 jaar verandering',
            loading: 'Bezig met laden...',
        },
        fullScreen: {
            startTrading: 'Begin met handelen',
            deposit: 'Storting',
            dashboard: 'Dashboard',
            personalInfo: 'Persoonlijke informatie',
            withdrawal: 'Opname',
            verification: 'Verificatie',
            accounts: 'Rekeningen',
            liveChat: 'Live chatten',
            savings: 'Spaargeld',
            settings: 'Instellingen',
            logOut: 'Uitloggen',
        },
        fullScreenDeposit: {
            name: 'Cardholder\'s name',
            address: 'Address',
            DOB: 'DOB',
            bankName: 'Bank name',
            additionalInfo: 'Om meer op uw account te storten, neem rechtstreeks contact op met uw accountmanager',
            additionalInfoOnlyBTCpsp: 'Om andere betaalmethoden te openen, gelieve KYC te verstrekken of contact op te nemen met uw accountmanager',
            sofortPspMessage: 'Ons financiële team zal contact met u opnemen om u te helpen een storting te doen',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'STORTING DOEN',
            instant: 'Instant',
            minutes: 'minuten',
            hours: 'uur',
            days: 'dagen',
            amount: 'Bedrag',
            continue: 'Doorgaan met',
            qrCode: 'QR CODE',
            depositAddress: 'BETALINGS ADRES',
            copy: 'Kopiëren',
            last: 'LAATSTE',
            deposits: 'DEPOSITO\'S',
            time: 'Tijd',
            currency: 'Valuta',
            status: 'Toestand',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Even geduld aub. Verbinding maken met betalingsdienstaanbieder...',
            cardPayment: 'Kaart betaling',
            inOrder: 'Om het bedrag te verzenden',
            pleaseProvide: 'geef kaartgegevens op:'
        },
        fullScreenDashboard: {
            totalTaxes: 'Total Taxes',
            totalBalance: 'Eigen Vermogen',
            totalPnl: 'Totaal PNL',
            profitableOrders: 'Winstgevende posties',
            roi: 'ROI',
            activityLog: 'Activiteiten logboek',
            loginFromIp: 'Inloggen vanaf IP',
            tradingResults: 'Handelsresultaten',
            week: 'Week',
            month: 'Maand',
            year: 'Jaar',
            successRate: 'Slaagkans',
            closedWithProfit: 'Gesloten met winst',
            closedWithLoss: 'Gesloten met verlies',
            account: 'Account',
            balance: 'Balans',
            leverage: 'Hefboom',
            credit: 'Credit',
            tradeNow: 'Nu handelen',
            usingCurrentRate: 'met huidige wisselkoers',
            totalDeposits: 'Total Deposits'
        },
        fullScreenPersonal: {
            personalDetails: 'Persoonlijke gegevens',
            profilePhoto: 'Profielfoto',
            firstName: 'Voornaam',
            country: 'Land',
            lastName: 'Achternaam',
            address: 'Adres',
            email: 'E-mail',
            phone: 'Telefoonnummer',
            saveChanges: 'Wijzigingen opslaan',
        },
        fullScreenAvatar: {
            dropFile: 'Zet een bestand in de cirkel hierboven om te uploaden',
            notAllowed: 'Het is niet toegestaan ​​om te publiceren',
            notAllowedLine1: 'Foto\'s van expliciet seksuele of pornografische aard',
            notAllowedLine2: 'Afbeeldingen gericht op het aanzetten tot etnische of rassenhaat of agressie',
            notAllowedLine3: 'Foto\'s met personen jonger dan 18 jaar',
            notAllowedLine4: 'Auteursrechtelijk beschermde foto\'s van derden',
            notAllowedLine5: 'Afbeeldingen groter dan 5 MB en in een ander formaat dan JPG, GIF of PNG',
            requirements: 'Je gezicht moet duidelijk zichtbaar zijn op de foto. Alle door jou geüploade foto\'s en video\'s moeten aan deze eisen voldoen, anders kunnen ze worden verwijderd.'
        },
        fullScreenWithdrawal: {
            withdrawal: 'Opname',
            requestNewWithdrawal: 'EEN NIEUWE OPNAME AANVRAGEN',
            amount: 'Bedrag',
            currentBalance: 'Uw huidige rekeningsaldo is',
            withdrawAll: 'Alles uitlaten betalen',
            requestWithdrawal: 'Uitbetaling aanvragen',
            last: 'LAATSTE',
            withdrawalRequests: 'INTREKKING VERZOEKEN',
            time: 'Tijd',
            currency: 'Valuta',
            info: 'Info',
            status: 'Toestand',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            card: 'Kaart',
            ethereum: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Uitbetalingsdetails',
            address: 'Portemonnee-adres',
            bankAccount: 'Rekeningnummer',
            bankHolder: 'Naam rekeninghouder',
            bankIban: 'IBAN',
            bankSwift: 'Bank SWIFT code',
            sortCode: 'Sorteercode',
            ifscCode: 'IFSC-code',
            bankName: 'Banknaam',
            bankBranch: 'Bankfiliaal',
            cardNumber: 'Kaartnummer',
            cardHolder: 'Naam pashouder',
            incorrectAmountByFreeMargin: 'The amount is incorrect you can withdraw the amount of free margin',
            closeOrders: 'before close all orders'
        },

        fullScreenVerification: {
            dragAndDrop: 'slepen en neerzetten van het document naar dit gebied',
            documentsList: 'LIJST MET GEUPLOAD DOCUMENTEN',
            document: 'Document',
            timeUploaded: 'Tijd geüpload',
            timeProcessed: 'Tijd verwerkt',
            status: 'Toestand',
            types: ['Bewijs van Id', 'Bewijs van Residentie', 'Creditcard voorzijde', 'Creditcard achterzijde', 'Bewijs van identiteitsbewijs achterzijde', 'Selfie','Other'],
            types2: ['Proof of Id (ID CARD, PASSPORT, DRIVER LICENCE)', 'Proof of Residence (Utility bill or Bank statement)', 'Credit Card Front', 'Credit Card Back', 'Proof of Id Back (ID CARD BACK, DRIVER LICENCE BACK)', 'Selfie','Other'],
            upload: 'Uploaden',
            isConfirmed: 'bevestigd',
            uploadAnotherDocument: 'Nog een document uploaden',
            isUnderReview: 'wordt beoordeeld',
            questionnaire: 'Vragenlijst',
            sendAnswers: 'Send Answers',
            progressBarOf: 'van',
            progressBarText: 'Van uw documenten zijn geüpload en bevestigd'
        },
        fullScreenAccounts: {
            manageAccounts: 'Geld overboeken tussen uw rekeningen',
            transferFrom: 'Van account',
            transferTo: 'Verklaren',
            amount: 'Bedrag',
            makeTransfer: 'Overboeking maken',
            yourAccounts: 'Uw rekeningen',
            account: 'Account',
            currency: 'Valuta',
            balance: 'Balans',
            credit: 'Credit',
            makeActive: 'Maak actief',
            active: 'Actief'
        },
        fullScreenSupport: {
            chatOff: 'Chat will be available on working days',
            sendMessage: 'Bericht versturen'
        },
        fullScreenSavings: {
            pct_0: 'Flexibel',
            pct_30: '1 maand vergrendeld',
            pct_90: '1 Kwart vergrendeld',
            pct_180: '6 maanden vergrendeld',
            pct_365: '1 jaar vergrendeld',
            pct_730: '2 jaar vergrendeld',
            savings: 'Besparingen',
            detailedInformation: 'Gedetailleerde informatie',
            pleaseSelect: 'Selecteer een valuta en een periode',
            openSavings: 'Spaargeld openen',
            currentTime: 'Huidige tijd',
            releaseTime: 'Vrijgavetijd',
            currencyChosen: 'Valuta gekozen',
            periodChosen: 'Periode gekozen',
            yourRate: 'Uw tarief',
            yourAnnualRate: 'Uw jaarlijkse tarief',
            yourEarnings: 'Uw verdiensten',
            accountFrom: 'Rekening om geld van over te boeken',
            enterAmount: 'Voer een bedrag in',
            in: 'In',
            openAndInvest: 'Spaarrekening openen en beleggen',
            investment: 'Investering',
            period: 'Periode',
            daysTotal: 'Dagen totaal',
            finalEarnings: 'Eindinkomsten',
            created: 'gemaakt',
            daysElapsed: 'Verstreken dagen',
            days: 'dagen',
            earnings: 'Verdiensten',
            fundsReleasedIn: 'Fondsen vrijgegeven in',
            claim: 'Beweren',
        },
        fullScreenSettings: {
            updatePassword: 'VERNIEUW WACHTWOORD',
            currentPassword: 'Huidig ​​wachtwoord',
            newPassword: 'Nieuw wachtwoord',
            changePassword: 'Verander wachtwoord',
            activationForGoogle: 'Activation code for Google Authenticator',
            activateAndEnter: 'Activeer Authenticator en voer een gegenereerde code in het onderstaande veld in',
            qrCode: 'QR Code',
            activateProtection: 'Activeer 2FA-beveiliging',
            protectionActive: '2FA-beveiliging is actief',
            twoFactorCode: '2FA-Code',
            disableProtection: 'Schakel 2FA-beveiliging uit',
            language: 'Taal',
            dashboardCurrency: 'Dashboardvaluta',
            confirmNewPassword: 'Bevestig nieuw wachtwoord',
        },
        fullScreenReferrals: {
            yourLink: 'JOUW VERWIJSLINK',
            referredFriends: 'VERWEZEN VRIENDEN',
            id: 'ID',
            name: 'Naam',
            registered: 'geregistreerd',
            level: 'Niveau',
            payout: 'Uitbetaling',
            lastPayouts: 'LAATSTE VERWIJZINGSUITBETALINGEN',
            time: 'Tijd',
            amount: 'Bedrag',
            currency: 'Valuta',
            info: 'Info'
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'Mei',
            Jun: 'Jun',
            Jul: 'Jul',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Okt',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Kopen', 'Verkopen', 'Kooplimiet', 'Verkooplimiet', 'Koopstop', 'Verkoopstop'],
        statuses: ['In behandeling', 'Goedgekeurd', 'Geweigerd', 'Bezig met verwerken','In beoordeling'],
        errors: {
            INVALID_FIELDS: 'Please check that the data you have entered is correct',
            NOT_FOUND: 'Verzoek fout',
            FORBIDDEN: 'Toegang geweigerd. Gelieve opnieuw in te loggen',
            SERVER_ERROR: 'Actie: mislukt. Probeer het later opnieuw',
            INVALID_ACCOUNT: 'Sessie verlopen. Gelieve opnieuw in te loggen',
            INVALID_EMAIL: 'Ongeldig e-mailadres',
            INVALID_PHONE: 'Ongeldig telefoonnummer',
            INVALID_COUNTRY: 'Ongeldig land',
            INVALID_LOGIN: 'Ongeldige inloggegevens',
            USER_BLOCKED: 'Account is geblokkeerd. Neem contact op met ondersteuning',
            USER_REGISTERED: 'Gebruiker met ingevoerd e-mailadres of telefoonnummer is al geregistreerd',
            INVALID_USER: 'Gebruiker is niet gevonden',
            REJECTED: 'Bediening is niet toegestaan ​​vanwege platformbeperkingen',
            INVALID_OLD_PASSWORD: 'Oud wachtwoord is ongeldig',
            INVALID_SYMBOL: 'Ongeldig handelssymbool',
            INVALID_SYMBOL_PRECISION: 'Invalid symbol precision',
            INVALID_FRAME: 'Ongeldig kaartframe',
            INVALID_CURRENCY: 'Ongeldige valuta',
            INVALID_PRODUCT: 'Ongeldig handelstype account',
            INSUFFICIENT_FUNDS: 'Onvoldoende middelen',
            INVALID_WITHDRAWAL: 'Opname niet gevonden',
            INVALID_STATUS: 'Ongeldige status',
            INVALID_FILE: 'Ongeldige bestandsextensie of bestand is te groot',
            INVALID_DOCUMENT: 'Document niet gevonden',
            INVALID_NAME: 'Ongeldige naam',
            INVALID_LOT_SIZE: 'Ongeldige partijgrootte',
            INVALID_LOT_STEP: 'Ongeldige partijstap',
            INVALID_MIN_VOLUME: 'Ongeldig minimaal volume',
            INVALID_MAX_VOLUME: 'Ongeldig maximaal volume',
            INVALID_GROUP: 'Ongeldige groep',
            INVALID_SPREAD: 'Ongeldige spreiding',
            REQUEST_TIMEOUT: 'Verzoek time-out. Probeer het later opnieuw',
            INVALID_TYPE: 'Ongeldig ordertype',
            INVALID_VOLUME: 'Ongeldig bestelvolume',
            INVALID_SL: 'Ongeldige stop loss-waarde',
            INVALID_TP: 'Ongeldige winstwaarde',
            INVALID_PRICE: 'Ongeldige bestelprijs',
            INVALID_EXPIRATION: 'Ongeldige vervaldatum van bestelling',
            NOT_ENOUGH_MARGIN: 'Niet genoeg marge',
            INVALID_ORDER: 'Bestelling niet gevonden',
            MARKET_CLOSED: 'Handelen is niet mogelijk. Probeer het opnieuw',
            INVALID_BALANCE_TYPE: 'Ongeldig type saldobewerking',
            INVALID_HASH: 'Ongeldige hash',
            HASH_EXPIRED: 'De link voor het opnieuw instellen van uw wachtwoord is verlopen. Vraag een nieuwe aan',
            INVALID_CODE: 'Ongeldige 2FA-code',
            CHAT_DISABLED: 'Toegang tot de supportchat is gesloten',
            WITHDRAWAL_NOT_ALLOWED: 'Toegang tot de opnames is gesloten',
            TRADING_DISABLED: 'Handelen is uitgeschakeld voor lopende rekening',
            PENDING_DEPOSITS_LIMIT: 'Slechts één lopende storting toegestaan',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Slechts één lopende opname toegestaan',
            LOCAL_DEPOSIT_ONLY_REAL: 'Storting is alleen beschikbaar voor een echt account',
            LOCAL_DEPOSIT_POSITIVE: 'Het aanbetalingsbedrag moet meer dan 0 zijn',
            LOCAL_INVALID_CARD_NUMBER: 'Ongeldig kaart nummer',
            LOCAL_INVALID_CARD_EXPIRATION: 'Ongeldige kaart vervalt',
            LOCAL_INVALID_CVC: 'Ongeldige CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Wachtwoorden komen niet overeen, gelieve opnieuw in te voeren',
            LOCAL_NO_TRADING_ACCOUNTS: 'U heeft geen handelsaccounts beschikbaar. Neem contact op met de ondersteuning',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum opnamebedrag is $',
            LOCAL_INVALID_FILE_TYPE: 'Bestands formaat niet ondersteund. Gelieve alleen JPG, PNG of PDF bij te voegen',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later',
            REGISTRATIONS_BLOCKED: "Bedankt voor uw aanvraag, Een van onze vertegenwoordigers neemt zo snel mogelijk contact met u op",
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland-eilanden',
            AL: 'Albanië',
            DZ: 'Algerije',
            AS: 'Amerikaans Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua En Barbuda',
            AR: 'Argentinië',
            AM: 'Armenië',
            AW: 'Aruba',
            AU: 'Australië',
            AT: 'Oostenrijk',
            AZ: 'Azerbeidzjan',
            BS: 'Bahamas',
            BH: 'Bahrein',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Wit-Rusland',
            BE: 'België',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnië en Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet-eiland',
            BR: 'Brazilië',
            IO: 'Brits Territorium in de Indische Oceaan',
            BN: 'Brunei Darussalam',
            BG: 'Bulgarije',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodja',
            CM: 'Kameroen',
            CA: 'Canada',
            CV: 'Kaapverdië',
            KY: 'Kaaimaneilanden',
            CF: 'Centraal-Afrikaanse Republiek',
            TD: 'Tsjaad',
            CL: 'Chili',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands',
            CO: 'Colombia',
            KM: 'Comoren',
            CG: 'Congo',
            CD: 'Congo, Democratische Republiek',
            CK: 'Cook Islands',
            CR: 'Costa Rica',
            CI: 'Ivoorkust',
            HR: 'Kroatië',
            CU: 'Cuba',
            CY: 'Cyprus',
            CZ: 'Tsjechië',
            DK: 'Denemarken',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominicaanse Republiek',
            EC: 'Ecuador',
            EG: 'Egypte',
            SV: 'El Salvador',
            GQ: 'Equatoriaal-Guinea',
            ER: 'Eritrea',
            EE: 'Estland',
            ET: 'Ethiopië',
            FK: 'Falklandeilanden (Malvinas)',
            FO: 'Faeröer Eilanden',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'Frankrijk',
            GF: 'Frans-Guyana',
            PF: 'Frans-Polynesië',
            TF: 'Franse zuidelijke gebieden',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgië',
            DE: 'Duitsland',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Griekenland',
            GL: 'Groenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haïti',
            HM: 'Heard Island & Mcdonald Islands',
            VA: 'Heilige Stoel (Vaticaanstad)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hongarije',
            IS: 'IJsland',
            IN: 'India',
            ID: 'Indonesië',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Ierland',
            IM: 'Isle Of Man',
            IL: 'Israëll',
            IT: 'Italië',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordanië',
            KZ: 'Kazachstan',
            KE: 'Kenia',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Koeweit',
            KG: 'Kirgizië',
            LA: 'Lao Democratische Volksrepubliek',
            LV: 'Letland',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libische Arabische Jamahiriya',
            LI: 'Liechtenstein',
            LT: 'Litouwen',
            LU: 'Luxembourg',
            MO: 'Macao',
            MK: 'Macedonië',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Maleisië',
            MV: 'Malediven',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshalleilanden',
            MQ: 'Martinique',
            MR: 'Mauritanië',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesië',
            MD: 'Moldavië',
            MC: 'Monaco',
            MN: 'Mongolië',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morokko',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Nederland',
            AN: 'Nederlandse Antillen',
            NC: 'Nieuw-Caledonië',
            NZ: 'Nieuw-Zeeland',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolkeiland',
            MP: 'Noordelijke Marianen',
            NO: 'Noorwegen',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestijns grondgebied, bezet',
            PA: 'Panama',
            PG: 'Papoea-Nieuw-Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filipijnen',
            PN: 'Pitcairn',
            PL: 'Polen',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Roemenië',
            RU: 'Rusland',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts En Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre En Miquelon',
            VC: 'Saint Vincent En Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tomé en Principe',
            SA: 'Saoedi-Arabië',
            SN: 'Senegal',
            RS: 'Servië',
            SC: 'Seychellen',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovakije',
            SI: 'Slovenië',
            SB: 'Salomonseilanden',
            SO: 'Somalië',
            ZA: 'Zuid-Afrika',
            GS: 'Zuid-Georgië en Sandwich-eilanden',
            ES: 'Spanje',
            LK: 'Sri Lanka',
            SD: 'Soedan',
            SR: 'Suriname',
            SJ: 'Svalbard En Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Zweden',
            CH: 'Zwitserland',
            SY: 'Syrische Arabische Republiek',
            TW: 'Taiwan',
            TJ: 'Tadzjikistan',
            TZ: 'Tanzania',
            TH: 'Thailand',
            TL: 'Timor Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad En Tobago',
            TN: 'Tuvalu',
            TR: 'Turkije',
            TM: 'Turkmenistan',
            TC: 'Turks- en Caicoseilanden',
            TV: 'Tuvalu',
            UG: 'Oeganda',
            UA: 'Oekraïne',
            AE: 'Verenigde Arabische Emiraten',
            GB: 'Verenigd Koninkrijk',
            US: 'Verenigde Staten',
            UM: 'Verenigde Staten perifere eilanden',
            UY: 'Uruguay',
            UZ: 'Oezbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Maagdeneilanden, Brits',
            VI: 'Maagdeneilanden, VS',
            WF: 'Wallis En Futuna',
            EH: 'Westelijke Sahara',
            YE: 'Jemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    sa: {
        _name: 'Arabic',
        autoCloseModal: {
            takeProfitStopLoss: 'جني الأرباح وإيقاف الخسارة',
            takeProfit: 'جني الأرباح',
            stopLoss: 'وقف الخسارة',
            updateOrder: 'تحديث الطلب',
        },
        balanceModal: {
            balance: 'تحسين',
            credit: 'حيوي',
            equity: 'صافي الرصيد',
            practice: 'الممارسة',
            real: 'حقيقي',
            account: 'الحساب',
            invested: 'استثمر',
            profit: 'ربح',
            loss: 'خسارة',
            margin: 'الهامش',
            marginLevel: 'مستوى الهامش',
            marginFree: 'الهامش الحر'
        },
        header: {
            cfd: 'العقود مقابل الفروقات (CFD)',
            platform: 'المنصة',
            deposit: 'إيداع'
        },
        sideMenu: {
            marketWatch: 'مراقبة السوق',
            activeOrders: 'أوامر مخصصة',
            tradingHistory: 'تاريخ التداول',
            economicCalendar: 'التقويم الاقتصادي',
            marketNews: 'أخبار السوق'
        },
        closePositionModal: {
            closeOrder: 'إغلاق الصفقة',
            areYouSure: 'هل أنت متأكد من إغلاق الصفقة',
            buy: 'اشترى',
            sell: 'بيع',
            yes: 'نعم',
            no: 'لا',
        },
        chart: {
            addNewChart: 'إضافة رسم بياني جديد',
        },
        symbolsModal: {
            watchlist: 'قائمة المراقبة',
            asset: 'الأصل',

            price: 'السعر',
            changePct: 'تغيير 24 ساعة'
        },
        pendingModal: {
            pending: 'معلق',
            assetPrice: 'سعر الأصول',
            current: 'الحالي',
            revert: 'العودة إلى سعر السوق',
            automatically: 'فتح الصفقة تلقائيًا عندما يصل السعر إلى هذا المستوى'
        },
        orderMenu: {
            volume: 'الحجم',
            lots: 'اللوتات',
            units: 'الوحدات',
            currency: 'في المقابل',
            contractSize: 'حجم العقد',
            position: 'الصفقة ',
            margin: 'الهامش',
            freeMargin: 'الهامش الحر',
            takeProfitStopLoss: 'جني الأرباح وإيقاف الخسارة',
            pending: 'معلق',
            market: 'السوق',
            leverage: 'الرافعة المالية',
            spread: 'هامش السعر',
            notSet: 'غير محدد',
            at: 'عند',
            buy: 'اشترى',
            sell: 'بيع',
        },
        footer: {
            supportBanner: 'كل يوم ، على مدار الساعة',
            currentTime: 'الوقت الحالي',
            liveChat: 'الدردشة الحية'
        },
        statusBar: {
            balance: 'تحسين',
            credit: 'حيوي',
            equity: 'صافي الرصيد',
            margin: 'الهامش',
            marginLevel: 'مستوى الهامش',
            freeMargin: 'الهامش الحر',
            pnl: 'الأرباح والخسائر (PnL)',
            profitTotal: 'PNL مدى الحياة'
        },
        accountModal: {
            dateRegistered: 'تاريخ التسجيل',
            userId: 'معرف المستخدم',
            dashboard: 'المنصة',

            personalData: 'البيانات الشخصية',
            deposit: 'إيداع',
            withdrawFunds: 'سحب الأموال',
            savings: 'مدخرات',
            settings: 'الإعدادات',
            logout: 'تسجيل الخروج'
        },
        depositModal: {
            BT_INVOICE: 'أدخل مبلغ وإنشاء الفاتورة',
            BT_TITLE: 'تفاصيلنا المصرفية',
            BT_ACCOUNT: 'الحساب',
            BT_BANK_NAME: 'اسم البنك',
            BT_BENEFICIARY: 'المستفيد',
            BT_ADDRESS: 'عنوان المستفيد',
            BT_SWIFT: 'سويفت',
            BT_IBAN: 'رقم الحساب بصيغة IBAN',
            BT_REFERENCE_CODE : 'رمز المرجع البنكي',
            TOKEN_AMOUNT: 'المبلغ',
            TOKEN_SUBTOTAL: 'المجموع الفرعي',
            TOKEN_TOTAL: 'الإجمالي',
            TOKEN_DESCRIPTION: 'الوصف',
            TOKEN_PAYMENT_DETAILS: 'تفاصيل الدفع',
            PENDING_DEPOSITS_LIMIT: 'حد الإيداع',
            makeDeposit: 'قم بإيداع',
            practiceAccount: 'الحساب التجريبي',
            realAccount: 'حساب حقيقي',
            noWithdrawals: 'لا توجد عمليات سحب',
            easyWithdrawals: 'عمليات سحب سهلة',
            allAssets: 'جميع الأصول المتاحة',
            fullFledged: 'منصة ذات أركان كاملة',
            fillUpTo: 'التعبئة حتى',
            freeReplenishment: 'تجديد مجاني',
            topUp: 'اشحن حسابك',
            minimumAmount: 'الحد الأقصى',
            canSwitch: 'يمكنك إجراء عمليات بين حساباتك في أي لحظة'
        },
        gridsModal: {
            chartGrids: 'شبكات الرسم البياني',
            chart1: '1 رسم بياني',
            chart2: '2 رسومات بيانية',
            chart3: '3 رسومات بيانية',
            chart4: '4 رسومات بيانية',
        },
        noConnectionModal: {
            connectionLost: 'تم فقد الاتصال بالخادم',
            retryNow: 'إعادة المحاولة الآن',
        },
        loginModal: {
            loginToTradeRoom: 'تسجيل الدخول إلى غرفة التداول',

            email: 'عنوان البريد الإلكتروني',
            emailAndId: 'عنوان البريد الإلكتروني / رقم الهوية',
            enterEmail: 'أدخل البريد الإلكتروني',
            twoFactor: 'رمز المصادقة ذات العاملين 2FA (إذا تم تمكينه)',
            twoFactorAuth: 'المصادقة ذات العاملين',
            password: 'كلمة المرور',
            yourPassword: 'كلمة مرورك',
            signIn: 'تسجيل الدخول',
            dontHaveAccount: 'ليس لديك حساب؟',
            restore: 'استعادة',
            fullName: 'الاسم الكامل',
            havePromo: 'لدي رمز ترويجي',
            promo: 'الرمز الشعيبي',
            login: 'تسجيل الدخول',
            haveAccount: 'هل لديك حساب بالفعل؟',
            hide: 'إخفاء',
            goTo: 'العودة إلى الخلف'
        },
        newPasswordModal: {
            setNewPassword: 'تعيين كلمة مرور جديدة',
            newPassword: 'كلمة مرور جديدة',
            confirmNewPassword: 'تأكيد كلمة المرور الجديدة',
            setButton: 'تعيين'
        },
        registerModal: {
            createNewAccount: 'إنشاء حساب جديد',
            email: 'عنوان البريد الإلكتروني',
            firstName: 'الاسم الأول',
            lastName: 'اسم العائلة',
            country: 'الدولة',
            phone: 'الهاتف',
            password: 'كلمة المرور',
            createAccount: 'إنشاء حساب',
            currency: 'في المقابل'
        },
        forgotPasswordModal: {
            forgotPassword: 'هل نسيت كلمة المرور؟',
            submitEmail: 'الرجاء إدخال بريد إلكتروني مستخدم للتسجيل ، وتحقق من صندوق الوارد الخاص بك واتبع التعليمات',
            submit: 'إرسال'
        },
        notifications: {
            error: 'خطأ',
            success: 'النجاح',
            deposit: 'إيداع',
            withdrawal: 'سحب الاموال',
            depositApproved: 'الموافقة على الإيداع',
            depositDeclined: 'تم رفض الإيداع',
            withdrawalApproved: 'الموافقة على عملية سحب الاموال',
            withdrawalDeclined: 'تم رفض عملية سحب الاموال',
            withdrawalCancelled: 'تم إلغاء السحب'
        },
        markets: {
            forex: 'فوركس',
            stocks: 'الأسهم',
            commodities: 'السلع',
            indices: 'المؤشرات',
            crypto: 'العملات المشفرة',
            metals: 'المعادن',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'اشترى',
            profitCalculator: 'حاسبة الربح',
            sell: 'بيع',
            volume: 'الحجم',
            entryPrice: 'سعر الدخول',
            takeProfit: 'جني الأرباح',
            stopLoss: 'وقف الخسارة',
            maxPosition: 'أقصى حد للصفقة',
            calculate: 'احسب',
            calculationsFor: 'حسابات تخص',
            leverage: 'الرافعة المالية',
            requiredMargin: 'الهامش المطلوب',
            profitFromTP: 'الربح من جني الأرباح',
            lossFromSL: 'خسارة من إيقاف الخسارة',
            roe: 'العائد على حقوق المساهمين',
        },
        depositSuccessModal: {
            title: 'تم الايداع بنجاح',
            text: 'تمت بنجاح عملية الايداع!',
        },
        depositFailModal: {
            title: 'فشل الإيداع',
            text: 'لم تتم عملية الايداع.',
        },
        widgetMarketWatch: {
            marketWatch: 'مراقبة السوق',
            search: 'بحث ...',
        },
        widgetOrders: {
            commission: 'العمولة',
            volume: 'الحجم',
            margin: 'الهامش',
            active: 'نشط',
            pending: 'معلق',
            activeOrders: 'أوامر مخصصة',
            portfolio: 'محفظة',

            allPositions: 'جميع الصفقات',
            noPositionsLine1: 'ليس لديك',
            noPositionsLine2: 'الصفقات حتى الآن',
            show: 'عرض',
            more: 'اكثر',
            less: 'أقل',
            purchaseTime: 'وقت الشراء',
            closePosition: 'إغلاق الصفقة',
            priceOpen: 'سعر الشراء',
            priceSl: 'سعر إيقاف الخسارة',
            priceTp: 'سعر جني الأرباح',
            type: 'إات الصفقة',
            pnl: 'ربح / خسارة',
            cancelOrder: 'إلغاء الصفقة',
            orderType: 'نوع الطلب',
            cancelled: 'ملغاة',
            tradingHistory: 'تاريخ التداول',
            noHistoryLine1: 'أنت لم',
            noHistoryLine2: 'الصفقات المغلقة حتى الآن',
            sortByTime: 'فرز حسب الوقت',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'التقويم الاقتصادي'
        },
        widgetMarketNews: {
            marketNews: 'أخبار السوق'
        },
        ordersPane: {
            ordersActive: 'أوامر مخصصة',
            ordersHistory: 'سجل الأوامر',
            id: 'المعرف',
            symbol: 'الرمز',
            type: 'النوع',
            volume: 'الحجم',
            openPrice: 'سعر الفتح',
            openTime: 'وقت الفتح ',
            closePrice: 'سعر الإغلاق',
            closeTime: 'وقت الإغلاق',
            sl: 'إيقاف الخسارة',
            tp: 'جني الأرباح',
            price: 'السعر',
            pnl: 'الأرباح والخسائر (PnL)',
            actions: 'الإجراءات',
            edit: 'تحرير',
            close: 'إغلاق',
            commission: 'العمولة',
            swap: 'مبادلة',
            swapLong: 'مبادلة شراء',
            swapShort: 'مبادلة بيع'

        },
        assetInfoModal: {
            assetInfo: 'معلومات حول الأصول',
            tradingConditions: 'شروط التداول',
            information: 'المعلومات',
            bid: 'عرض',
            ask: 'طلب',
            sessionChange: 'تغيير الجلسة',
            tradeNow: 'تداول الآن',
            opens: 'يفتح',
            closes: 'يغلق',
            at: 'في',
            open247: 'مفتوح 24/7',
            today: 'اليوم',
            tomorrow: 'غدا',
            sunday: 'الأحد',
            monday: 'الاثنين',
            tuesday: 'الثلاثاء',
            wednesday: 'الأربعاء',
            thursday: 'الخميس',
            friday: 'الجمعة',
            saturday: 'السبت',
            contractSpecification: 'مواصفات العقد',
            symbol: 'الرمز',
            name: 'اسم',
            market: 'السوق',
            baseCurrency: 'الأساسي',
            digits: 'الأرقام',
            lotSize: 'حجم اللوت',
            lotStep: 'خطوة نحو اللوت',
            minVolume: 'الحجم الأدنى',
            maxVolume: 'الحجم الأقصى',
            leverage: 'الرافعة المالية',
            commission: 'العمولة',
            swapLong: 'مبادلة شراء',
            swapShort: 'مبادلة بيع',
            schedule: 'جدول',
            weekday: 'يوم من أيام الأسبوع',
            tradingTime: 'وقت التداول',
            closed: 'مغلق',
            sell: 'بيع',
            buy: 'شراء',
            low: 'منخفض',
            high: 'عالي',
            oneHourChange: 'تغيير ساعة لمدة',
            oneDayChange: 'تغيير يوم واحد',
            oneWeekChange: 'تغيير أسبوع واحد',
            oneMonthChange: 'تغيير شهر واحد',

            threeMonthsChange: 'تغيير 3 أشهر',
            oneYearChange: 'تغيير سنة لمدة واحدة',
            loading: 'جارى التحميل ...',
        },
        fullScreen: {
            startTrading: 'ابدأ التداول',
            deposit: 'إيداع',
            dashboard: 'منصة التداول',
            personalInfo: 'المعلومات الشخصية',
            withdrawal: 'السحب',
            verification: 'التحقق',
            accounts: 'الحسابات',
            liveChat: 'الدردشة الحية',
            savings: 'مدخرات',
            settings: 'الإعدادات',
            logOut: 'تسجيل الخروج',
        },
        fullScreenDeposit: {
            name: 'Cardholder\'s name',
            address: 'Address',
            DOB: 'DOB',
            bankName: 'Bank name',
            additionalInfo: 'لإيداع المزيد في حسابك، يرجى الاتصال بمدير حسابك مباشرةً',
            additionalInfoOnlyBTCpsp: 'لفتح طرق الدفع الأخرى، يرجى تقديم KYC أو الاتصال بمدير الحساب الخاص بك',
            sofortPspMessage: 'فريقنا المالي سيتصل بك لمساعدتك في إيداع المبلغ',
            addWalletAddress: 'لم يتم تعيين المحفظة لطريقة الإيداع هذه. يرجى الاتصال بوكيلك أو فريق الدعم.',
            addressReceipt: 'الرجاء الانتظار حتى يتم تحميل العنوان',
            makeDeposit: 'قم بإيداع',
            instant: 'فوري',
            minutes: 'دقائق',
            hours: 'ساعات',
            days: 'أيام',
            amount: 'المبلغ',
            continue: 'متابعة',
            qrCode: 'كود QR',
            depositAddress: 'عنوان الإيداع',
            copy: 'نسخ',
            last: 'آخر',
            deposits: 'الايداعات',
            time: 'التوقيت',
            currency: 'في المقابل',
            status: 'الحالة',
            info: 'معلومات',
        },
        fullScreenCcExt: {
            pleaseWait: 'يرجى الانتظار. جاري الاتصال بمزود خدمة الدفع ...',
            cardPayment: 'بطاقة الدفع',
            inOrder: 'من أجل إرسال المبلغ',
            pleaseProvide: 'يرجى ادخال تفاصيل البطاقة:'
        },
        fullScreenDashboard: {
            totalTaxes: 'Total Taxes',
            totalBalance: 'ارتفاع إجمالي',
            totalPnl: 'إجمالي الأرباح والخسائر',
            profitableOrders: 'أوامر مربحة',
            roi: 'عائد الاستثمار',

            activityLog: 'سجل النشاط',
            loginFromIp: 'تسجيل الدخول من IP',
            tradingResults: 'نتائج التداول',
            week: 'اسبوع',
            month: 'شهر',
            year: 'سنة',
            successRate: 'معدل النجاح',
            closedWithProfit: 'مغلق بالربح',
            closedWithLoss: 'مغلق بالخسارة',
            account: 'الحساب',
            balance: 'تحسين',
            leverage: 'الرافعة المالية',
            credit: 'حيوي',
            tradeNow: 'تداول الآن',
            usingCurrentRate: 'استخدام سعر الصرف الحالي',
            totalDeposits: 'إجمالي الودائع'
        },
        fullScreenPersonal: {
            personalDetails: 'التفاصيل الشخصية',
            profilePhoto: 'صورة الملف الشخصي',
            firstName: 'الاسم الأول',
            country: 'الدولة',
            lastName: 'اسم العائلة',
            address: 'العنوان',
            email: 'البريد الإلكتروني',
            phone: 'رقم الهاتف',
            saveChanges: 'حفظ التغييرات',
        },
        fullScreenAvatar: {
            dropFile: 'أسقط ملف',
            notAllowed: 'ممنوع النشر',
            notAllowedLine1: 'صور ذات جنسية أو إباحية صريحة',
            notAllowedLine2: 'المضايقة أو العدوان أو الإثني أو العرقي',
            notAllowedLine3: 'صور لأكبر عدد من الأعضاء',
            notAllowedLine4: 'الصور المحمية الطبع والنشر من جهات خارجية',
            notAllowedLine5: 'الصور التي يزيد حجمها عن 5 ميغابايت وبتنسيق غير JPG أو GIF أو PNG',
            requirements: 'أن يكون وجهك ظاهرًا في الصورة بوضوح. يمكن أن تكون صورتها التي يمكن أن تقاطعها من',
            خلال: 'الفيديو.'
        },
        fullScreenWithdrawal: {
            withdrawal: 'سحب',
            requestNewWithdrawal: 'طلب سحب جديد',
            amount: 'المبلغ',
            currentBalance: 'رصيد حسابك الجاري هو',
            withdrawAll: 'سحب كل المبلغ',
            requestWithdrawal: 'طلب سحب',
            last: 'آخر',
            withdrawalRequests: 'طلبات السحب',

            time: 'التوقيت',
            currency: 'في المقابل',
            info: 'معلومات',
            status: 'الحالة',
            bank: 'البنك',
            bitcoin: 'Bitcoin',
            card: 'بطاقة',
            ethereum: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'تفاصيل السحب',
            address: 'عنوان المحفظة',
            bankAccount: 'رقم الحساب',
            bankHolder: 'اسم صاحب الحساب',
            bankIban: 'رقم الحساب بصيغة IBAN',
            bankSwift: 'رمز SWIFT للمصرف',
            sortCode: 'كود التصنيف',
            ifscCode: 'كود ال IFSC',
            bankName: 'اسم البنك',
            bankBranch: 'فرع البنك',
            cardNumber: 'رقم البطاقة',
            cardHolder: 'اسم حامل البطاقة',
            incorrectAmountByFreeMargin: 'The amount is incorrect you can withdraw the amount of free margin',
            closeOrders: 'before close all orders'
        },
        fullScreenVerification: {
            dragAndDrop: 'قم بسحب المستند وإفلاته في هذه المنطقة',
            documentsList: 'قائمة المستندات المحملة',
            document: 'المستند',
            timeUploaded: 'وقت التحميل',
            timeProcessed: 'معالجة الوقت',
            status: 'الحالة',
            types: ['إثبات بطاقة الهوية', 'دليل الإقامة', 'واجهة بطاقة الائتمان', 'ظهر بطاقة الائتمان', 'إثبات ظهر بطاقة الهوية',
                'سيلفي','Other'],
            types2: ['Proof of Id (ID CARD, PASSPORT, DRIVER LICENCE)', 'Proof of Residence (Utility bill or Bank statement)', 'Credit Card Front', 'Credit Card Back', 'Proof of Id Back (ID CARD BACK, DRIVER LICENCE BACK)', 'Selfie','Other'],
            upload: 'تحميل',
            isConfirmed: 'تم التأكيد',
            uploadAnotherDocument: 'تحميل وثيقة أخرى',
            isUnderReview: 'قيد المراجعة',
            questionnaire: 'استبيان',
            sendAnswers: 'إرسال الإرسال',
            progressBarOf: 'من',
            progressBarText: 'تم تحميل مستنداتك وتأكيدها'
        },
        fullScreenAccounts: {
            manageAccounts: 'تحويل الأموال بين حساباتك',
            transferFrom: 'من الحساب',
            transferTo: 'إلى الحساب',
            amount: 'المبلغ',
            makeTransfer: 'إجراء التحويل',
            yourAccounts: 'حساباتك',
            account: 'الحساب',
            currency: 'في المقابل',
            balance: 'تحسين',
            credit: 'حيوي',
            makeActive: 'تنشيط',

            active: 'نشط'
        },
        fullScreenSupport: {
            sendMessage: 'أرسل رسالة'
        },
        fullScreenSavings: {
            pct_0: 'مرنة',
            pct_30: 'مغلق لمدة شهر',
            pct_90: 'مغلق لمدة ٣ أشهر',
            pct_180: 'مغلق لمدة 6 أشهر',
            pct_365: 'مغلق لمدة عام',
            pct_730: 'مغلق لمدة عام',
            savings: 'مدخرات',
            detailedInformation: 'معلومات مفصلة',
            pleaseSelect: 'الرجاء تحديد عملة وعلاقة',
            openSavings: 'فتح المدخرات',
            currentTime: 'الوقت الحالي',
            releaseTime: 'وقت الإصدار',
            currencyChosen: 'من أجل المختارة',
            periodChosen: 'الفترة المختارة',
            yourRate: 'تقييمك',
            yourAnnualRate: 'معدل سنويك',
            yourEarnings: 'أرباحك',
            accountFrom: 'حساب لتحويل الأموال منه',
            enterAmount: 'أدخل المبلغ',
            in: 'في',
            openAndInvest: 'افتح حساب التوفير واستثمر',
            investment: 'الاستثمار',
            period: 'الفترة',
            daysTotal: 'مجموع الأيام',
            finalEarnings: 'الأرباح النهائية',
            created: 'تم الإنشاء',
            daysElapsed: 'الأيام المنقضية',
            days: 'أيام',
            earnings: 'الأرباح',
            fundsReleasedIn: 'تم إصدار الأموال في',
            claim: 'مطالبة',
        },
        fullScreenSettings: {
            updatePassword: 'تحديث كلمة المرور',
            currentPassword: 'كلمة المرور الحالية',
            newPassword: 'كلمة مرور جديدة',
            changePassword: 'تغيير كلمة المرور',
            activationForGoogle: 'رمز التنشيط لـ Google Authenticator',
            activateAndEnter: 'قم بتنشيط Authenticator وأدخل رمزًا تم إنشاؤه في الحقل أدناه',
            qrCode: 'رمز الاستجابة السريعة',
            activateProtection: 'تنشيط حماية 2FA',
            protectionActive: 'حماية 2FA نشطة',
            twoFactorCode: 'رمز 2FA',
            disableProtection: 'تعطيل حماية 2FA',

            language: 'اللغة',
            dashboardCurrency: 'عملة لوحة المعلومات',
            confirmNewPassword: 'تأكيد كلمة المرور الجديدة',
        },
        fullScreenReferrals: {
            yourLink: 'رابط الإحالة الخاص بك',
            referredFriends: 'الأصدقاء المُحالين',
            id: 'رقم الهوية',
            name: 'اسم',
            registered: 'سجل',
            level: 'المستوى',
            payout: 'الدفع',
            lastPayouts: 'مدفوعات الإحالة الأخيرة',
            time: 'التوقيت',
            amount: 'المبلغ',
            currency: 'في المقابل',
            info: 'معلومات'
        },
        months: {
            Jan: 'يناير',
            Feb: 'فبراير',
            Mar: 'مارس',
            Apr: 'أبريل',
            May: 'مايو',
            Jun: 'يونيو',
            Jul: 'يوليو',
            Aug: 'أغسطس',
            Sep: 'سبتمبر',
            Oct: 'أكتوبر',
            Nov: 'نوفمبر',
            Dec: 'ديسمبر'
        },
        orderTypes: ['شراء "،" بيع "،" حد الشراء "،" حد البيع "،" إيقاف الشراء "،" إيقاف البيع'],
        statuses: ['معلق "،" موافق عليه "،" مرفوض "،" قيد المعالجة','قيد المراجعة'],
        errors: {
            INVALID_FIELDS: 'Please check that the data you have entered is correct',
            NOT_FOUND: 'خطأ في الطلب',
            FORBIDDEN: 'الدخول مرفوض. الرجاء إعادة تسجيل الدخول',
            SERVER_ERROR: 'فشل الإجراء. الرجاء المحاولة في وقت لاحق',
            INVALID_ACCOUNT: 'انتهت الجلسة. الرجاء إعادة تسجيل الدخول',
            INVALID_EMAIL: 'عنوان بريد إلكتروني غير صالح',
            INVALID_PHONE: 'رقم الهاتف غير صالح',
            INVALID_COUNTRY: 'دولة غير صحيحة',
            INVALID_LOGIN: 'بيانات اعتماد تسجيل الدخول غير صالحة',
            USER_BLOCKED: 'الحساب محظور. الرجاء الاتصال بالدعم',
            USER_REGISTERED: 'المستخدم الذي لديه بريد إلكتروني أو رقم هاتف مسجل بالفعل',
            INVALID_USER: 'المستخدم غير موجود',
            REJECTED: 'التشغيل غير مسموح به بسبب قيود المنصة',
            INVALID_OLD_PASSWORD: 'كلمة المرور القديمة غير صالحة',

            INVALID_SYMBOL: 'رمز تداول غير صالح',
            INVALID_SYMBOL_PRECISION: 'دقة رمز غير صالحة',
            INVALID_FRAME: 'إطار الرسم البياني غير صالح',
            INVALID_CURRENCY: 'عملة غير صالحة',
            INVALID_PRODUCT: 'نوع تداول الحساب غير صالح',
            INSUFFICIENT_FUNDS: 'أموال غير كافية',
            INVALID_WITHDRAWAL: 'السحب غير موجود',
            INVALID_STATUS: 'حالة غير صالحة',
            INVALID_FILE: 'امتداد الملف غير صالح أو الملف كبير جدًا',
            INVALID_DOCUMENT: 'لم يتم العثور على المستند',
            INVALID_NAME: 'اسم غير صالح',
            INVALID_LOT_SIZE: 'حجم اللوت غير صالح',
            INVALID_LOT_STEP: 'خطوة غير صالحة نحو اللوت',
            INVALID_MIN_VOLUME: 'حجم أدنى غير صالح',
            INVALID_MAX_VOLUME: 'حجم الأقصى غير صالح',
            INVALID_GROUP: 'مجموعة غير صالحة',
            INVALID_SPREAD: 'هامش سعر غير صالح',
            REQUEST_TIMEOUT: 'انتهت مهلة الطلب. يرجى المحاولة مرة أخرى في وقت لاحق',
            INVALID_TYPE: 'نوع الطلب غير صالح',
            INVALID_VOLUME: 'حجم الطلب غير صالح',
            INVALID_SL: 'قيمة الخسارة غير صالحة',
            INVALID_TP: 'قيمة جني الأرباح غير صالحة',
            INVALID_PRICE: 'سعر الطلب غير صالح',
            INVALID_EXPIRATION: 'انتهاء صلاحية أمر غير صالح',
            NOT_ENOUGH_MARGIN: 'هامش غير كافٍ',
            INVALID_ORDER: 'يتم العثور على الطلب',
            MARKET_CLOSED: 'التداول غير متاح. حاول مرة أخرى',
            INVALID_BALANCE_TYPE: 'نوع غير صالح لعملية الرصيد',
            INVALID_HASH: 'تجزئة غير صالحة',
            HASH_EXPIRED: 'رابط إعادة تعيين كلمة المرور انتهت صلاحيته. الرجاء طلب واحد جديد',
            INVALID_CODE: 'رمز 2FA غير صالح ',
            CHAT_DISABLED: 'تم إغلاق الوصول إلى دردشة الدعم',
            WITHDRAWAL_NOT_ALLOWED: 'تم إغلاق الوصول إلى عمليات السحب',
            TRADING_DISABLED: 'التداول معطل للحساب الجاري',
            PENDING_DEPOSITS_LIMIT: 'تجاوز حد الإيداعات',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'يسمح بطلب سحب معلق واحد فقط',
            LOCAL_DEPOSIT_ONLY_REAL: 'الإيداع متاح فقط للحساب الحقيقي',
            LOCAL_DEPOSIT_POSITIVE: 'يجب أن يكون مبلغ الإيداع أكثر من 0',
            LOCAL_INVALID_CARD_NUMBER: 'رقم البطاقة غير صالح',
            LOCAL_INVALID_CARD_EXPIRATION: 'انتهاء صلاحية البطاقة غير صالحة',
            LOCAL_INVALID_CVC: 'غير صالح CVV / CVC رمز',
            LOCAL_PASSWORDS_NOT_MATCH: 'كلمات المرور غير متطابقة ، الرجاء إعادة',
            LOCAL_NO_TRADING_ACCOUNTS: 'ليس لديك حسابات متاحة. يرجى الاتصال بالدعم',
            LOCAL_MIN_DEPOSIT: ' $ الحد الأدنى للإيداع هو ',
            LOCAL_MIN_WITHDRAWAL: '$ الحد للسحب هو ',
            LOCAL_INVALID_FILE_TYPE: 'فقط JPG أو PNG أو PDF نوع الملف غير مدعوم. يرجى إرفاق ملفات ',
            LOCAL_MIN_STAKING: 'الحد الأقصى للإيداع للإيداع ',

            FLOOD_REJECTED: ' أنت ترسل الرسائل. الرجاء المحاولة في وقت لاحق',
            REGISTRATIONS_BLOCKED: "شكرًا لاستفسارك, سيتواصل معك أحد ممثلينا في أقرب وقت ممكن",
        },
        countries: {
            AF: 'أفغانستان',
            AX: 'جزر آلاند',
            AL: 'ألبانيا',
            DZ: 'الجزائر',
            AS: 'ساموا الأمريكية',
            AD: 'أندورا',
            AO: 'أنغولا',
            AI: 'أنغيلا',
            AQ: 'أنتاركتيكا',
            AG: 'أنتيغوا وبربودا',
            AR: 'الأرجنتين',
            AM: 'أرمينيا',
            AW: 'أروبا',
            AU: 'فيكتوريا',
            AT: 'النمسا',
            AZ: 'أذربيجان',
            BS: 'جزر البهاما',
            BH: 'البحرين',
            BD: 'بنغلاديش',
            BB: 'بربادوس',
            BY: 'بيلاروسيا',
            BE: 'بلجيكا',
            BZ: 'بليز',
            BJ: 'بنين',
            BM: 'برمودا',
            BT: 'بوتان',
            BO: 'بوليفيا',
            BA: 'البوسنة والهرسك',
            BW: 'بوتسوانا',
            BV: 'جزيرة بوفيت',
            BR: 'البرازيل',
            IO: 'إقليم المحيط الهندي البريطاني',
            BN: 'بروناي دار السلام',
            BG: 'بلغاريا',
            BF: 'بوركينا فاسو',
            BI: 'بوروندي',
            KH: 'كمبوديا',
            CM: 'الكاميرون',
            CA: 'كندا',
            CV: 'الرأس الأخضر',
            KY: 'جزر كايمان',
            CF: 'جمهورية أفريقيا الوسطى',
            TD: 'تشاد',
            CL: 'تشيلي',
            CN: 'الصين',

            CX: 'جزيرة الكريسماس',
            CC: 'جزر كوكوس (كيلينغ)',
            CO: 'كولومبيا',
            KM: 'جزر القمر',
            CG: 'الكونغو',
            CD: 'جمهورية الكونغو الديمقراطية',
            CK: 'جزر كوك',
            CR: 'كوستاريكا',
            CI: 'كوت دي إيفوار',
            HR: 'كرواتيا',
            CU: 'كوبا',
            CY: 'قبرص',
            CZ: 'جمهورية التشيك',
            DK: 'الدنمارك',
            DJ: 'جيبوتي',
            DM: 'دومينيكا',
            DO: 'جمهورية الدومينيكان',
            EC: 'الإكوادور',
            EG: 'مصر',
            SV: 'السلفادور',
            GQ: 'غينيا الاستوائية',
            ER: 'إريتريا',
            EE: 'إستونيا',
            ET: 'إثيوبيا',
            FK: '(مالفيناس)',
            FO: 'جزر فارو',
            FJ: 'فيجي',
            FI: 'فنلندا',
            FR: 'فرنسا',
            GF: 'جويانا الفرنسية',
            PF: 'بولينيزيا الفرنسية',
            TF: 'الأقاليم الجنوبية الفرنسية',
            GA: 'الجابون',
            GM: 'غامبيا',
            GE: 'جورجيا',
            DE: 'ألمانيا',
            GH: 'غانا',
            GI: 'جبل طارق',
            GR: 'اليونان',
            GL: 'بالألمانية',
            GD: 'غرينادا',
            GP: 'جوادلوب',
            GU: 'غوام',
            GT: 'غواتيمالا',
            GG: 'غيرنسي',
            GN: 'غينيا',
            GW: 'غينيا بيساو',
            GY: 'غيانا',

            HT: 'هايتي',
            HM: 'جزيرة هيرد وجزر ماكدونالد',
            VA: 'الكرسي الرسولي (دولة الفاتيكان)',
            HN: 'هندوراس',
            HK: 'المزيد من اللغات',
            HU: 'المجر',
            IS: 'آيسلندا',
            IN: 'الهند',
            ID: 'إندونيسيا',
            IR: 'إيران',
            IQ: 'العراق',
            IE: 'أيرلندا',
            IM: 'جزيرة مان',
            IL: 'إسرائيل',
            IT: 'إيطاليا',
            JM: 'جامايكا',
            JP: 'اليابان',
            JE: 'صغير',
            JO: 'الأردن',
            KZ: 'كازاخستان',
            KE: 'كينيا',
            KI: 'كيريباتي',
            KR: 'كوريا',
            KW: 'الكويت',
            KG: 'قيرغيزستان',
            LA: 'جمهورية لاو الديمقراطية الشعبية',
            LV: 'لاتفيا',
            LB: 'لبنان',
            LS: 'ليسوتو',
            LR: 'ليبيريا',
            LY: 'الجماهيرية العربية الليبية',
            LI: 'ليختنشتاين',
            LT: 'ليتوانيا',
            LU: 'لوكسمبورغ',
            MO: 'ماكاو',
            MK: 'مقدونيا',
            MG: 'مدغشقر',
            MW: 'ملاوي',
            MY: 'ماليزيا',
            MV: 'جزر المالديف',
            ML: 'مالي',
            MT: 'مالطا',
            MH: 'جزر مارشال',
            MQ: 'مارتينيك',
            MR: 'موريتانيا',
            MU: 'موريشيوس',
            YT: 'مايوت',
            MX: 'المكسيك',

            FM: 'ميكرونيزيا',
            MD: 'مولدوفا',
            MC: 'موناكو',
            MN: 'منغوليا',
            ME: 'الجبل الأسود',
            MS: 'مونتسيرات',
            MA: 'المغرب',
            MZ: 'موزمبيق',
            MM: 'ميانمار',
            NA: 'ناميبيا',
            NR: 'ناورو',
            NP: 'نيبال',
            NL: 'هولندا',
            AN: 'جزر الأنتيل دول',
            NC: 'كاليدونيا الجديدة',
            NZ: 'نيوزيلندا',
            NI: 'نيكاراغوا',
            NE: 'النيجر',
            NG: 'نيجيريا',
            NU: 'نيوي',
            NF: 'جزيرة نورفولك',
            MP: 'جزر ماريانا الشمالية',
            NO: 'النرويج',
            OM: 'عمان',
            PK: 'باكستان',
            PW: 'بالاو',
            PS: 'الأراضي الفلسطينية المحتلة',
            PA: 'بنما',
            PG: 'بابوا غينيا الجديدة',
            PY: 'باراغواي',
            PE: 'بيرو',
            PH: 'الفلبين',
            PN: 'بيتكيرن',
            PL: 'بولندا',
            PT: 'البرتغال',
            PR: 'بورتوريكو',
            QA: 'قطر',
            RE: 'ريونيون',
            RO: 'رومانيا',
            RU: 'روسيا',
            RW: 'رواندا',
            BL: 'سانت بارتيليمي',
            SH: 'سانت هيلانة',
            KN: 'سانت كيتس ونيفيس',
            LC: 'سانت لوسيا',
            MF: 'سانت مارتن',
            PM: 'سان بيير وميكلون',
            VC: 'سانت فنسنت وجزر غرينادين',

            WS: 'ساموا',
            SM: 'سان مارينو',
            ST: 'ساو تومي وبرينسيبي',
            SA: 'المملكة العربية السعودية',
            SN: 'السنغال',
            RS: 'صربيا',
            SC: 'سيشل',
            SL: 'سيراليون',
            SG: 'سنغافورة',
            SK: 'سلوفاكيا',
            SI: 'سلوفينيا',
            SB: 'جزر سليمان',
            SO: 'الصومال',
            ZA: 'جنوب إفريقيا',
            GS: 'جورجيا الجنوبية وجزيرة ساندويتش.',
            ES: 'إسبانيا',
            LK: 'سريلانكا',
            SD: 'السودان',
            SR: 'سورينام',
            SJ: 'سفالبارد وجان ماين',
            SZ: 'سوازيلاند',
            SE: 'السويد',
            CH: 'سويسرا',
            SY: 'الجمهورية العربية السورية',
            TW: 'أنفه',
            TJ: 'طاجيكستان',
            TZ: 'تنزانيا',
            TH: 'تايلاند',
            TL: 'تيمور الشرقية',
            TG: 'توغو',
            TK: 'توكيلاو',
            TO: 'تونجا',
            TT: 'ترينيداد وتوباغو',
            TN: 'تونس',
            TR: 'تركيا',
            TM: 'تركمانستان',
            TC: 'جزر تركس وكايكوس',
            TV: 'توفالو',
            UG: 'أوغندا',
            UA: 'أوكرانيا',
            AE: 'الإمارات العربية المتحدة',
            GB: 'المملكة المتحدة',
            US: 'الولايات المتحدة',
            UM: 'جزر الولايات المتحدة النائية',
            UY: 'أوروغواي',
            UZ: 'أوزبكستان',
            VU: 'فانواتو',
            VE: 'فنزويلا',

            VN: 'فيتنام',
            VG: 'جزر فيرجن البريطانية',
            VI: 'جزر فيرجن الأمريكية',
            WF: 'واليس وفوتون',
            EH: 'الصحراء الغربية',
            YE: 'اليمن',
            ZM: 'زامبيا',
            ZW: 'زيمبابوي'
        },
    },
    it: {
        _name: 'Italiano',
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            takeProfit: 'Profitto',
            stopLoss: 'Stop Loss',
            updateOrder: `Aggiornamento dell'ordine`,
        },
        balanceModal: {
            balance: 'Equilibrio',
            credit: 'Credito',
            equity: 'Patrimonio netto',
            practice: 'PRATICA',
            real: 'REALE',
            account: 'CONTO',
            invested: 'Investito',
            profit: 'Profitto',
            loss: 'Loss',
            margin: 'Margine',
            marginLevel: 'Livello del margine',
            marginFree: 'Margine libero'
        },
        header: {
            cfd: 'CFD',
            platform: 'Piattaforma',
            deposit: 'Deposito'
        },
        sideMenu: {
            marketWatch: 'Osservatorio del mercato',
            activeOrders: 'Ordini attivi',
            tradingHistory: 'Storia del trading',
            economicCalendar: 'Calendario economico',
            marketNews: 'Notizie di mercato'
        },
        closePositionModal: {
            closeOrder: 'Posizione di chiusura',
            areYouSure: 'Siete sicuri di chiudere la posizione',
            buy: 'Acquista',
            sell: 'Vendere',
            yes: 'Sì',
            no: 'No',
        },
        chart: {
            addNewChart: 'Aggiungi nuovo grafico',
        },
        symbolsModal: {
            watchlist: 'Watchlist',
            asset: 'Patrimonio',
            price: 'Prezzo',
            changePct: 'Cambiamento 24 ore su 24'
        },
        pendingModal: {
            pending: 'In attesa',
            assetPrice: `Prezzo dell'attività`,
            current: 'Attuale',
            revert: 'Ritorno al prezzo di mercato',
            automatically: 'La posizione verrà aperta automaticamente quando il prezzo raggiungerà questo livello.'
        },
        orderMenu: {
            volume: 'Volume',
            lots: 'lotti',
            units: 'unità',
            currency: 'valuta',
            contractSize: 'Dimensione del contratto',
            position: 'Posizione',
            margin: 'Margine',
            freeMargin: 'Margine libero',
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            pending: 'In attesa',
            market: 'Mercato',
            leverage: 'Leva',
            spread: 'Diffusione',
            notSet: 'Non impostato',
            at: 'a',
            buy: 'acquistare',
            sell: 'vendere',
        },
        footer: {
            supportBanner: 'OGNI GIORNO, 24 ORE SU 24',
            currentTime: 'TEMPO CORRENTE',
            liveChat: 'Chat in diretta'
        },
        statusBar: {
            balance: 'Equilibrio',
            credit: 'Credito',
            equity: 'Patrimonio netto',
            margin: 'Margine',
            marginLevel: 'Livello di margine',
            freeMargin: 'Margine libero',
            pnl: 'PnL',
            profitTotal: 'Durata della vita PnL'
        },
        accountModal: {
            dateRegistered: 'Data di registrazione',
            userId: 'ID utente',
            dashboard: 'Cruscotto',
            personalData: 'Dati personali',
            deposit: 'Deposito',
            withdrawFunds: 'Prelievo di fondi',
            savings: 'Risparmio',
            settings: 'Impostazioni',
            logout: 'Disconnessione'
        },
        depositModal: {
            BT_INVOICE: `Inserire l'importo e generare la fattura`,
            BT_TITLE: 'I nostri dati bancari',
            BT_ACCOUNT: 'Conto',
            BT_BANK_NAME: 'Nome della banca',
            BT_BENEFICIARY: 'Beneficiario',
            BT_ADDRESS: 'Indirizzo del beneficiario',
            BT_SWIFT: 'Swift',
            BT_IBAN: 'IBAN',
            BT_REFERENCE_CODE : 'Codice di riferimento bancario',
            BT_INN: 'INN',
            BT_KPP: 'Conto Corrispondente',
            BT_CORRESPONDING: '',
            TOKEN_AMOUNT: 'Importo',
            TOKEN_SUBTOTAL: 'Subtotale',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Descrizione',
            TOKEN_PAYMENT_DETAILS: 'Dettagli sul pagamento',
            PENDING_DEPOSITS_LIMIT: 'Limite di deposito',
            makeDeposit: 'Effettuare un deposito',
            practiceAccount: 'Conto pratica',
            realAccount: 'Conto reale',
            noWithdrawals: 'Nessun prelievo',
            easyWithdrawals: 'Prelievi facili',
            allAssets: 'Tutte le attività disponibili',
            fullFledged: 'Piattaforma completa',
            fillUpTo: 'Riempire fino a',
            freeReplenishment: 'Rifornimento gratuito',
            topUp: 'Ricaricare il conto',
            minimumAmount: 'Importo minimo',
            canSwitch: `Potete passare da un conto all'altro in qualsiasi momento`
        },
        gridsModal: {
            chartGrids: 'GRIGLIE DEL GRAFO',
            chart1: '1 chart',
            chart2: '2 charts',
            chart3: '3 charts',
            chart4: '4 charts',
        },
        noConnectionModal: {
            connectionLost: 'La connessione con il server è stata persa',
            retryNow: 'Riprova ora',
        },
        loginModal: {
            loginToTradeRoom: 'Accesso a Traderoom',
            email: 'Email address',
            emailAndId: 'Email address / Numero ID',
            enterEmail: 'Inserisci il tuo indirizzo e-mail',
            twoFactor: 'Codice 2FA (se abilitato)',
            twoFactorAuth: 'Autenticazione a due fattori',
            password: 'Password',
            yourPassword: 'la vostra password',
            signIn: 'Accedi',
            dontHaveAccount: 'Non avete un account?',
            restore: 'Ripristino',
            fullName: 'Nome completo',
            havePromo: 'Ho un codice promozionale',
            promo: 'Codice promozionale',
            login: 'Accesso',
            haveAccount: 'Avete già un account?',
            hide: 'HiNasconderede',
            goTo: 'Torna indietro'
        },
        newPasswordModal: {
            setNewPassword: 'Imposta nuova password',
            newPassword: 'Nuova password',
            confirmNewPassword: 'Confermare la nuova password',
            setButton: 'Set'
        },
        registerModal: {
            createNewAccount: 'Creare un nuovo account',
            email: 'Indirizzo e-mail',
            firstName: 'Nome',
            lastName: 'Cognome',
            country: 'Paese',
            phone: 'Telefono',
            password: 'Password',
            createAccount: 'Creare un account',
            currency: 'Valuta'
        },
        forgotPasswordModal: {
            forgotPassword: 'Password dimenticata?',
            submitEmail: `Per favore, inviate l'e-mail utilizzata per la registrazione, controllate la vostra casella di posta e seguite le istruzioni fornite.`,
            submit: 'Submit'
        },
        notifications: {
            error: 'Errore',
            success: 'Il successo',
            deposit: 'Deposito',
            withdrawal: 'Ritiro',
            depositApproved: 'Il deposito è stato approvato',
            depositDeclined: 'Il deposito è stato rifiutato',
            withdrawalApproved: 'Il ritiro è stato approvato',
            withdrawalDeclined: 'Il ritiro è stato rifiutato',
            withdrawalCancelled: 'Il ritiro è stato annullato',
        },
        markets: {
            forex: 'Forex',
            stocks: 'Stocks',
            commodities: 'Materie prime',
            indices: 'Indici',
            crypto: 'Crypto',
            metals: 'Metals',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'ACQUISTO',
            profitCalculator: 'Calcolatore di profitto',
            sell: 'VENDERE',
            volume: 'Volume',
            entryPrice: 'Prezzo di ingresso',
            takeProfit: 'Profitto',
            stopLoss: 'Stop Loss',
            maxPosition: 'Posizione massima',
            calculate: 'Calcolare',
            calculationsFor: 'Calcoli per',
            leverage: 'Leva',
            requiredMargin: 'Margine richiesto',
            profitFromTP: 'Profito da TP',
            lossFromSL: 'Loss da SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposito successo',
            text: 'Il vostro deposito è stato elaborato con successo!',
        },
        depositFailModal: {
            title: 'Deposito failare',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Osservatorio del mercato',
            search: 'Ricerca...',
        },
        widgetOrders: {
            commission: 'Commissione',
            volume: 'Volume',
            margin: 'Margin',
            active: 'Attivo',
            pending: 'In attesa',
            activeOrders: 'Ordini attivi',
            portfolio: 'Portafoglio',
            allPositions: 'Tutte le posizioni',
            noPositionsLine1: `Non avete un'apertura`,
            noPositionsLine2: 'posizioni ancora',
            show: 'Mostra',
            more: 'di più',
            less: 'meno',
            purchaseTime: 'Tempo di acquisto',
            closePosition: 'Posizione di chiusura',
            priceOpen: 'Prezzo di acquisto',
            priceSl: 'Prezzo dello stop loss',
            priceTp: 'Prezzo di take profit',
            type: 'Posizione Direzione',
            pnl: 'Profitto/Loss',
            cancelOrder: `Annullamento dell'ordine`,
            orderType: 'Tipo di ordine',
            cancelled: 'Annullato',
            tradingHistory: 'Storia del trading',
            noHistoryLine1: 'Non avete nessun',
            noHistoryLine2: 'accordi già conclusi',
            sortByTime: 'Ordina per tempo',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Calendario economico'
        },
        widgetMarketNews: {
            marketNews: 'Notizie di mercato'
        },
        ordersPane: {
            ordersActive: 'Ordini attivi',
            ordersHistory: 'Storia degli ordini',
            id: 'ID',
            symbol: 'Simbolo',
            type: 'Tipo',
            volume: 'Volume',
            openPrice: 'Prezzo aperto',
            openTime: 'Tempo aperto',
            closePrice: 'Prezzo di chiusura',
            closeTime: 'Tempo di chiusura',
            sl: 'SL',
            tp: 'TP',
            price: 'Prezzo',
            pnl: 'PnL',
            actions: 'ActioAzionins',
            edit: 'Modifica',
            close: 'Chiudere',
            commission: 'Commissione',
            swap: 'Scambio',
            swapLong: 'Scambio lungo',
            swapShort: 'Scambio breve'
        },
        assetInfoModal: {
            assetInfo: 'Info sulle attività',
            tradingConditions: 'Condizioni di trading',
            information: 'Informazioni',
            bid: 'Offerta',
            ask: 'Chiedere',
            sessionChange: 'Cambio di sessione',
            tradeNow: 'Commercio ora',
            opens: 'Apre',
            closes: 'ClosChiudees',
            at: 'a',
            open247: 'Aperto 24/7',
            today: 'oggi',
            tomorrow: 'domani',
            sunday: 'Domenica',
            monday: 'Lunedì',
            tuesday: 'Martedì',
            wednesday: 'Mercoledì',
            thursday: 'Giovedì',
            friday: 'Venerdì',
            saturday: 'Sabato',
            contractSpecification: 'Specifiche del contratto',
            symbol: 'Simbolo',
            name: 'Nome',
            market: 'Mercato',
            baseCurrency: 'Valuta di base',
            digits: 'Cifre',
            lotSize: 'Dimensione del lotto',
            lotStep: 'Lotto Passo',
            minVolume: 'Volume minimo',
            maxVolume: 'Volume massimo',
            leverage: 'Leva',
            commission: 'Commissione',
            swapLong: 'Scambio lungo',
            swapShort: 'Scambio breve',
            schedule: 'Programma',
            weekday: 'Giorno feriale',
            tradingTime: 'Tempo di negoziazione',
            closed: 'chiuso',
            sell: 'Vendere',
            buy: 'Acquista',
            low: 'Basso',
            high: 'Alto',
            oneHourChange: 'Cambio di 1 ora',
            oneDayChange: 'Cambio di 1 giorno',
            oneWeekChange: 'Cambio di 1 settimana',
            oneMonthChange: 'Variazione di 1 mese',
            threeMonthsChange: 'Variazione di 3 mesi',
            oneYearChange: 'Variazione di 1 anno',
            loading: 'Caricamento...',
        },
        fullScreen: {
            startTrading: 'Avviare il trading',
            deposit: 'Deposito',
            dashboard: 'Cruscotto',
            personalInfo: 'Informazioni personali',
            withdrawal: 'Ritiro',
            verification: 'Verifica',
            accounts: 'Conti',
            liveChat: 'Chat in diretta',
            savings: 'Risparmio',
            settings: 'Impostazioni',
            logOut: 'Disconnettersi',
        },
        fullScreenDeposit: {
            addressReceipt: `Attendere il caricamento dell'indirizzo`,
            makeDeposit: 'FARE UN DEPOSITO',
            instant: 'Immediato',
            minutes: 'minuti',
            hours: 'ore',
            days: 'giorni',
            amount: 'Capitale',
            continue: 'Continua',
            qrCode: 'QR CODE',
            depositAddress: 'INDIRIZZO DI DEPOSITO',
            copy: 'Copia',
            last: 'ULTIMO',
            deposits: 'DEPOSITI',
            time: 'Tempo',
            currency: 'Valuta',
            status: 'Stato',
            info: 'Info',
            name: 'Nome del titolare della carta',
            address: 'Indirizzo',
            DOB: 'DOB',
            bankName: 'Nome della banca',
            additionalInfo: 'Per effettuare ulteriori versamenti sul vostro conto, contattate direttamente il vostro account manager.',
            additionalInfoOnlyBTCpsp: 'Per aprire altri metodi di pagamento si prega di fornire il KYC o di contattare il proprio account manager.',
            sofortPspMessage: 'Il nostro team finanziario vi contatterà per aiutarvi a effettuare il deposito.'
        },
        fullScreenCcExt: {
            pleaseWait: 'Attendere prego. Connessione al fornitore di servizi di pagamento...',
            cardPayment: 'Pagamento con carta',
            inOrder: `Per inviare l'importo`,
            pleaseProvide: 'fornire i dati della carta :'
        },
        fullScreenDashboard: {
            totalTaxes: 'Totale imposte',
            totalBalance: 'Saldo totale',
            totalPnl: 'Totale PNL',
            profitableOrders: 'Ordini redditizi',
            roi: 'ROI',
            activityLog: 'Registro delle attività',
            loginFromIp: 'Accesso da IP',
            tradingResults: 'Risultati del trading',
            week: 'Settimana',
            month: 'Mese',
            year: 'Anno',
            successRate: 'Tasso di successo',
            closedWithProfit: 'Chiuso con profitto',
            closedWithLoss: 'Chiuso con perdita',
            account: 'Conto',
            balance: 'Equilibrio',
            leverage: 'Leva',
            credit: 'Credito',
            tradeNow: 'Commercio ora',
            usingCurrentRate: 'al tasso di cambio corrente',
            totalDeposits: 'Totale depositi'
        },
        fullScreenPersonal: {
            personalDetails: 'Dettagli personali',
            profilePhoto: 'Foto del profilo',
            firstName: 'Nome',
            country: 'Paese',
            lastName: 'Cognome',
            address: 'Indirizzo',
            email: 'E-mail',
            phone: 'Numero di telefono',
            saveChanges: 'Salva le modifiche',
        },
        fullScreenAvatar: {
            dropFile: 'Lasciate un file nel cerchio qui sopra per caricarlo',
            notAllowed: 'Non è consentito pubblicare',
            notAllowedLine1: 'Foto di natura esplicitamente sessuale o pornografica',
            notAllowedLine2: `Immagini che incitano all'odio o all'aggressione etnica o razziale.`,
            notAllowedLine3: 'Foto che coinvolgono persone di età inferiore ai 18 anni',
            notAllowedLine4: 'Foto protette da copyright di terzi',
            notAllowedLine5: 'Immagini di dimensioni superiori a 5 MB e in un formato diverso da JPG, GIF o PNG',
            requirements: `Il vostro volto deve essere chiaramente visibile nella foto. Tutte le foto e i video caricati dall'utente devono essere conformi a questi requisiti, altrimenti potranno essere rimossi.`
        },
        fullScreenWithdrawal: {
            withdrawal: 'Ritiro',
            requestNewWithdrawal: 'RICHIEDERE UN NUOVO PRELIEVO',
            amount: 'Importo',
            currentBalance: 'Il saldo del conto corrente è',
            withdrawAll: 'Ritirare tutti',
            requestWithdrawal: 'Richiesta di ritiro',
            last: 'ULTIMO',
            withdrawalRequests: 'RICHIESTE DI RITIRO',
            time: 'Tempo',
            currency: 'Valuta',
            info: 'Info',
            status: 'Stato',
            bank: 'Banca',
            bitcoin: 'Bitcoin',
            card: 'carta',
            ethereum: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Dettagli sul ritiro',
            address: 'Indirizzo del portafoglio',
            bankAccount: 'Numero di conto',
            bankHolder: 'Nome del titolare del conto',
            bankIban: 'IBAN',
            bankSwift: 'Codice SWIFT della banca',
            sortCode: 'Codice di ordinamento',
            ifscCode: 'IFSC Codice',
            bankName: 'Nome della banca',
            bankBranch: 'Filiale della banca',
            cardNumber: 'Numero di carta',
            cardHolder: 'Nome del titolare della carta',
            incorrectAmountByFreeMargin: `L'importo non è corretto, è possibile ritirare l'importo del margine libero.`,
            closeOrders: 'prima di chiudere tutti gli ordini'
        },
        fullScreenVerification: {
            dragAndDrop: `trascinare e rilasciare il documento in quest'area`,
            documentsList: 'ELENCO DEI DOCUMENTI CARICATI',
            document: 'Documento',
            timeUploaded: 'Tempo caricato',
            timeProcessed: 'Tempo elaborato',
            status: 'Stato',
            types: ["Prova d'identità", "Prova di residenza", "Carta di credito anteriore", "Carta di credito posteriore", "Prova d'identità posteriore", "Selfie",'Other'],
            types2: ['Proof of Id (ID CARD, PASSPORT, DRIVER LICENCE)', 'Proof of Residence (Utility bill or Bank statement)', 'Credit Card Front', 'Credit Card Back', 'Proof of Id Back (ID CARD BACK, DRIVER LICENCE BACK)', 'Selfie','Other'],
            upload: 'Caricare',
            isConfirmed: 'è confermato',
            uploadAnotherDocument: 'Caricare un altro documento',
            isUnderReview: 'è in fase di revisione',
            questionnaire: 'Questionario',
            sendAnswers: 'Invia le risposte',
            progressBarOf: 'di',
            progressBarText: 'i documenti sono stati caricati e confermati'
        },
        fullScreenAccounts: {
            manageAccounts: 'Trasferire fondi tra i vostri conti',
            transferFrom: 'Dal conto',
            transferTo: 'Per rendere conto',
            amount: 'Importo',
            makeTransfer: 'Effettuare il trasferimento',
            yourAccounts: 'I vostri conti',
            account: 'Conto',
            currency: 'Valuta',
            balance: 'Equilibrio',
            credit: 'Credito',
            makeActive: 'Rendere attivi',
            active: 'Attivo'
        },
        fullScreenSupport: {
            chatOff: 'La chat sarà disponibile nei giorni lavorativi',
            sendMessage: 'Invia il messaggio'
        },
        fullScreenSavings: {
            pct_0: 'Flessibile',
            pct_30: 'Bloccato 1 mese',
            pct_90: 'Bloccato 1 trimestre',
            pct_180: 'Bloccato 6 mesi',
            pct_365: 'Bloccato 1 anno',
            pct_730: 'Bloccato 2 anni',
            savings: 'Risparmio',
            detailedInformation: 'Informazioni dettagliate',
            pleaseSelect: 'Selezionare una valuta e un periodo',
            openSavings: 'Risparmio aperto',
            currentTime: 'Ora corrente',
            releaseTime: 'Tempo di rilascio',
            currencyChosen: 'Valuta scelta',
            periodChosen: 'Periodo scelto',
            yourRate: 'La vostra tariffa',
            yourAnnualRate: 'Il tuo tasso annuale',
            yourEarnings: 'I vostri guadagni',
            accountFrom: 'Conto da cui trasferire i fondi',
            enterAmount: `Inserire l'importo`,
            in: 'In',
            openAndInvest: 'Aprire un conto di risparmio e investire',
            investment: 'Investimenti',
            period: 'Periodo',
            daysTotal: 'Totale giorni',
            finalEarnings: 'Guadagni finali',
            created: 'Creato',
            daysElapsed: 'Giorni trascorsi',
            days: 'giorni',
            earnings: 'Guadagni',
            fundsReleasedIn: 'Fondi rilasciati in',
            claim: 'Rivendicazione',
        },
        fullScreenSettings: {
            updatePassword: 'AGGIORNARE LA PASSWORD',
            currentPassword: 'Password corrente',
            newPassword: 'Nuova Password',
            changePassword: 'Cambia password',
            activationForGoogle: 'Codice di attivazione per Google Authenticator',
            activateAndEnter: `Attivate l'Autenticatore e inserite un codice generato nel campo sottostante`,
            qrCode: 'QR Code',
            activateProtection: 'Attivare la protezione 2FA',
            protectionActive: 'La protezione 2FA è attiva',
            twoFactorCode: '2FA codice',
            disableProtection: 'Disattivare la protezione 2FA',
            language: 'LanLinguaguage',
            dashboardCurrency: 'Cruscotto Valuta',
            confirmNewPassword: 'Confermare la nuova password',
        },
        fullScreenReferrals: {
            yourLink: 'IL VOSTRO LINK DI RIFERIMENTO',
            referredFriends: 'AMICI SEGNALATI',
            id: 'ID',
            name: 'Nome',
            registered: 'Registro',
            level: 'Livello',
            payout: 'Pagamento',
            lastPayouts: 'ULTIMI PAGAMENTI DI REFERRAL',
            time: 'Time',
            amount: 'Importo',
            currency: 'Valuta',
            info: 'Info'
        },
        months: {
            Jan: 'Gennaio',
            Feb: 'Febbraio',
            Mar: 'Marzo',
            Apr: 'Apri',
            May: 'Maggio',
            Jun: 'Giugno',
            Jul: 'Luglio',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Ottobre',
            Nov: 'Novembre',
            Dec: 'Decembre'
        },
        orderTypes: ['Acquista', 'Vendi', 'Limite di acquisto', 'Limite di vendita', 'Stop di acquisto', 'Stop di vendita'],
        statuses: ["In attesa", "Approvato", "Rifiutato", "In elaborazione",'In revisione'],
        errors: {
            INVALID_FIELDS: 'Verificare che i dati inseriti siano corretti.',
            NOT_FOUND: 'Errore di richiesta',
            FORBIDDEN: 'Accesso negato. Effettuare nuovamente il login',
            SERVER_ERROR: 'Azione fallita. Riprovare più tardi',
            INVALID_ACCOUNT: 'Sessione scaduta. Effettuare nuovamente il login',
            INVALID_EMAIL: 'Indirizzo e-mail non valido',
            INVALID_PHONE: 'Numero di telefono non valido',
            INVALID_COUNTRY: 'Paese non valido',
            INVALID_LOGIN: 'Credenziali di accesso non valide',
            USER_BLOCKED: `L'account è bloccato. Contattare l'assistenza`,
            USER_REGISTERED: `L'utente con l'e-mail o il numero di telefono inserito è già registrato`,
            INVALID_USER: `L'utente non è stato trovato`,
            REJECTED: 'Il funzionamento non è consentito a causa delle restrizioni della piattaforma',
            INVALID_OLD_PASSWORD: 'La vecchia password non è valida',
            INVALID_SYMBOL: 'Simbolo di trading non valido',
            INVALID_SYMBOL_PRECISION: 'Precisione del simbolo non valida',
            INVALID_FRAME: 'Cornice del grafico non valida',
            INVALID_CURRENCY: 'Valuta non valida',
            INVALID_PRODUCT: 'Tipo di trading del conto non valido',
            INSUFFICIENT_FUNDS: 'Fondi insufficienti',
            INVALID_WITHDRAWAL: 'Il prelievo non è stato trovato',
            INVALID_STATUS: 'Stato non valido',
            INVALID_FILE: 'Estensione file non valida o file troppo grande',
            INVALID_DOCUMENT: 'Il documento non è stato trovato',
            INVALID_NAME: 'Nome non valido',
            INVALID_LOT_SIZE: 'Dimensione del lotto non valida',
            INVALID_LOT_STEP: 'Fase del lotto non valida',
            INVALID_MIN_VOLUME: 'Volume minimo non valido',
            INVALID_MAX_VOLUME: 'Volume massimo non valido',
            INVALID_GROUP: 'Gruppo non valido',
            INVALID_SPREAD: 'Spread non valido',
            REQUEST_TIMEOUT: 'La richiesta è scaduta. Riprovare più tardi',
            INVALID_TYPE: 'Tipo di ordine non valido',
            INVALID_VOLUME: `Volume dell'ordine non valido`,
            INVALID_SL: 'Valore di stop loss non valido',
            INVALID_TP: 'Valore di take profit non valido',
            INVALID_PRICE: `Prezzo dell'ordine non valido`,
            INVALID_EXPIRATION: `Scadenza dell'ordine non valida`,
            NOT_ENOUGH_MARGIN: 'Margine insufficiente',
            INVALID_ORDER: `L'ordine non è stato trovato`,
            MARKET_CLOSED: 'Il trading non è disponibile. Si prega di riprovare',
            INVALID_BALANCE_TYPE: 'Tipo di operazione di bilanciamento non valido',
            INVALID_HASH: 'Hash non valido',
            HASH_EXPIRED: 'Il link per la reimpostazione della password è scaduto. Richiedetene una nuova',
            INVALID_CODE: 'Codice 2FA non valido',
            CHAT_DISABLED: `L'accesso alla chat di supporto è stato chiuso`,
            WITHDRAWAL_NOT_ALLOWED: `L'accesso ai prelievi è stato chiuso`,
            TRADING_DISABLED: 'Il trading è disabilitato per il conto corrente',
            PENDING_DEPOSITS_LIMIT: 'Superamento del limite dei depositi',
            CLOSE_OPEN_ORDERS: 'Chiudere gli ordini aperti',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'È consentito un solo prelievo in sospeso',
            LOCAL_DEPOSIT_ONLY_REAL: 'Il deposito è disponibile solo per il conto reale',
            LOCAL_DEPOSIT_POSITIVE: `L'importo del deposito deve essere superiore a 0`,
            LOCAL_INVALID_CARD_NUMBER: 'Numero di carta non valido',
            LOCAL_INVALID_CARD_EXPIRATION: 'Scadenza della carta non valida',
            LOCAL_INVALID_CVC: 'Codice CVV/CVC non valido',
            LOCAL_PASSWORDS_NOT_MATCH: 'Le password non corrispondono, inserire nuovamente',
            LOCAL_NO_TRADING_ACCOUNTS: `Non sono disponibili conti di trading. Si prega di contattare l'assistenza`,
            LOCAL_MIN_DEPOSIT: 'Il deposito minimo è di €',
            LOCAL_MIN_WITHDRAWAL: `L'importo minimo di prelievo è di €`,
            LOCAL_INVALID_FILE_TYPE: 'Formato di file non supportato. Allegare solo JPG, PNG o PDF.',
            LOCAL_MIN_STAKING: 'Il deposito minimo per questa valuta è ',
            FLOOD_REJECTED: `L'invio di messaggi è troppo frequente. Riprovare più tardi`,
            REGISTRATIONS_BLOCKED: "Grazie per la tua richiesta, Uno dei nostri rappresentanti ti contatterà al più presto",
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland Islands',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'American Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua And Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belgium',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnia And Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet Island',
            BR: 'Brazil',
            IO: 'British Indian Ocean Territory',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodia',
            CM: 'Cameroon',
            CA: 'Canada',
            CV: 'Cape Verde',
            KY: 'Cayman Islands',
            CF: 'Central African Republic',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands',
            CO: 'Colombia',
            KM: 'Comoros',
            CG: 'Congo',
            CD: 'Congo, Democratic Republic',
            CK: 'Cook Islands',
            CR: 'Costa Rica',
            CI: 'Cote D\'Ivoire',
            HR: 'Croatia',
            CU: 'Cuba',
            CY: 'Cyprus',
            CZ: 'Czech Republic',
            DK: 'Denmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominican Republic',
            EC: 'Ecuador',
            EG: 'Egypt',
            SV: 'El Salvador',
            GQ: 'Equatorial Guinea',
            ER: 'Eritrea',
            EE: 'Estonia',
            ET: 'Ethiopia',
            FK: 'Falkland Islands (Malvinas)',
            FO: 'Faroe Islands',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'France',
            GF: 'French Guiana',
            PF: 'French Polynesia',
            TF: 'French Southern Territories',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Germany',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Greece',
            GL: 'Greenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island & Mcdonald Islands',
            VA: 'Holy See (Vatican City State)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungary',
            IS: 'Iceland',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Iran',
            IQ: 'Iraq',
            IE: 'Ireland',
            IM: 'Isle Of Man',
            IL: 'Israel',
            IT: 'Italy',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwait',
            KG: 'Kyrgyzstan',
            LA: 'Lao People\'s Democratic Republic',
            LV: 'Latvia',
            LB: 'Lebanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libyan Arab Jamahiriya',
            LI: 'Liechtenstein',
            LT: 'Lithuania',
            LU: 'Luxembourg',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldives',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshall Islands',
            MQ: 'Martinique',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesia',
            MD: 'Moldova',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morocco',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Netherlands',
            AN: 'Netherlands Antilles',
            NC: 'New Caledonia',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk Island',
            MP: 'Northern Mariana Islands',
            NO: 'Norway',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestinian Territory, Occupied',
            PA: 'Panama',
            PG: 'Papua New Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippines',
            PN: 'Pitcairn',
            PL: 'Poland',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Romania',
            RU: 'Russia',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts And Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre And Miquelon',
            VC: 'Saint Vincent And Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome And Principe',
            SA: 'Saudi Arabia',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovakia',
            SI: 'Slovenia',
            SB: 'Solomon Islands',
            SO: 'Somalia',
            ZA: 'South Africa',
            GS: 'South Georgia And Sandwich Isl.',
            ES: 'Spain',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Suriname',
            SJ: 'Svalbard And Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Sweden',
            CH: 'Switzerland',
            SY: 'Syrian Arab Republic',
            TW: 'Taiwan',
            TJ: 'Tajikistan',
            TZ: 'Tanzania',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad And Tobago',
            TN: 'Tunisia',
            TR: 'Turkey',
            TM: 'Turkmenistan',
            TC: 'Turks And Caicos Islands',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'United Arab Emirates',
            GB: 'United Kingdom',
            US: 'United States',
            UM: 'United States Outlying Islands',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Virgin Islands, British',
            VI: 'Virgin Islands, U.S.',
            WF: 'Wallis And Futuna',
            EH: 'Western Sahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
}

export default langs
