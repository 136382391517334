import Vue from "vue"

import {
    ACCOUNT,
    BALANCE_UPDATE, NOTIFICATION, STAKING_ACCOUNT_CLAIMED, STAKING_CURRENCY, USER
} from '@/store/mutations.type'
import {
    LOGOUT, STAKING_CLAIM, STAKING_MAKE
} from '@/store/actions.type'
import RestService from '@/common/rest.service'
import {BRAND} from "@/common/config";

const state = {
    stakingCurrencies: {},
    stakingAccounts: {},
    savingExist: false
}

const getters = {
    stakingCurrencies() {
        return state.stakingCurrencies
    },

    stakingAccounts() {
        return state.stakingAccounts
    },

    savingExist(){
        return state.savingExist
    }
}

const actions = {
    [STAKING_MAKE](context, data) {
        RestService.post('/staking', data)
            .then(account => {
                this.commit(ACCOUNT, account)

                this.commit(NOTIFICATION, 'New saving account has been created')
            })
    },

    [STAKING_CLAIM](context, data) {
        RestService.post('/staking/account/'+data.account_id+'/claim')
            .then(() => {
                this.commit(STAKING_ACCOUNT_CLAIMED, {
                    id: data.account_id
                })

                this.commit(NOTIFICATION, 'Saving account has been closed')
            })
    }
}

const mutations = {
    [USER]() {
        Vue.set(state, 'savingAccountExist', false)
        RestService.get('/staking/currencies')
            .then(currencies => {
                if (currencies.length > 0){
                    Vue.set(state, 'savingExist', true)
                }

                BRAND === 'standexcapital' ? currencies.reverse() : ''
                for(let n in currencies) {
                    this.commit(STAKING_CURRENCY, currencies[n])
                }
            })
    },

    [ACCOUNT](context, account) {
        if (account.product !== 'staking') {
            return
        }

        Vue.set(state.stakingAccounts, account.id, account)
    },

    [STAKING_ACCOUNT_CLAIMED](context, account) {
        Vue.delete(state.stakingAccounts, account.id)
    },

    [STAKING_CURRENCY](context, data) {
        Vue.set(state.stakingCurrencies, data.currency, data)
    },

    [BALANCE_UPDATE](context, balance) {
        if (balance.product !== 'staking' || state.accounts?.[balance.account_id] === undefined) {
            return
        }

        state.stakingAccounts[balance.account_id].balance += balance.balanceChange
        state.stakingAccounts[balance.account_id].credit += balance.creditChange
    },

    [LOGOUT]() {
        Vue.set(state, 'stakingAccounts',  {})
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
