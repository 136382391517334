import Vue from 'vue'
import {SUPPORT_READ, SUPPORT_SEND} from '@/store/actions.type'
import RestService from "@/common/rest.service";
import {DATA_REFRESH, LOGOUT, NOTIFICATION_SUPPORT, SUPPORT_MESSAGE, SUPPORT_UNREAD, USER} from '@/store/mutations.type'
import {COMPANY_NAME} from "@/common/config";

const state = {
    supportMessages: [],
    supportUnread: false
}

const getters = {
    supportMessages() {
        return state.supportMessages
    },

    supportUnread() {
        return state.supportUnread
    }
}

const actions = {
    [SUPPORT_SEND](context, text) {
        RestService.post('/support/messages', {text})
    },

    [SUPPORT_READ]() {
        RestService.post('/support/messages/ack', {})

        this.commit(SUPPORT_UNREAD, false)
    }
}

const mutations = {
    [USER]() {
        RestService.get('/support/messages')
            .then(messages => {
                for(let n in messages) {
                    this.commit(SUPPORT_MESSAGE, messages[n])
                }
            })
    },

    [SUPPORT_MESSAGE](context, message) {
        if (!message.time_read && message.manager_id > 0 && this.getters.user.enable_chat) {
            this.commit(NOTIFICATION_SUPPORT, 
                {
                    title:`New message from ${COMPANY_NAME}`,
                    text: message.text,
                }
            )
            this.commit(SUPPORT_UNREAD, true)
        }

        state.supportMessages.push(message)
    },

    [DATA_REFRESH]() {
        state.supportMessages = []

        RestService.get('/support/messages')
            .then(messages => {
                for(let n in messages) {
                    this.commit(SUPPORT_MESSAGE, messages[n])
                }
            })
    },

    [SUPPORT_UNREAD](context, val) {
        state.supportUnread = val
    },

    [LOGOUT]() {
        Vue.set(state, 'supportMessages', [])
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}