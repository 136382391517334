import Vue from "vue"

import {
    QUOTE, QUOTES,
    SYMBOL, SYMBOL_REMOVE,
    CONVERSION,
    SESSIONS_STATE
} from '@/store/mutations.type'
import {APP_INIT} from "./actions.type"
import RestService from '@/common/rest.service'
import {checkSession} from "@/common/helpers"

const state = {
    symbols: {},
    symbolGroups: [],
    conversions: {},
    sessions: {},
    sessionsState: {}
}

const getters = {
    symbols() {
        return state.symbols
    },
    symbolGroups() {
        return state.symbolGroups
    },
    conversions() {
        return state.conversions
    },
    sessions() {
        return state.sessions
    },
    sessionsState() {
        return state.sessionsState
    }
}

const actions = {
    [APP_INIT]() {
        RestService.get('/conversions')
            .then(conversions => {
                for(let symbol in conversions) {
                    this.commit(CONVERSION, {
                        symbol,
                        value: conversions[symbol]
                    })
                }
            })

        // const check = () => {
        //     let now = Math.floor(Date.now() / 1000)
        //     for (let n in state.symbols) {
        //         let lastQuote = state.symbols[n].lastQuoteStamp || 0
        //         if (now - lastQuote > 60 * 5) {
        //             // console.log(n + 'false')
        //             this.commit(SESSIONS_STATE, {
        //                 symbol: n,
        //                 res: false
        //             })
        //         } else {
        //             // console.log(n + 'true')
        //             this.commit(SESSIONS_STATE, {
        //                 symbol: n,
        //                 res: true
        //             })
        //         }
        //     }
        // }

        // setTimeout(check, 1000 * 10)

        // setInterval(check, 1000 * 60)
    }
}

const mutations = {
    [SESSIONS_STATE](context, data) {
        Vue.set(state.sessionsState, data.symbol, data.res)
    },

    [SYMBOL](context, symbol) {
        Vue.set(state.symbols, symbol.symbol, symbol)

        if (state.symbolGroups.indexOf(symbol.market) === -1) {
            state.symbolGroups.push(symbol.market)
        }

        Vue.set(state.sessions, symbol.symbol, symbol.sessions)
        Vue.set(state.sessionsState, symbol.symbol, checkSession(symbol.sessions))
    },

    [QUOTES](context, quotes) {
        for(let i in quotes) {
            let quote = quotes[i]

            if (state.symbols[quote.symbol] === undefined) {
                return
            }

            if (state.symbols[quote.symbol].quote) {
                let dirBid = quote.bid < state.symbols[quote.symbol].quote.bid ? 'down' : (quote.bid > state.symbols[quote.symbol].quote.bid ? 'up' : ''),
                    dirAsk = quote.ask < state.symbols[quote.symbol].quote.ask ? 'down' : (quote.ask > state.symbols[quote.symbol].quote.ask ? 'up' : '')

                state.symbols[quote.symbol].quote = quote
                state.symbols[quote.symbol].quote.dirBid = dirBid
                state.symbols[quote.symbol].quote.dirAsk = dirAsk
            }

            // state.symbols[quote.symbol].lastQuoteStamp = Math.floor(Date.now() / 1000)
            // this.commit(SESSIONS_STATE, {
            //     symbol: quote.symbol,
            //     res: true
            // })
        }
    },

    [QUOTE](context, quote) {
        if (state.symbols[quote.symbol] === undefined) {
            return
        }

        let dirBid = quote.bid < state.symbols[quote.symbol].quote.bid ? 'down' : (quote.bid > state.symbols[quote.symbol].quote.bid ? 'up' : ''),
            dirAsk = quote.ask < state.symbols[quote.symbol].quote.ask ? 'down' : (quote.ask > state.symbols[quote.symbol].quote.ask ? 'up' : '')

        state.symbols[quote.symbol].quote = quote
        state.symbols[quote.symbol].quote.dirBid = dirBid
        state.symbols[quote.symbol].quote.dirAsk = dirAsk

        // state.symbols[quote.symbol].lastQuoteStamp = Math.floor(Date.now() / 1000)
        // this.commit(SESSIONS_STATE, {
        //     symbol: quote.symbol,
        //     res: true
        // })
    },

    [SYMBOL_REMOVE](context, symbol) {
        Vue.delete(state.symbols, symbol)
    },

    [CONVERSION](context, data) {
        Vue.set(state.conversions, data.symbol, data.value)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
