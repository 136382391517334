import Vue from "vue";
import RestService from "@/common/rest.service";
import {
    APP_READY, AVATAR_UPLOAD, DEPOSIT_REQUEST, DEPOSITS_GET, ATTACHMENT_UPLOAD, DOCUMENT_UPLOAD, DOCUMENTS_GET,
    LOGIN,
    LOGOUT, OTP_ACTIVATE, OTP_CREATE, OTP_DISABLE,
    PASSWORD_CHANGE, PASSWORD_RESET, PASSWORD_RESTORE,
    PERSONAL_UPDATE,
    REGISTER, TRANSFER_REQUEST, WITHDRAWAL_REQUEST, WITHDRAWALS_GET, REFRESH_USER,
    DASHBOARD_BALANCE, GENERATE_INVOICE, DOWNLOAD_INVOICE, UPLOAD_CONFIRMATION, CANCEL_WITHDRAWAL, PRE_LOGIN, GET_TAXES
} from "@/store/actions.type";
import {
    USER, USER_PERSONAL,
    MANAGERS,
    WITHDRAWALS,
    DOCUMENTS,
    DEPOSITS,
    NOTIFICATION,
    TOKEN,
    DEPOSIT_ADDRESS,
    OTP_ENABLE_DATA,
    MODAL,
    TRANSACTION,
    NOTIFICATION_TRANSACTION, DEPOSIT_TRANSACTION, SYMBOL, PSP_LIST, QUESTIONS, OTHER_PSPS,
    DASHBOARD_LOGIN, DASHBOARD_STATS, DASHBOARD_BALANCE_CHART, CFD_ORDER, MODAL_FULLSCREEN,
    BANK_DEPOSIT, ACTIVE_CURRENCY, SET_TAXES, CURRENCIES
} from '@/store/mutations.type'
import {timeToString, localStorage} from "@/common/helpers";
import {BRAND, MODULE_FTD, INITIAL_DASHBOARD, REST_ENDPOINT, SHOW_TAXES} from "@/common/config"

const state = {
    loggedIn: false,
    token: null,
    user: {
        // firstname: ' ',
        // lastname: ' ',
        fullname: ' ',
        avatar: '',
        created: 0,
        otp: 0,
        enable_withdrawal: 1,
        enable_chat: 1,
        min_deposit: 0
    },
    managers: [],
    deposits: [],
    withdrawals: [],
    documents: [],
    depositAddress: '',
    depositTransaction: {
        id: 0,
        status: 0
    },
    otpEnableData: {
        qr: '',
        base32: ''
    },
    pspList: [],
    questions: [],
    otherPsps: [],
    dashboardData: {
        logins: {
            count: 0,
            rows: {
                browser: "",
                id: 0,
                ip: "",
                name: "",
                time: 0,
                user_id: 0
            }
        },
        stats: {
            amount: 1,
            cnt_profit: 0,
            profit: 0,
            deposits: 0
        },
        balance_chart: [],
    },
    bankDeposit: {
        id: '',
        memo: '',
        misc: ''
    },
    activeCurrency: "",
    isoCountry: [{
        country: '',
        iso: ''
    }],
    isActiveOtp: false,
    taxes: []
    // sessionsState: {}
}

const getters = {
    loggedIn() {
        return state.loggedIn
    },

    user() {
        return state.user
    },

    managers() {
        return state.managers
    },

    deposits() {
        return state.deposits
    },

    withdrawals() {
        return state.withdrawals
    },

    documents() {
        return state.documents
    },

    depositAddress() {
        return state.depositAddress
    },

    depositTransaction() {
        return state.depositTransaction
    },

    otpEnableData() {
        return state.otpEnableData
    },

    pspList() {
        return state.pspList
    },

    questions() {
        return state.questions
    },

    otherPsps() {
        return state.otherPsps
    },

    dashboardData() {
        return state.dashboardData
    },

    bankDeposit() {
        return state.bankDeposit
    },

    activeCurrency() {
        return state.activeCurrency
    },

    taxes() {
        return state.taxes
    }
}

const actions = {
    [REFRESH_USER]() {
        RestService.get('/user')
            .then(user => {
                this.commit(USER, user)
            })

    },
    [APP_READY]() {
        if (state.token === undefined || state.token === 'undefined' || state.token === null || state.token === 'null') {
            localStorage.removeItem('token')

            RestService.get('/symbols/full')
                .then(symbols => {
                    for (let i in symbols) {
                        this.commit(SYMBOL, symbols[i])
                    }
                })
                .catch(() => {
                })
        } else {
            RestService.token(state.token)
            this.commit(MODAL, {
                register: false
            })

            if (MODULE_FTD) {
                this.commit(MODAL, {
                    fullscreen: true,
                })

                this.commit(MODAL_FULLSCREEN, {
                    page: "dashboard"
                })
            }
            RestService.get('/user', {}, () => {
                localStorage.removeItem('token')
                RestService.token('')

                RestService.get('/symbols/full')
                    .then(symbols => {
                        for (let i in symbols) {
                            this.commit(SYMBOL, symbols[i])
                        }
                    })
                    .catch(() => {
                    })
            })
                .then(user => {
                    if (!user) {
                        localStorage.removeItem('token')
                        return
                    }

                    this.commit(USER, user)
                })
        }
    },

    [PRE_LOGIN](context, data) {
        RestService.get('/otp', {email: data.email, password: data.password})
            .then(otp => {
                otp.otp ? this.commit(MODAL, {otp: true}) : this.dispatch(LOGIN, data)
            })
    },

    [LOGIN](context, data) {
        RestService.post('/auth', data)
            .then(user => {
                this.commit(USER, user)
                this.commit(MODAL, {
                    fullscreen: true,
                })

                if (INITIAL_DASHBOARD) {
                    this.commit(MODAL_FULLSCREEN, {
                        page: "dashboard"
                    })
                }
            })
    },

    [PASSWORD_RESTORE](context, data) {
        RestService.post('/user/password', data)
            .then(() => {
                this.commit(NOTIFICATION, 'E-mail with the reset link has been sent to your e-mail address. Please check your inbox.')
            })
    },

    [PASSWORD_RESET](context, data) {
        RestService.post('/password/reset', {
            password: data.password,
            hash: localStorage.getItem('hash')
        })
            .then(() => {
                this.commit(NOTIFICATION, 'Password has been changed')
                this.commit(MODAL, {
                    newPassword: false,
                    login: true
                })
                window.history.pushState({}, '', '/')
            })
    },

    [LOGOUT]() {
        localStorage.removeItem('token')
        localStorage.removeItem('termsIsAccept')
        RestService.token('')

        this.commit(LOGOUT)
    },

    [REGISTER](context, data) {
        data.affiliate_id = localStorage.getItem('referrer') || 0
        RestService.post('/user', data)
            .then(() => {
                this.commit(NOTIFICATION, 'Account has been registered. You may login now')

                this.commit(MODAL, {
                    register: false,
                    login: true
                })

                this.dispatch(LOGIN, data)
            })
    },

    [PERSONAL_UPDATE](context, user) {
        RestService.put('/user', user)
            .then(() => {
                this.commit(NOTIFICATION, 'Personal details have been updated')

                let nUser = {
                    ...state.user
                }

                for (let n in user) {
                    nUser[n] = user[n]
                }

                nUser.fullname = nUser.firstname + ' ' + nUser.lastname
                nUser.jwt_token = localStorage.getItem('token')

                this.commit(USER, nUser)
            })
    },

    [PASSWORD_CHANGE](context, pass) {
        RestService.put('/user/password', pass)
            .then(() => {
                this.commit(NOTIFICATION, 'Password has been changed. Please re-login with new password.')
            })
    },

    [DEPOSITS_GET]() {
        RestService.get('/transactions/deposits')
            .then(deposits => {
                this.commit(DEPOSITS, deposits.rows || [])
            })
    },

    [WITHDRAWAL_REQUEST](context, data) {
        data.balance = 'balance'
        RestService.post('/transactions/withdrawals', data)
            .then(() => {
                this.commit(NOTIFICATION, 'Withdrawal request has been sent')
                this.dispatch(WITHDRAWALS_GET)
            })
    },

    [WITHDRAWALS_GET]() {
        RestService.get('/transactions/withdrawals')
            .then(withdrawals => {
                this.commit(WITHDRAWALS, withdrawals.rows || [])
            })
    },

    [ATTACHMENT_UPLOAD](context, data) {
        RestService.post('/support/messages/file', data)
            .then(() => {
                // this.commit(NOTIFICATION, 'Attachment uploaded')
            })
    },


    [DOCUMENT_UPLOAD](context, data) {
        RestService.post('/kyc/documents', data)
            .then(() => {
                this.commit(NOTIFICATION, 'Document successfully uploaded')
                this.dispatch(DOCUMENTS_GET)
            })
    },

    [DOCUMENTS_GET]() {
        RestService.get('/kyc/documents')
            .then(documents => {
                this.commit(DOCUMENTS, documents.rows || [])
            })
    },

    [TRANSFER_REQUEST](context, data) {
        RestService.post('/transactions/transfers', data)
            .then(() => {
                this.commit(NOTIFICATION, 'Transfer has been processed')
            })
    },

    [DEPOSIT_REQUEST](context, data) {
        switch (data.psp) {
            case 'nax':
                this.commit(DEPOSIT_ADDRESS, '')
                RestService.get('/psp/nax/wallet', {
                    currency: data.currency,
                    psp: 'nax',
                    account_id: this.getters.activeAccount.id,
                    product: 'cfd'
                })
                    .then(res => {
                        if (res.wallet) {
                            this.commit(DEPOSIT_ADDRESS, res.wallet)
                        }
                    })
                break
            case 'perfectmoney':
            case 'webmoney':
            case 'paymentwall':
            case 'realeasypay':
            case 'cascading':
            case 'finanic':
            case 'betatransfer':
            case 'praxis':
            case 'prmoney':
            case 'starbill_usd':
            case 'starbill_eur':
            case 'paypound':
            case 'kryptova':
            case 'walletix':
            case 'walletcomru':
            case 'payzon':
            case 'bitexbit':
            case 'paystudio':
            case 'stripe':
            case 'settlepay_usd':
            case 'settlepay_eur':
            case 'garrypay':
            case 'texcent':
            case 'wellex':
            case 'villpay':
            case 'pinikle':
            case 'myfatoorah':
            case 'payepo':
            case 'wpay':
            case 'wpay_upi':
            case 'nonstopay':
            case 'gatetwoway':
            case 'gatetwoway_imps':
            case 'deluxepay':
            case 'cricpayz':
            case 'cricpayz_bank':
            case 'centiwise':
            case 'quikipay':
            case 'zyaadapay':
            case 'ttpay':
                RestService.post('/psp/request', {
                    currency: data.currency,
                    psp: data.psp,
                    amount: data.amount,
                    account_id: this.getters.realAccount.id,
                    product: 'cfd',
                })
                    .then(data => {
                        let form, win, script
                        switch (data.type) {
                            case 'post':
                                form = document.createElement('form')

                                form.setAttribute('action', data.data.url)
                                form.setAttribute('method', 'post')
                                form.setAttribute('hidden', 'true')
                                form.setAttribute('target', '_blank')

                                for (let n in data.data.params) {
                                    let input = document.createElement('input')
                                    input.setAttribute('type', 'text')
                                    input.setAttribute('name', n)
                                    input.setAttribute('value', data.data.params[n])

                                    form.appendChild(input)
                                }

                                document.body.appendChild(form);
                                form.submit()
                                document.body.removeChild(form)

                                break
                            case 'get':
                                win = window.open(data.data.url, '_blank')

                                if (!win || win.closed || typeof win.closed === 'undefined') {
                                    window.location.href = `${data.data.url}`
                                }

                                if (win) {
                                    win.focus()
                                }
                                break
                            case 'script':
                                script = document.createElement('script')
                                script.src = data.data.url
                                document.getElementsByTagName('head')[0].appendChild(script);
                                break
                        }
                    })
                break
            case 'payretailers':
                RestService.post('/psp/request', {
                    currency: data.currency,
                    psp: data.psp,
                    amount: data.amount,
                    account_id: this.getters.realAccount.id,
                    product: 'cfd',
                    country: data.country
                })
                    .then(data => {
                        let form, win, script
                        switch (data.type) {
                            case 'post':
                                form = document.createElement('form')

                                form.setAttribute('action', data.data.url)
                                form.setAttribute('method', 'post')
                                form.setAttribute('hidden', 'true')
                                form.setAttribute('target', '_blank')

                                for (let n in data.data.params) {
                                    let input = document.createElement('input')
                                    input.setAttribute('type', 'text')
                                    input.setAttribute('name', n)
                                    input.setAttribute('value', data.data.params[n])

                                    form.appendChild(input)
                                }

                                document.body.appendChild(form);
                                form.submit()
                                document.body.removeChild(form)

                                break
                            case 'get':
                                win = window.open(data.data.url, '_blank')

                                if (!win || win.closed || typeof win.closed === 'undefined') {
                                    window.location.href = `${data.data.url}`
                                }

                                if (win) {
                                    win.focus()
                                }
                                break
                            case 'script':
                                script = document.createElement('script')
                                script.src = data.data.url
                                document.getElementsByTagName('head')[0].appendChild(script);
                                break
                        }
                    })
                break
            case '4on':
                RestService.post('/psp/request', {
                    currency: data.currency,
                    psp: data.psp,
                    amount: data.amount,
                    account_id: this.getters.realAccount.id,
                    product: 'cfd',
                    country: data.country,
                    method: data.method
                })
                    .then(data => {
                        let form, win, script
                        switch (data.type) {
                            case 'post':
                                form = document.createElement('form')

                                form.setAttribute('action', data.data.url)
                                form.setAttribute('method', 'post')
                                form.setAttribute('hidden', 'true')
                                form.setAttribute('target', '_blank')

                                for (let n in data.data.params) {
                                    let input = document.createElement('input')
                                    input.setAttribute('type', 'text')
                                    input.setAttribute('name', n)
                                    input.setAttribute('value', data.data.params[n])

                                    form.appendChild(input)
                                }

                                document.body.appendChild(form);
                                form.submit()
                                document.body.removeChild(form)

                                break
                            case 'get':
                                win = window.open(data.data.url, '_blank')

                                if (!win || win.closed || typeof win.closed === 'undefined') {
                                    window.location.href = `${data.data.url}`
                                }

                                if (win) {
                                    win.focus()
                                }
                                break
                            case 'script':
                                script = document.createElement('script')
                                script.src = data.data.url
                                document.getElementsByTagName('head')[0].appendChild(script);
                                break
                        }
                    })
                break
            case 'solidpayment':
                RestService.post('/psp/request', {
                    currency: data.currency,
                    psp: data.psp,
                    amount: data.amount,
                    account_id: this.getters.realAccount.id,
                    product: 'cfd',
                })
                    .then(data => {
                        let form, win, script
                        switch (data.type) {
                            case 'post':
                                form = document.createElement('form')

                                form.setAttribute('action', data.data.url)
                                form.setAttribute('method', 'post')
                                form.setAttribute('hidden', 'true')
                                form.setAttribute('target', '_blank')

                                for (let n in data.data.params) {
                                    let input = document.createElement('input')
                                    input.setAttribute('type', 'text')
                                    input.setAttribute('name', n)
                                    input.setAttribute('value', data.data.params[n])

                                    form.appendChild(input)
                                }

                                document.body.appendChild(form);
                                form.submit()
                                document.body.removeChild(form)

                                break
                            case 'get':
                                win = window.open(decodeURIComponent(data.data.url), '_blank')

                                if (!win || win.closed || typeof win.closed === 'undefined') {
                                    window.location.href = `${data.data.url}`
                                }

                                if (win) {
                                    win.focus()
                                }
                                break
                            case 'script':
                                script = document.createElement('script')
                                script.src = data.data.url
                                document.getElementsByTagName('head')[0].appendChild(script);
                                break
                        }
                    })
                break
            case 'billing_ru':
            case 'billing_world':
            case 'honeypay':
            case 'square':
                RestService.post('/psp/request', {
                    currency: data.currency,
                    psp: data.psp,
                    amount: data.amount,
                    account_id: this.getters.realAccount.id,
                    product: 'cfd',
                })
                    .then(data => {
                        let form, win, script
                        switch (data.type) {
                            case 'post':
                                form = document.createElement('form')

                                form.setAttribute('action', data.data.url)
                                form.setAttribute('method', 'get')
                                form.setAttribute('hidden', 'true')
                                form.setAttribute('target', '_blank')

                                for (let n in data.data.params) {
                                    let input = document.createElement('input')
                                    input.setAttribute('type', 'text')
                                    input.setAttribute('name', n)
                                    input.setAttribute('value', data.data.params[n])

                                    form.appendChild(input)
                                }

                                document.body.appendChild(form);
                                form.submit()
                                document.body.removeChild(form)

                                break
                            case 'get':
                                win = window.open(data.data.url, '_blank')

                                if (win) {
                                    win.focus()
                                }
                                break
                            case 'script':
                                script = document.createElement('script')
                                script.src = data.data.url
                                document.getElementsByTagName('head')[0].appendChild(script);
                                break
                        }
                    })
                break
            case 'alwayspay_visa':
            case 'alwayspay_master':
            case 'chargemoney':
                RestService.post('/psp/request', {
                    currency: data.currency,
                    psp: data.psp,
                    amount: data.amount,
                    account_id: this.getters.realAccount.id,
                    product: 'cfd',
                    card: data.card ? data.card : ''
                })
                    .then(data => {
                        let form, win, script
                        switch (data.type) {
                            case 'post':
                                form = document.createElement('form')

                                form.setAttribute('action', data.data.url)
                                form.setAttribute('method', 'post')
                                form.setAttribute('hidden', 'true')
                                form.setAttribute('target', '_blank')

                                for (let n in data.data.params) {
                                    let input = document.createElement('input')
                                    input.setAttribute('type', 'text')
                                    input.setAttribute('name', n)
                                    input.setAttribute('value', data.data.params[n])

                                    form.appendChild(input)
                                }

                                document.body.appendChild(form);
                                form.submit()
                                document.body.removeChild(form)

                                break
                            case 'get':
                                win = window.open(data.data.url, '_blank')

                                if (win) {
                                    win.focus()
                                }
                                break
                            case 'script':
                                script = document.createElement('script')
                                script.src = data.data.url
                                document.getElementsByTagName('head')[0].appendChild(script);
                                break
                        }
                    })
                break
            case 'bank':
                RestService.post('/transactions/deposits', {
                    amount: data.amount,
                    account_id: this.getters.realAccount.id,
                    currency: data.currency,
                    product: 'cfd'
                })
                    .then(() => {
                        this.commit(NOTIFICATION, 'Please contact your account manager for bank details')
                        this.dispatch(DEPOSITS_GET)
                    })
                break
            case 'cc-ext':
                RestService.post('/transactions/deposits', data)
                    .then(() => {
                        this.commit(NOTIFICATION, 'Thank you! Your deposit is being processed')
                        this.dispatch(DEPOSITS_GET)
                    })
                break
            case 'cc-ext2':
                RestService.post('/transactions/deposits', data)
                    .then(transaction => {
                        this.commit(DEPOSIT_TRANSACTION, transaction)
                        this.dispatch(DEPOSITS_GET)
                    })
                break
            case 'razorpay':
                RestService.post('/psp/request', {
                    psp: data.psp,
                    amount: data.amount,
                    currency: data.currency,
                    account_id: this.getters.realAccount.id,
                    product: 'cfd'
                })
                    .then(data => {
                        let rzp = new window.Razorpay(data.data.params)

                        rzp.open()

                        this.dispatch(DEPOSITS_GET)
                    })
                break
        }
    },

    [GENERATE_INVOICE](context, data) {
        RestService.post('/transactions/deposits/bank', data)
            .then(ans => {
                this.commit(BANK_DEPOSIT, ans)
            })
    },

    [DOWNLOAD_INVOICE](context, id) {
        RestService.get('/transactions/deposits/bank/' + id)
            .then(r => {
                let anchor = document.createElement('a')
                anchor.href = REST_ENDPOINT + '/kyc/document/' + r.file + '?download'
                anchor.download = r.file
                anchor.target = '_blank'
                anchor.click()
            })
    },

    [UPLOAD_CONFIRMATION](context, data) {
        RestService.post('/transactions/deposits/bank/' + state.bankDeposit.id, data)
            .then(() => {
                this.commit(NOTIFICATION, 'Document successfully uploaded')
                this.dispatch(DEPOSITS_GET)
            })
    },

    [AVATAR_UPLOAD](context, data) {
        RestService.post('/avatar', data)
            .then(ans => {
                this.commit(NOTIFICATION, 'Photo successfully uploaded')
                this.commit(USER, {
                    avatar: ans.file
                })
            })
    },

    [OTP_CREATE]() {
        RestService.post('/otp')
            .then(data => {
                this.commit(OTP_ENABLE_DATA, data)
            })
    },

    [OTP_ACTIVATE](context, data) {
        RestService.put('/otp', {
            code: data.code
        })
            .then(() => {
                this.commit(NOTIFICATION, '2FA protection has been activated')
                this.commit(USER, {
                    otp: 1
                })
            })
    },

    [OTP_DISABLE](context, data) {
        RestService.post('/otp/remove', {
            code: data.code
        })
            .then(() => {
                this.commit(NOTIFICATION, '2FA protection has been disabled')

                this.dispatch(OTP_CREATE)
                this.commit(USER, {
                    otp: 0
                })
            })
    },

    [DASHBOARD_BALANCE](context, time) {
        RestService.get('/dashboard/balance_chart/', {count: time})
            .then(balance => {
                this.commit(DASHBOARD_BALANCE_CHART, balance)
            })
    },

    [CANCEL_WITHDRAWAL](context, id) {
        RestService.put(`/transactions/withdrawals/${id}/cancel`)
            .then(ans => {
                if (ans) {
                    let withdrawalsNew = this.getters.withdrawals.filter(w => w.id !== id)
                    this.commit(WITHDRAWALS, withdrawalsNew)
                }
            })
    },

    [GET_TAXES]() {
        RestService.get('/taxes')
            .then(taxes => {
                this.commit(SET_TAXES, taxes)
            })
    }
}

const mutations = {
    [TOKEN](context, token) {
        RestService.token(token)

        localStorage.setItem('token', token)

        state.token = token
    },

    [USER](context, user) {
        for (let n in user) {
            Vue.set(state.user, n, user[n])
        }

        this.commit(TOKEN, user.jwt_token)

        if(!localStorage.getItem('termsIsAccept')) {
            this.commit(MODAL, {
                termsAndCondition: true
            })
        }

        if (user.id) {
            state.loggedIn = true
        }

        RestService.get('/psp/list')
            .then(list => {
                this.commit(PSP_LIST, list)
            })

        RestService.get('/kyc/questions')
            .then(questions => {
                this.commit(QUESTIONS, questions)
            })

        RestService.get('/currencies',)
            .then(curr => {
                this.commit(CURRENCIES, curr)
            })

        RestService.get('/psp/other')
            .then(otherPsps => {
                // TODO remove then it not needed
                if (BRAND === 'mapledex') {
                    otherPsps.push({
                        name: 'mapleQrCode',
                        url: 'https://checkout.stripe.com/c/pay/cs_live_a1rTc3PobXCk3trZPVXNFgihdly3ElYG8FxFmJ5cglLIs6jxWNahpwHZov#fidkdWxOYHwnPyd1blppbHNgWjA0S0pUalxCb2hDQzxcN2FpNHBqV11AX2RQbEM2Y3RoYGRjbTdIM3dHUmJ3VTMwdG1vUGdvVH1Df2ZqX0dKSE5xQTV8QkpIXWp0RzBnfWZEVERqamE2QDExNTVxakxVZEtkdCcpJ3VpbGtuQH11anZgYUxhJz8nMG5EM3d9bTRtPEZyNT1iMnZxJ3gl'
                    })
                }
                this.commit(OTHER_PSPS, otherPsps)
            })

        RestService.get('/auth/logins')
            .then(logins => {
                this.commit(DASHBOARD_LOGIN, logins)
            })

        RestService.get('/dashboard/stats')
            .then(stats => {
                this.commit(DASHBOARD_STATS, stats)
                if (SHOW_TAXES) {
                    this.dispatch(GET_TAXES)
                }
            })

        this.dispatch(DASHBOARD_BALANCE, 7 * 24)
    },

    [USER_PERSONAL](context, user) {
        for (let n in user) {
            Vue.set(state.user, n, user[n])
        }
    },

    [CFD_ORDER](context, order) {
        if (order.time_close > 0) {
            let created = false

            for (const acc in state.dashboardData.stats.accounts) {
                state.dashboardData.stats.accounts[acc].account_id === order.account_id ? created = true : ''
            }

            if (!state.dashboardData.stats.accounts) {
                Vue.set(state.dashboardData.stats, 'accounts', [])
            }

            if (!created) {
                state.dashboardData.stats.accounts.push({
                    account_id: order.account_id,
                    cnt_total: 0,
                    amount: 0,
                    profit: 0,
                    cnt_profit: order.profit > 0 ? "1" : "0"
                })
            }

            state.dashboardData.stats.amount = 0
            state.dashboardData.stats.profit = 0

            for (const acc in state.dashboardData.stats.accounts) {
                if (state.dashboardData.stats.accounts[acc].account_id === order.account_id) {
                    state.dashboardData.stats.accounts[acc].cnt_total += 1
                    state.dashboardData.stats.accounts[acc].amount += order.margin
                    state.dashboardData.stats.accounts[acc].profit += order.profit
                }

                state.dashboardData.stats.amount += state.dashboardData.stats.accounts[acc].amount
                state.dashboardData.stats.profit += state.dashboardData.stats.accounts[acc].profit
            }

            if (order.profit > 0) {
                state.dashboardData.stats.cnt_profit = state.dashboardData.stats.cnt_profit ? parseInt(state.dashboardData.stats.cnt_profit) + 1 : 1
            }
        }
    },

    [LOGOUT]() {
        state.loggedIn = false
    },

    [MANAGERS](context, managers) {
        state.managers = managers
    },

    [DEPOSITS](context, deposits) {
        for (let i in deposits) {
            deposits[i].timeCreatedString = timeToString(deposits[i].time_created * 1000)
        }

        if (deposits) {
            deposits.reverse()
        }

        Vue.set(state, 'deposits', deposits || [])
    },

    [WITHDRAWALS](context, withdrawals) {
        for (let i in withdrawals) {
            withdrawals[i].timeCreatedString = timeToString(withdrawals[i].time_created * 1000)
        }

        if (withdrawals) {
            withdrawals.reverse()
        }

        Vue.set(state, 'withdrawals', withdrawals)
    },

    [DOCUMENTS](context, documents) {
        for (let i in documents) {
            documents[i].timeCreatedString = timeToString(documents[i].time_created * 1000)
        }

        if (documents) {
            documents.reverse()
        }

        Vue.set(state, 'documents', documents)
    },

    [DEPOSIT_ADDRESS](context, address) {
        state.depositAddress = address
    },

    [DEPOSIT_TRANSACTION](context, transaction) {
        Vue.set(state, 'depositTransaction', transaction)
    },

    [OTP_ENABLE_DATA](context, data) {
        state.otpEnableData = data
    },

    [TRANSACTION](context, data) {
        if ((data.status === 1 || data.status === 2) && (data.type === 1 || data.type === 2)) {
            this.commit(NOTIFICATION_TRANSACTION, data)
        }

        if (state.depositTransaction.id === data.id) {
            this.commit(DEPOSIT_TRANSACTION, data)
        }

        this.dispatch(DEPOSITS_GET)
    },

    [PSP_LIST](context, data) {
        state.pspList = data
    },

    [QUESTIONS](context, data) {
        state.questions = data
    },

    [OTHER_PSPS](context, data) {
        state.otherPsps = data
    },

    [DASHBOARD_LOGIN](context, login) {
        login.rows.reverse()
        state.dashboardData.logins = login
    },

    [DASHBOARD_STATS](context, stats) {
        state.dashboardData.stats = stats
    },

    [DASHBOARD_BALANCE_CHART](context, balance) {
        state.dashboardData.balance_chart = balance
    },

    [BANK_DEPOSIT](context, data) {
        state.bankDeposit = data
    },

    [ACTIVE_CURRENCY](context, data) {
        state.activeCurrency = data
    },

    [SET_TAXES](context, data) {
        Vue.set(state, 'taxes', data.rows)
        Vue.set(state.dashboardData.stats, 'taxes', parseFloat(Object.keys(data.rows).reduce((previous, key) => {
            return previous + data.rows[key].amount_usd
        }, 0)))
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
